let basketEventController = {
  run() {
    this.initEventList();
  },
  initEventList() {
    const
      basketNodeTriggersIdList = [
        'open-basket-button-mob',
        'open-basket-button-desc',
        'open-basket-button-header',
        'if-basket-length-not-0',
        'if-basket-length-0',
        'open-basket-button-header-mob',
        'if-basket-length-not-0-mob',
      ],
      basketNodeTriggersIdListLength = basketNodeTriggersIdList.length,
      customVueEvent = new CustomEvent('openBasketVue');
    let
      i = 0;

    for (i; i < basketNodeTriggersIdListLength; i++) {
      const node = document.getElementById(basketNodeTriggersIdList[i]);
      if (!!node) {
        node.addEventListener(
          'click',
          () => this.dispatchCustomVueEvent(customVueEvent),
          false,
        );
      }
    }
  },
  dispatchCustomVueEvent(eventObj) {
    if ($('.compare-cart__sidebar .b-modal-side__close').length) {
      $('.compare-cart__sidebar .b-modal-side__close').trigger('click')
    }
    document.dispatchEvent(eventObj);
  },
};
module.exports = basketEventController;
