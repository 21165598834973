let faqThemesFilter = {
    run() {
        $(document).on('click', '[data-faq-themes-head]', event => {
            let theme = $(event.target).data('faq-theme-head');
            if (theme == 'all') {
                faqThemesFilter.showAll();
            } else {
                if ($('[data-faq-theme-head=all]').hasClass('active')) {
                    $('[data-faq-theme-head]').each(function () {
                        $(this).removeClass('active');
                    });
                    $('[data-faq-theme-content]').each(function () {
                        $(this).hide();
                    });
                }

                if ($(event.target).hasClass('active')) {
                    $(event.target).removeClass('active');
                } else {
                    $(event.target).addClass('active');
                }

                let clickedTheme = $('[data-faq-theme-content=' + theme + ']');

                if (clickedTheme.is(":hidden")) {
                    clickedTheme.show();
                } else {
                    clickedTheme.hide();
                }

                if ($('[data-faq-theme-head].active').length == 0) {
                    faqThemesFilter.showAll();
                }
            }
        });
    },
    showAll() {
        $('[data-faq-theme-content]').each(function () {
            $(this).show();
        });
        $('[data-faq-theme-head]').each(function () {
            $(this).removeClass('active');
        });
        $('[data-faq-theme-head=all]').addClass('active')
    },
};

module.exports = faqThemesFilter;