import Swiper from 'swiper/dist/js/swiper.min.js';

const reviewsModalOpener = {
    run() {
        this.$box = $('[review-modal]');
        this.$text = this.$box.find('[review-modal-text]');

        if(!this.$box.length) return false;

        this.initModal();
        this.checkHeight();
    },

    initModal() {
        const self = this;

        $('[review-modal-opener]').magnificPopup({
            type: 'inline',
            preloader: false,
            closeOnBgClick: true,
            focus: '#username',
            modal: true,
            callbacks: {
                open: function () {
                    const $slider = $(this.content).find('[data-slider="testimonial"]');

                    self.initSlider({
                        $slider: $slider,
                    });

                    self.initGallery({
                        $slider: $slider,
                    });

                    $(document).on('click', self.closeModalReview);
                },
                close: function () {
                    $(document).off('click', self.closeModalReview);
                }
            }
        });
        $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
    },

    checkHeight() {

        this.$box.each((index, item) => {

            let maxHeight = $(item).attr('data-max-height');
			if ($(window).width() > 768) {
				maxHeight = 61;
			}
            const $text = $(item).find('[review-modal-text]');

            if($text.innerHeight() > maxHeight) {
                $text.css('height', maxHeight);
                $text.attr('data-text-status', 'hide');
            }
        });
    },

    initGallery({$slider}) {

        $slider.each(function (index, item) {

            const $links = $(item).find('.testimonial__img-link');

            $links.on('click', function (e) {
                e.preventDefault();

                const modalID = $(item).attr('data-modal-id');

                $.magnificPopup.close();
                const curIndex = parseInt($(this).attr('data-index')) || 0;
                const $link = $(this);
                const items = [];

                $links.each(function (i,el) {
                    items.push({
                        src: $(el).attr('data-mfp-src') || '',
                    });
                })

                setTimeout(function(){
                    $.magnificPopup.open({
                        items: items,
                        tLoading: 'Loading image #%curr%...',
                        mainClass: 'mfp-review-gallery',
                        type: 'image',
                        image: {
                            verticalFit: true,
                            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                        },
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: 0,
                        },
                        callbacks: {
                            close: function () {
                                setTimeout(function () {
                                    $('.testimonial__action[href="#'+ modalID +'"]').trigger('click');
                                }, 400)
                            }
                        }
                    })

                    $.magnificPopup.instance.goTo(curIndex);
                }, 500)
            })
        })
    },

    initSlider({$slider}) {
        const self = this;

        if(!$slider.find('.swiper-container-initialized ').length) {
            const testimonialSlider = new Swiper('[data-slider="testimonial"] .swiper-container', {
                slidesPerView: 3,
				spaceBetween: 20,
                navigation: {
                    prevEl: '[data-slider-arrow-p="testimonial"]',
                    nextEl: '[data-slider-arrow-n="testimonial"]',
                    disabledClass: 'disabled',
                },
            });
        }
    },

    closeModalReview(evt) {
        if(!$('.mfp-ready').length) return false;

        if(!$(evt.target).hasClass('b-modal--review') && !$('.b-modal--review').has(evt.target).length) {
            $.magnificPopup.close();
        }
    }
};

module.exports = reviewsModalOpener;
