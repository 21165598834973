const {baseCRMUrl} = require("../config/config.axios.local");

const eventListener = {
	run() {
		window.addEventListener('message', function (event) {
			if(event.data.action === 'historyBack' && `${event.origin}/` === baseCRMUrl) {
				history.back()
			}
		})
	}
}

module.exports = eventListener
