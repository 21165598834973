import crsOrder from "./rest/crs/crsOrder";
import crsUser from "./rest/crs/crsUser";

const vodohodPro = {
    async run() {
        const _this = this
        const customer_id = (await crsUser.getCurrent()).data.id
        const adds = JSON.parse($('[name="adds"]').val())
        $(document).on("click", "[data-vodohod-pro-subscribe]", event => {
            event.preventDefault();
            const element = document.getElementById("vodohod_pro_subscribe");
            element.scrollIntoView({block: "end", inline: "center"});
        })
        $(document).on("submit", "#vodohod_pro_subscribe form", async event => {
            event.preventDefault()
            const formData = new FormData(event.target)
            const add_id = formData.get('add_insurance') === 'on' ?
                adds['vodohod-pro-with-insurance'] : adds['vodohod-pro']
            const order = await _this.createOrder({
                add_id,
                customer_id
            })
            if(order.payment_link.length > 0) {
                window.onCPSuccess = () => {
                    location.reload()
                }
                $.getScript(order.payment_link)
            }
        })
    },
    async createOrder(data) {
        const order = await crsOrder.shopOrdersAdd(data)
        if (+order.data.order_id > 0) {
            await crsOrder.shopOrdersConfirm(+order.data.order_id)
            return (await crsOrder.shopOrdersGet(order.data.order_id)).data
        }
        return {}
    },
}

module.exports = vodohodPro
