let loader = {
  run() {
    this.showWaitInit();
    this.closeWaitInit();
  },

  showWaitInit() {
    BX.showWait = function (node, msg) {
      var loader = $('.loader');
      if (loader.length <= 0) {
        $('body').append('<div class="lds-css ng-scope loader">\n' +
            '    <div class="lds-eclipse">\n' +
            '        <div></div>\n' +
            '    </div>\n' +
            '</div>');
      }
      loader.fadeIn();
    };
  },

  closeWaitInit() {
    BX.closeWait = function (node, obMsg) {
      var loader = $('.loader');
      loader.fadeOut();
    };
  }

}

module.exports = loader;

