let iframeInteraction = {

    run() {

        $(document).on('click', '[data-save-filter-to-crm]', e => {
            e.preventDefault();
            let data = {
                action: 'saveFilter',
                url: window.location.href,
                fields: [],
            };
            $('[data-filter] [data-f-item]').each(function (i, el) {
                let field = $(el).find('[data-f-field]');
                var value = field.val();
                if (value.length != 0) {
                    if (field.multiple) {
                        value = field.children().map(function (index, el) {
                            if (el.selected) return el.innerHTML;
                        })
                    } else if (value.constructor === Array) {
                        value = value.map(function (optionValue) {
                            return field.find('[value=' + optionValue + ']')[0].innerHTML.trim();
                        })
                    } else {
                        value = [value];
                    }
                    data.fields.push(
                        {
                            'name': $($(el).find('[data-f-label]')[0]).text(),
                            'code': field.attr('data-filter-item-code'),
                            'value': value,
                        }
                    )
                }
            });
            $('[data-filter] [data-filter-checkbox]').each(function (i, el) {
                let field = $(el).find('[type=checkbox]');
                if (field[0].checked) {
                    data.fields.push(
                        {
                            'name': $(el).find('[data-filter-checkbox-name]').text(),
                            'code': field.attr('data-filter-checkbox-code'),
                            'value': ['Да'],
                        }
                    )
                }
            });

            //TODO: Позже указать конкретный url crm
            window.parent.parent.postMessage(data, '*');
        });


        $(document).on('click', '[data-save-cruise-to-crm]', e => {
            e.preventDefault();
            $(e.currentTarget).addClass('disabled');

            var cruiseContainer = $($(e.target).parents('[data-itemId]')[0]);
            var cruiseTitle = cruiseContainer.find('[data-cruise-title]')[0];
            var cruiseShipName = cruiseContainer.find('[data-cruise-ship-name]')[0];
            var cruiseShipClass = cruiseContainer.find('[data-cruise-ship-class]')[0];
            var cruiseRoute = cruiseContainer.find('[data-cruise-route]')[0];
            var cruiseDate = cruiseContainer.find('[data-cruise-date]')[0];
            var cruisePrice = cruiseContainer.find('[data-load-cruise-price]')[0];

            let data = {
                'action': 'saveCruise',
                'name': cruiseTitle.innerText,
                'ship': cruiseShipName.innerText,
                'class': cruiseShipClass.innerText,
                'route': cruiseRoute.innerText,
                'cruisesDate': cruiseDate.innerText,
                'price': cruisePrice.innerText,
                'url': cruiseTitle.href,
            };
            //TODO: Позже указать конкретный url crm
            window.parent.parent.postMessage(data, '*')
        });

        $(document).on('change', '#CRM_SAVED_SELECTION', e => {
            $('.SAVED_SELECTION_HIDDEN').prop('disabled', !$(e.target).prop('checked'))
        })

        if($('#CRM_SAVED_SELECTION').length) {
            $('.SAVED_SELECTION_HIDDEN').prop('disabled', !$('#CRM_SAVED_SELECTION').prop('checked'))
        }
    },
};

module.exports = iframeInteraction;