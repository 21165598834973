const tabsSimple = {
    run() {
        this.tabsSelector = '[data-simple-tabs]';
        this.navSelector = '[data-simple-tabs-nav]';
        this.contentSelector = '[data-simple-tabs-content]';
        this.linkAllSelector = '[data-simple-tabs-link-all]';
        this.$tabs = $(this.tabsSelector);
        this.$navs = $(this.navSelector);
        this.$content = $(this.contentSelector);

        this.$navs.on('click', (evt) => {
           this.onClickNav({$nav: $(evt.currentTarget)});
        });
    },

    onClickNav({$nav}) {
        const $box = $nav.closest(this.tabsSelector);
        const $curNavs = $box.find(this.navSelector);
        const $curContents = $box.find(this.contentSelector);
        const $curLinkAll = $box.find(this.linkAllSelector);
        const urlLinkAll = $nav.attr('data-url-to-all');
        const id = $nav.attr('data-simple-tabs-id');
        const $curContent = $(this.contentSelector + '[data-simple-tabs-id="'+ id +'"]');
        const $swiper = $curContent.find('.swiper-container-initialized');

        $curNavs.removeClass('active');
        $nav.addClass('active');
        $curContents.removeClass('active');
        $curContent.addClass('active');
        
        if($curLinkAll.length && typeof urlLinkAll === 'string') this.updLinkAll({$link: $curLinkAll, url: urlLinkAll});
        if($swiper.length) $swiper[0].swiper.update();
    },

    updLinkAll({$link, url}) {
        $link.attr('href', url);
    },
};

module.exports = tabsSimple;