const modalGalleryOpener = {
    run() {
        if($('[data-modal-gallery-opener]').length) {
            $('[data-modal-gallery-opener]').on('click', (evt) => {
                evt.preventDefault();
                this.onOpenerClick({
                    $btn: $(evt.currentTarget),
                });
            });
        }
    },

    onOpenerClick({$btn}) {
        const $galleryFirstActiveLink = $btn.closest('[data-modal-gallery-box]').find('[data-modal-gallery] a:first-child');

        if($galleryFirstActiveLink.length) {
            $galleryFirstActiveLink.click();
        }
    },
};

module.exports = modalGalleryOpener;