import '../_vendor/jquery.menu-aim';
import lazy from './../lazy';
import headerFix from '../fixOn/fixOnScroll';

let desktopMenuHover = {
    run() {
        $(window).resize(() => {
            this.init();
            this.updHeader();
        }).trigger('resize');
    },
    init() {
        const $wrap = $("[data-menu-wrap]");
        this.isDetail = $('.h__bottom--tabs').length && $(window).innerWidth() >= 1024;

        if($wrap.hasClass('init')) return;
        $wrap.addClass('init');

        let $openedMenu = null;
        const $mask = $('[data-menu-mask]');
        let activateTimer = null;
        const $links = $('.h-menu__inner');
        let isLinksVisible = false;
        const $hTop = $('.h__top');
        const $hBottom = $('.h__bottom');

        $wrap.menuAim({
            activate: activateSubmenu,
            submenuDirection: "below",
            rowSelector: " [data-menu-link]",
            exitMenu: exitMenu,
        });

        function activateSubmenu(row) {
            activateTimer = setTimeout(function() {
                const $this = $(row);
                const link = $this.data('menu-link');
                const $menu = $(`[data-menu="${link}"]`);

                $('#old-design').hide();

                linksRemoveActive();
                $this.addClass('active');

                if($openedMenu) { /* активное меню есть - перключаем контент */
                    $openedMenu.hide();
                    $menu.show();

                    lazy.update();
                } else {  /* активного меню нет - открываем */
                    setTimeout(() => $wrap.addClass('active'), 100);
                    if (!$hBottom.hasClass('fixed')) {
                        $hTop.css('z-index', 11);
                        $hBottom.css('z-index', 11);
                    }
                    $mask.fadeIn();
                    $menu.slideToggle({
                        progress: (item, progress)=> {
                            if(Math.round(progress * 100) >= 70 && !isLinksVisible) {
                                $links.addClass('init');
                                isLinksVisible = true;

                                lazy.update();
                            }
                        }
                    });
                }

                $openedMenu = $menu;
				document.dispatchEvent(new Event("top-menu-opened"));
            }, 100);
        }

        function exitMenu() {
            clearTimeout(activateTimer);
            if($openedMenu)
                hideMenu();

            return true
        }

        function linksRemoveActive() {
            $('[data-menu-link]').removeClass('active');
        }

        function hideMenu() {
            linksRemoveActive();

            $('#old-design').show();

            $openedMenu.slideToggle();
            $openedMenu = false;
            $mask.fadeOut();

            isLinksVisible = false;
            $links.removeClass('init');
            setTimeout(() => {
                $wrap.removeClass('active');
                if (!$hBottom.hasClass('fixed')) {
                    $hTop.css('z-index', 6);
                    $hBottom.css('z-index', 3);
                }
            }, 300);
        }
    },

    updHeader() {
        const $header = $('.h');
        const $headerBottom = $header.find('.h__bottom');

        window.matchMedia("(max-width: 1279px)").matches && !this.isDetail ? $headerBottom.addClass('fixed') : $headerBottom.removeClass('fixed');
        headerFix.run();
    },
};

module.exports = desktopMenuHover;
