const mainPageFilter = {
	run() {
		const $filterContainer = $('[data-main-filter]')
		const $firstItem = $filterContainer.find('.c-filter__item:nth(0)')
		const $secondItem = $filterContainer.find('.c-filter__item:nth(1)')
		const $filterBtn = $filterContainer.find('[data-tab-filter]')

		const checkedItems = {
			first: [],
			second: [],
		}

		$firstItem.addClass('hidden')

		$(document).on('click', '[data-tab-filter]', function () {
			const index = $(this).data('tab-filter')

			if ($(this).hasClass('active')) return

			$filterBtn.removeClass('active')
			$(this).addClass('active')

			if (index === 0) {
				checkedItems.second = []

				$secondItem.find('input[type="checkbox"]').each((i, checkbox) => {
					if ($(checkbox).prop('checked')) {
						checkedItems.second.push(i)
						smartFilter.click(checkbox)
						$(checkbox).prop('checked', false)
					}
				})
				checkedItems.first.forEach(i => {
					const checkbox = $firstItem.find('input[type="checkbox"]')[i]
					smartFilter.click(checkbox)
					$(checkbox).prop('checked', true)
				})

				$firstItem.removeClass('hidden')
				$secondItem.addClass('hidden')
			} else {
				checkedItems.first = []

				$firstItem.find('input[type="checkbox"]').each((i, checkbox) => {
					if ($(checkbox).prop('checked')) {
						checkedItems.first.push(i)
						smartFilter.click(checkbox)
						$(checkbox).prop('checked', false)
					}
				})
				checkedItems.second.forEach(i => {
					const checkbox = $secondItem.find('input[type="checkbox"]')[i]
					smartFilter.click(checkbox)
					$(checkbox).prop('checked', true)
				})

				$secondItem.removeClass('hidden')
				$firstItem.addClass('hidden')
			}
		})
	},
}

module.exports = mainPageFilter
