import Swiper from 'swiper/dist/js/swiper.min.js';

$(function () {
    let isOverlayHandlerAdd = false;
    let isESCHalderAdd = false;

    $('.popup-link').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#username',
        modal: true,
        callbacks: {
            open: function() {
                const self = this;

                var galleryTop = new Swiper('.gallery-top', {
                    spaceBetween: 10,
                    slidesPerView: 1,
                    loop: true,
                    loopedSlides: 4
                });
                
                var galleryThumbs = new Swiper('.gallery-thumbs', {
                    spaceBetween: 10,
                    centeredSlides: true,
                    navigation: {
                        nextEl: '.popup-button-prev',
                        prevEl: '.popup-button-next',
                    },
                    slidesPerView: 4,
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    loop: true,
                });
                galleryTop.controller.control = galleryThumbs;
                galleryThumbs.controller.control = galleryTop;

                $(document).on('keyup', function (evt) {
                    if(!$('.mfp-ready').length && !$('.mfp-ready .gallery-thumbs.swiper-container-initialized').length) return false;

                    if(evt.keyCode == 39) {
                        galleryThumbs.slideNext();
                    } else if(evt.keyCode == 37) {
                        galleryThumbs.slidePrev();
                    }
                })

                if(!isOverlayHandlerAdd) {
                    isOverlayHandlerAdd = true;
                    $(self.container).on('click', function (evt) {
                        if (!$(self.content).has(evt.target).length) {
                            $.magnificPopup.close();
                        }
                    })
                }

                if(!isESCHalderAdd) {
                    isESCHalderAdd = true;
                    $(document).on('keyup', function (evt) {
                        if (self.isOpen && evt.keyCode == 27) {
                            $.magnificPopup.close();
                        }
                    })
                }
            },
            close: function() {
                // Will fire when popup is closed
            }
        }
    });
    $(document).on('click', '.popup-modal-dismiss', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
    });
});