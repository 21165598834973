import {CrsClient} from "../crs-client";

class CrsCruise extends CrsClient
{
    priceTable(id) {
        return this.service.get(`/cruises/${id}/price-table`)
    }

    bookingAccommodations(id, params = {}) {
        return this.service.get(`/booking/accommodations/${id}`, {params})
    }

    rooms(id, params = {}) {
        return this.service.get(`/cruises/${id}/rooms`, {params})
    }

    roomTariffs(id, params = {}) {
        return this.service.get(`/cruises/room-tariffs/${id}`, {params})
    }

    bookingDiscounts(id) {
        return this.service.get(`/booking/discounts?id=${id}`)
    }

    getRoute(id, params = {}) {
        return this.service.get(`/cruises/route/${id}`, {params})
    }

    bookingAdds(id, params = {}) {
        return this.service.get(`/booking/adds/${id}`, {params})
    }

    getOne(id, params = {}) {
        return this.service.get(`/cruises/one/${id}`, {params})
    }
}

const crsCruise = new CrsCruise()
export default crsCruise
