const addBodyClass = {
    run() {
        if($('[data-add-body-class]').length) {
            $('[data-add-body-class]').each((index, item) => {
                this.addBodyClasses({
                    classes: $(item).attr('data-add-body-class'),
                });
            });
        }
    },

    addBodyClasses({classes}) {
        if(typeof classes != 'string' && /,/.test(classes)) {
            console.error(`Классы должны являться строкой. Классы между собой должны быть разделены пробелом. classes - ${classes}`);
            return false;
        }

        const $body = $('body');
        const arr = classes.split(' ');

        arr.forEach((item) => {
            if(!$body.hasClass(item)) $body.addClass(item);
        });
    },
};

module.exports = addBodyClass;