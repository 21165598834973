const services = {
    run() {
        this.$container = $('[data-services]');
        this.$list = this.$container.find('.c-services__list');
        this.$items = this.$list.find('.c-services__item');

        if(this.$items.length && $(window).innerWidth) {
            this.$items.hover((evt) => {
                this.hoverItem({$item: $(evt.currentTarget)});
            }, (evt) => {
                this.unhoverItem({$item: $(evt.currentTarget)});
            })
        }
    },

    hoverItem(props) {
        const $description = props.$item.find('.c-services__item-description');
        $description.stop().slideDown();
    },

    unhoverItem(props) {
        const $description = props.$item.find('.c-services__item-description');
        $description.stop().slideUp();
    },
};

module.exports = services;