const managerFilter = {
    run() {
        $('.js-select-pagination').on('change', function() {
            const limit = $(this).val()

            const {origin, pathname, search} = window.location
            const searchArr = search.split('&')
            let hasLimit = false
            let newSearchArr = [];
            newSearchArr = searchArr.map(str => {
                if (str.includes('LIMIT')) {
                    const arrLimit = str.split('=')
                    arrLimit[1] = limit
                    str = arrLimit.join('=')
                    hasLimit = true
                }
                return str
            })

            if (!hasLimit && newSearchArr[0] !== '' && newSearchArr.length) {
                newSearchArr.push(`LIMIT=${limit}`)
            } else if (!hasLimit) {
                newSearchArr[0] = `?LIMIT=${limit}`
            }
            const newSearchString = newSearchArr.join('&')
            window.location = `${origin}${pathname}${newSearchString}`
        })
    }
}

module.exports = managerFilter
