import noUiSlider from 'nouislider';

let rangeSlider = {
    run() {
        let self = this;
        let rangeSliders = document.querySelectorAll('[data-range]');
        const $minRange = $('[data-range-min]');
        
        if(!$minRange.length)
            return;

        if(typeof $minRange.data('range-min') !== 'number') {
            const $inputs = $('[data-range-input]');

            $inputs.prop('disabled',true);
            $inputs.prev('.b-form__label').removeClass('active');

            return;
        }

        
        //Счетчик - все ли компоненты инициализированы
        let isInit = 0;
        
        for (let i = 0; i < rangeSliders.length; i++) {
            let slider = rangeSliders[i];
            let rangeSlider = slider.querySelector('[data-range-slider]');
            let rangeInputs = [slider.querySelector('[data-range-input="left"]'), slider.querySelector('[data-range-input="right"]')];
            
            //Начальные данные
            let rangeStartMin,
                rangeStartMax,
                rangeMin,
                rangeMax;
            
            //Если data-type-slider != float - округляем до целых
            $(slider).data('type-slider') != 'float' ? getValues(parseInt) : getValues(parseFloat);
            
            function getValues(rounding) {
                rangeStartMin = rounding(slider.getAttribute('data-start-min'));
                rangeStartMax = rounding(slider.getAttribute('data-start-max'));
                rangeMin = rounding(slider.getAttribute('data-range-min'));
                rangeMax = rounding(slider.getAttribute('data-range-max'));
            }

            if (rangeMin === rangeMax) {
                rangeInputs.forEach((input) => {
                    input.setAttribute('disabled', 'disabled');
                });
            } else {
                noUiSlider.create(rangeSlider, {
                    start: [rangeStartMin, rangeStartMax],
                    connect: true,
                    range: {
                        'min': rangeMin,
                        'max': rangeMax
                    }
                });

                //Передаём jq обертку изменяемого "слайдера", новое значение, и объект min или max
                let rounding = ($target, values, handle)=> {
                    //Если округляем до целых то в меньшую / большую стороны
                    if($target.data('type-slider') != 'float') {
                        if(handle == 1) {
                            return Math.ceil(values[handle]);
                        } else {
                            return Math.floor(values[handle]);
                        }
                    }
                    //Иначе возвращаем значние
                    else {
                        return values[handle];
                    }
                };

                //Изменения значений
                rangeSlider.noUiSlider.on('update', function( values, handle ) {
                    //Обертка слайдера
                    let $target = $(this.target).parent();

                    //Если data-value соответствующего инпута не пустое - подставить в value
                    if($($target.find('.b-range-slider__input')[handle]).data('value')) {
                        rangeInputs[handle].value = rounding($target, values, handle);
                    }
                    //Если счетчик x2 от общего количества блоков - инициализация прошла и все дальнейшие изменения записываем уже в value
                    else if(isInit === rangeSliders.length * 2) {
                        rangeInputs[handle].value = rounding($target, values, handle);
                    }
                    //Иначе все min max значения записываем в placeholder
                    else {
                        rangeInputs[handle].placeholder = rounding($target, values, handle);

                        isInit++;
                    }


                });

                //Ввод через инпут
                rangeInputs.forEach((input, handle) => {
                    input.addEventListener('blur', function() {
                        self.setSliderHandle(rangeSlider, handle, this.value);
                    });
                });

                //Отпустили ползунок
                rangeSlider.noUiSlider.on('end', function( values, handle ) {
                    smartFilter.keyup(rangeInputs[handle]);
                    /*smartFilter.reload(rangeInputs[0]);*/
                });
            }
        }
    },
    
    setSliderHandle(rangeSlider, i, value) {
        let r = [null,null];
        r[i] = value;
        rangeSlider.noUiSlider.set(r);
    }
};

module.exports = rangeSlider;