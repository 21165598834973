import Blazy from 'blazy';

let lazy = {
    run() {
        this.isDelayInit = false;
        let delay;
        let initDelay = () => {
            if(this.isDelayInit) return;
            clearTimeout(delay);
            delay = setTimeout(() => this.delay(), 10);
        };

        this.bLazy = new Blazy({
            src: 'data-src',
            selector: '.lazy',
            successClass: 'init',
            offset: 300,
            success: initDelay(),
            breakpoints: [
                {
                    width: 767,
                    src: 'data-src-small',
                },
                {
                    width: 1023,
                    src: 'data-src-t',
                },
            ]
        });

        window.blazy = this.bLazy;
    },
    update() {
        this.bLazy.revalidate();
        objectFitImages();
    },
    delay() {
        //this.delayedImages();
        this.isDelayInit = true;
    },
    delayedImages() {
        const delayedImages = $('.lazy:not(.init)');
        this.bLazy.load(delayedImages, true);
    },


    /*interval: null,
    ajaxLoad() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {

            if($('.blocks__inner .lazy:not(.init)').length)
                this.update();
            else
                clearInterval(this.interval)

        }, 100)
    }*/
};

module.exports = lazy;
