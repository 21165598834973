import axios from "axios"
import {siteBaseAuth} from "../config/config.axios.local";

class VodohodClient {
	constructor() {
		this.service = axios.create({
			transformResponse: [
				data => {
					data = JSON.parse(data)
					return data.result
				}
			]
		})
		this.service.interceptors.request.use((config) => {
			config.baseURL = `/rest/1/qyi72zk41gcaqpho`
			if(siteBaseAuth) {
				config.headers = {Authorization: 'Basic ' + btoa(siteBaseAuth)}
			}
			return config
		}, (error) => {
			return Promise.reject(error)
		})
	}

	sendDocumentByEmail(emails, documents) {
		return this.service.post('/send-document-by-email', {emails, documents})
	}

	getManualDiscounts() {
		return this.service.get('/get_manual_discounts')
	}

	sendCodeEmail(crs_user_id, count_points) {
		return this.service.get('/send_code_email', {
			params: {
				crs_user_id,
				count_points,
				user_token: getCookie('user_token')
			}
		})
	}

	sendCodeSms(crs_user_id, count_points) {
		return this.service.get('/send_code_sms', {
			params: {
				crs_user_id,
				count_points,
				user_token: getCookie('user_token')
			}
		})
	}

	getShareLink(id) {
		return this.service.get(`/get_share_link?id=${id}`)
	}

	getPaymentLink(id) {
		return this.service.get(`/get_payment_link?id=${id}`)
	}

	getToken() {
		return this.service.get('/get_token')
	}

	getLandPointsByXmlId(land_points) {
		return this.service.get('/get-land-points-by-xml-id', {data: {land_points}})
	}
}

const vodohodClient = new VodohodClient()
export default vodohodClient