const axios = require("axios");

const pageStatistic = {
	vueComponents: {
		'#vue-app-price-table': 'priceTableLoaded'
	},
	run() {
		let eventName = 'mainJsLoaded'
		for (const id in this.vueComponents) {
			if(document.querySelector(id)) {
				eventName = this.vueComponents[id]
			}
		}
		document.addEventListener(eventName, () => {
			const data = $('#page_statistic').val()
			this.collectStatistic(data ? JSON.parse(data) : {})
		})
	},
	collectStatistic(data = {}) {
		if(!jQuery.isEmptyObject(data)) {
			data['STATISTIC']['UF_EXEC_TIME'] = 0
			const startExecTime = +sessionStorage.start_exec_time
			const now = Date.now()
			if(startExecTime) {
				data['STATISTIC']['UF_EXEC_TIME'] = ((now - startExecTime) / 1000).toFixed(2)
				axios
					.post(`${process.env.BASE_WILDLIF_URL}/save_statistic`, {data: data['STATISTIC']})
					.catch(e => console.error(e))
			}
		}
		this.setExecTime()
	},
	setExecTime() {
		sessionStorage.start_exec_time = Date.now()
		setTimeout(() => this.setExecTime(), 25)
	}
}

module.exports = pageStatistic
