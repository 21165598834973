var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "services" }, [
    _c(
      "div",
      { staticClass: "services__tabs" },
      [
        _c(
          "button",
          {
            staticClass: "services__tab",
            class: { "is-active": _vm.activeTab === 0 },
            on: {
              click: function ($event) {
                return _vm.toggleTab(0)
              },
            },
          },
          [_vm._v("\n\t\t\tВсе\n\t\t")]
        ),
        _vm._v(" "),
        _vm._l(_vm.tabs.names, function (item, index) {
          return _c(
            "button",
            {
              key: index,
              staticClass: "services__tab",
              class: { "is-active": _vm.tabs.ids[index] === _vm.activeTab },
              on: {
                click: function ($event) {
                  return _vm.toggleTab(_vm.tabs.ids[index])
                },
              },
            },
            [
              _c("img", {
                staticClass: "services__icon",
                attrs: { src: "", alt: "" },
              }),
              _vm._v("\n\t\t\t" + _vm._s(item) + "\n\t\t"),
            ]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "services__groups" },
      [
        _vm._l(_vm.tabs.ids, function (tabId, index) {
          return [
            _c(
              "div",
              {
                staticClass: "services__body",
                class: {
                  active: tabId === _vm.activeTab || _vm.activeTab === 0,
                },
              },
              [
                _c("div", { staticClass: "services__body-title" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.tabs.names[index]) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "services__list" },
                  _vm._l(_vm.tabItems(tabId), function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "services__item",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openModal(item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "services__name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "services__text" }, [
                          _vm._v(_vm._s(item.annotation)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "services__footer" }, [
                          _c("span", { staticClass: "services__price" }, [
                            _c(
                              "span",
                              { staticClass: "services__price-label" },
                              [_vm._v("Цена: ")]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(item.price.basePrice)
                                ) + " ₽\n\t\t\t\t\t\t\t"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          item.minAge && item.minAge >= 18
                            ? _c("span", { staticClass: "services__age" }, [
                                _vm._v("18+"),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.opened
      ? _c("div", { staticClass: "services__wrapper" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeServiceDiscount,
                  expression: "closeServiceDiscount",
                },
              ],
              staticClass: "services__modal",
            },
            [
              _c("div", { staticClass: "services__modal-header" }, [
                _c("div", { staticClass: "services__modal-image" }, [
                  _c("img", { attrs: { src: _vm.item.mainImage, alt: "" } }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "services__modal-info" }, [
                  _c("p", { staticClass: "services__modal-title" }, [
                    _vm._v(_vm._s(_vm.item.name)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "services__price" }, [
                    _vm._v("\n\t\t\t\t\t\tСтоимость за человека"),
                    _c("br"),
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm._f("formatPrice")(_vm.item.price.basePrice)
                        ) +
                        " ₽\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "services__modal-close",
                    on: { click: _vm.closeServiceDiscount },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          "data-v-68cdcfc3": "",
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "data-v-68cdcfc3": "",
                              "clip-path": "url(#clip0_437_16670)",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "data-v-68cdcfc3": "",
                                d: "M6.26264 8L0 1.7775L1.78896 0L9.84056 8L1.78896 16L0 14.2225L6.26264 8Z",
                                fill: "#9A243D",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                "data-v-68cdcfc3": "",
                                d: "M9.73736 8L16 1.7775L14.211 0L6.15944 8L14.211 16L16 14.2225L9.73736 8Z",
                                fill: "#9A243D",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", { attrs: { "data-v-68cdcfc3": "" } }, [
                          _c(
                            "clipPath",
                            {
                              attrs: {
                                "data-v-68cdcfc3": "",
                                id: "clip0_437_16670",
                              },
                            },
                            [
                              _c("rect", {
                                attrs: {
                                  "data-v-68cdcfc3": "",
                                  width: "16",
                                  height: "16",
                                  fill: "white",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "services__modal-body" }, [
                _c("div", {
                  staticClass: "services__modal-text",
                  domProps: { innerHTML: _vm._s(_vm.item.description) },
                }),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }