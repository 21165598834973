import Vue from 'vue'
import App from './App.vue'
Vue.filter('formatPrice', value => `${(Number(value) / 100).toLocaleString()}`)
if (document.querySelectorAll('#services-app').length) {
	new Vue({
		el: '#services-app',
		components: {
			App,
		},
	})
}
