import './svg/svg'

// Load plugins
import 'magnific-popup/dist/jquery.magnific-popup.min.js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'
import './js/_vendor/jquery.scrollbar.min.js'

import svg4everybody from 'svg4everybody'

window.svg4everybody = svg4everybody

import objectFitImages from 'object-fit-images'

window.objectFitImages = objectFitImages

import PerfectScrollbar from 'perfect-scrollbar'

window.PerfectScrollbar = PerfectScrollbar

// Load styles
import 'normalize.css/normalize.css'
import 'swiper/dist/css/swiper.min.css'
import 'magnific-popup/dist/magnific-popup.css'
import 'nouislider/dist/nouislider.min.css'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import 'slick-carousel/slick/slick.css'

import './styles/imports'

import 'inputmask/dist/jquery.inputmask.min.js'

// load modules
import './js/sidebar-dropdown.js'
import './js/popup-link.js'
import './js/popup-youtube.js'
import './js/popup-races.js'
import './js/popup-image-preview.js'
import './js/path-items.js'
import './js/pagination.js'
import './js/check-is-mobile'
import './js/jquery.suggestions.min.js'

import iframeInteraction from './js/iframeInteraction'
import lazy from './js/lazy'
import svgUse from './js/svgUse'
import modals from './js/modals'
import counter from './js/counter'
import cookie from './js/cookie'
import cookieHandler from './js/cookieHandler'
import mainSlider from './js/slider/mainSlider'
import desktopMenu from './js/menu/desktopMenu'
import mobileMenu from './js/menu/mobileMenu'
import sliders from './js/slider/sliders'
import toggleList from './js/toggleList'
import showMore from './js/showMore'
import headerFix from './js/fixOn/fixOnScroll'
import tabs from './js/tabs'
import cruises from './js/cruises'
import tooltip from './js/tooltip'
import inputs from './js/inputs'
import select from './js/select'
import inputMask from './js/inputMask'
import cabinTypesFilter from './js/cabinTypesFilter'
import faqThemesFilter from './js/faqThemesFilter'
import datePicker from './js/datepicker'
import validation from './js/validation'
import blockFixOnScroll from './js/fixOn/blockFixOnScroll'
import rangeSlider from './js/rangeSlider'
import sectionAsideMobile from './js/sectionAsideMobile'
import sendPdfPageForm from './js/sendPdfPageForm'
import showFileName from './js/showFileName'
import editorTableMobile from './js/editorTableMobile'
import scroll from './js/scroll'
import reInitForms from './js/reInitForms'
import selectedCruiseCookie from './js/selectedCruiseCookie'
import mainPageFilter from './js/mainPageFilter'
import formSubmit from './js/formSubmit'
import loader from './js/loader'
import lazyPrint from './js/lazyPrint'
import reviewsFilter from './js/reviewsFilter'
import reviewsForm from './js/reviewsForm'
import excursionsFilter from './js/excursionsFilter'
import rating from './js/rating'
import loadPrices from './js/loadPrices'
import fancyBox from './js/fancybox'
import banner from './js/banner'
import scrollbar from './js/scrollbar'
import services from './js/services'
import clampText from './js/clamp-text'
import perfectScroll from './js/perfect-scroll'
import spoller from './js/spoller'
import {YandexCaptcha} from './js/YandexCaptcha'
import drop from './js/drop'

import ajaxLoad from './js/ajax-load'
import basketEventController from './js/basketEventController'
import videoCruises from './js/video-cruises'
import certificates from './js/certificates'
import paymentLinkConfirmDialogModal from './js/paymentLinkConfirmDialogModal'
import inputSecondName from './js/input-second-name.js'
import langPicker from './js/lang-picker.js'
import tabsSimple from './js/tabs-simple.js'
import simpleDropdown from './js/simple-dropdown.js'
import accoSimple from './js/acco-simple.js'
import accoSimpleLkA from './js/acco-simple-lk-a.js'
import imgChanger from './js/img-changer.js'
import tabFixOnScroll from './js/fixOn/tabFixOnScroll.js'
import clearForm from './js/clear-form.js'
import galleryOpener from './js/gallery-opener.js'
import pathGalleryOpener from './js/path-gallery-opener.js'
import modalGalleryOpener from './js/modal-gallery-opener.js'
import waitingForm from './js/waiting-form.js'
import vodohodPro from './js/vodohod-pro.js'
import partner_filter from './js/partner-filter.js'
import simpleForm from './js/simple-form.js'
import cruiseList from './js/cruise-list.js'
import copy_detail from './js/copy-detail.js'
import simpleCruises from './js/simple-cruises.js'
import ownerSearch from './js/orders.filter/ownerSearch'
import ordersFilterSettings from './js/orders.filter/ordersFilterSettings'
import {OrdersFilter} from "./js/orders.filter/ordersFilter"
import ordersWidgetFilter from './js/orders.filter/ordersWidgetFilter'
import managerFilter from './js/orders.filter/managerFilter'
import imageUploader from './js/image-uploader.js'
import addReviewForm from './js/cruise/addReviewForm'
import pageStatistic from './js/pageStatistic'

import bannerChanger from './js/banner-changer'
import pfScrollbar from './js/perfect-scrollbar'
import simpleText from './js/simple-text.js'
import addBodyClass from './js/add-body-class.js'
import testimonialGallery from './js/popup-review-gallery.js'
import listMore from './js/list-more.js'
import reviewsModalOpener from './js/reviewsModalOpener.js'
import playVide from './js/play-video.js'
import crmEventListener from './js/crm/eventListener'

import tabsFilter from './js/tabsFilter'

import globalTooltips from './js/global-tooltips'

try {
	// init modules
	imageUploader.run()
	crmEventListener.run()
	datePicker.run()
	select.run()
	iframeInteraction.run()
	objectFitImages()
	lazy.run()
	svgUse.run()
	modals.run()
	cruises.run()
	counter.run()
	cookie.run()
	cookieHandler.run()
	desktopMenu.run()
	basketEventController.run()
	paymentLinkConfirmDialogModal.run()
	sliders.run()
	tabs.run()
	toggleList.run()
	showMore.run()
	headerFix.run()
	mobileMenu.run()
	tooltip.run()
	inputs.run()
	inputMask.run()
	cabinTypesFilter.run()
	faqThemesFilter.run()
	validation.run()
	blockFixOnScroll.run()
	rangeSlider.run()
	sectionAsideMobile.run()
	sendPdfPageForm.run()
	showFileName.run()
	editorTableMobile.run()
	selectedCruiseCookie.run()
	mainPageFilter.run()
	formSubmit.run()
	loader.run()
	lazyPrint.run()
	reviewsFilter.run()
	reviewsForm.run()
	excursionsFilter.run()
	rating.run()
	loadPrices.run()
	fancyBox.run()
	banner.run()
	scrollbar.run()
	videoCruises.run()
	certificates.run()
	inputSecondName.run()
	services.run()
	clampText.run()
	perfectScroll.run()
	langPicker.run()
	tabsSimple.run()
	simpleDropdown.run()
	accoSimple.run()
	accoSimpleLkA.run()
	imgChanger.run()
	bannerChanger.run()
	pfScrollbar.run()
	simpleText.run()
	addBodyClass.run()
	testimonialGallery.run()
	listMore.run()
	tabFixOnScroll.run()
	clearForm.run()
	galleryOpener.run()
	pathGalleryOpener.run()
	modalGalleryOpener.run()
	waitingForm.run()
	reviewsModalOpener.run()
	partner_filter.run()
	simpleForm.run()
	cruiseList.run()
	copy_detail.run()
	simpleCruises.run()
	tabsFilter.run()
	mainSlider.run()
	managerFilter.run()
	playVide.run()
	globalTooltips.run()
} catch (e) {
	console.error(e)
}
// Collect page speed statistic
pageStatistic.run()

import './js/vue/compare/comparePage'
import './js/vue/compare/compareHeader'
import './js/vue/compare/compareAdd'
import './js/vue/compare/compareSidebar'
import './js/vue/services/main'
import './js/vue/discounts/main'

const ordersFilter = new OrdersFilter()

// Export components
export {
	cookie,
	validation,
	cruiseList,
	modals,
	reInitForms,
	scroll,
	lazy,
	tooltip,
	lazyPrint,
	loadPrices,
	select,
	toggleList,
	datePicker,
	ajaxLoad,
	waitingForm,
	vodohodPro,
	reviewsModalOpener,
	perfectScroll,
	ownerSearch,
	ordersFilterSettings,
	ordersFilter,
	ordersWidgetFilter,
	inputMask,
	inputSecondName,
	inputs,
	simpleText,
	testimonialGallery,
	addReviewForm,
	YandexCaptcha,
	drop,
	spoller
}
