const clearForm = {
    run() {
        this.$btns = $('[data-clear-form-id]');

        if(this.$btns.lenght)
            return false;

        this.$btns.on('click', (evt) => {
            this.onClickBtn({
                $btn: $(evt.currentTarget),
            });
        });
    },

    onClickBtn({$btn}) {
        const formID = $btn.attr('data-clear-form-id');
        const $form = $(`#${formID}`);
        const $reset = $form.find('.c-filter__clear');

        if(!$form.length) return false;
        if($reset.length) $reset.trigger('click');
    },
}

module.exports = clearForm;