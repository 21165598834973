import {CrsClient} from "../crs-client";

class CrsCompanies extends CrsClient
{
    documentsBills(params = {}) {
        return this.service.get('/companies/documents-bills', {params})
    }

    managersList(id, params = {}) {
        params['id'] = id
        return this.service.get('/managers/list', {params})
    }
}

const crsCompanies = new CrsCompanies()
export default crsCompanies