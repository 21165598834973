let fancyBox = {
	run() {
	  $(document).ready(function() {
		$('[data-fancybox]').fancybox({
		  buttons: ["close"],	
		  type: "iframe",
		  toolbar: true,
		  smallBtn: false,
		  iframe: {
			preload: false
		  },
		  afterShow: function(instance, slide) {
			$('.fancybox-container').on('click', function(event) {
			  if ($(event.target).hasClass('fancybox-container')) {
				$.fancybox.close();
			  }
			});
		  },
		  beforeClose: function(instance, slide) {
			// Remove the click event from the Fancybox overlay when closing the gallery
			$('.fancybox-container').off('click');
		  }
		});
	  });
  
	  $(document).ready(function() {
		$('[data-fancybox-sheme]').fancybox({
		  buttons: ["zoom", "close"],
		  type: "image",
		  toolbar: true,
		  slideClass: "ship-gallery__scheme-fancybox",
		  clickContent: function(current, event) {
			return current.type === "image" ? "zoom" : false;
		  },
		  mobile: {
			idleTime: false,
			clickSlide: function(current, event) {
			  return current.type === "image" ? "toggleControls" : "close";
			},
			clickContent: function(current, event) {
			  return current.type === "image" ? "zoom" : false;
			},
			dblclickSlide: function(current, event) {
			  return current.type === "image" ? "zoom" : false;
			}
		  },
		});
	  });
	}
  };
  
  module.exports = fancyBox;