let fixOnScroll = {
	run() {
		this.isDetail = $('.h__bottom--tabs').length && $(window).innerWidth() >= 1024;

		this.$item = this.isDetail ? $('.d-p-tabs__inner') : $('.h__bottom');
		if ($('.d-p-tabs__inner').hasClass('fixed')) {
			$('.d-p-tabs__inner').removeClass('fixed')
			this.tabPosition = this.$item.offset().top + this.detailPageMargin();
			$('.d-p-tabs__inner').addClass('fixed')
		} else {
			this.tabPosition = this.$item.offset().top + this.detailPageMargin();
		}
		this.position = this.isDetail ? this.$item.offset().top + this.detailPageMargin() : this.$item.offset().top;

		this.setWrap();

		this.scroll();
		$(window).on('scroll', () => {
			//this.tabPosition = this.$item.offset().top + this.detailPageMargin();
			this.scroll()
		});
	},

	refreshProps() {
		this.isDetail = $('.h__bottom--tabs').length && $(window).innerWidth() >= 1024;
		this.$item = this.isDetail ? $('.d-p-tabs__inner') : $('.h');
		this.position = this.isDetail ? this.$item.offset().top + this.detailPageMargin() : this.$item.offset().top;
	},

	detailPageMargin() {
		const windowWidth = $(window).innerWidth();

		if (windowWidth < 1280)
			return 9;
		else if (windowWidth < 1800)
			return 25;
		else
			return 37;
	},

	setWrap() {
		$('.h').css('height', '');
		$('.h').height($('.h').height());
	},

	scroll() {
		this.refreshProps();

		this.currentPosition = $(window).scrollTop();
		if (this.isDetail) {
			if (this.currentPosition >= this.tabPosition) {
				this.$item.addClass('fixed');
				$('.pro-banner').addClass('pro-banner-fixed');
			} else {
				this.$item.removeClass('fixed');
				$('.pro-banner').removeClass('pro-banner-fixed');
			}
		} else {
			if (this.currentPosition > this.position) {
				this.$item.find('.h__bottom').addClass('fixed');
			} else {
				window.matchMedia("(max-width: 1279px)").matches && !this.isDetail ? this.$item.find('.h__bottom').addClass('fixed') : this.$item.find('.h__bottom').removeClass('fixed');
			}
		}
	},
};

module.exports = fixOnScroll;
