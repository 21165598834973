import Vue from 'vue'
import compareAdd from './compareAdd/compare-add'
import store from './store/index'

const items = document.querySelectorAll('.compare-add-app')

items.forEach(el => {
	new Vue({
		el,
		components: {
			compareAdd,
		},
		store,
	})
})
