const accoSimpleLkA = {
    run() {
        if (!$('.js-lk-a-menu-wrap').length) {
            return false;
        }
        const classOverlay = '.js-lk-a-menu-overlay'
        const classMenu = '.js-lk-a-menu'

        this.$menu = $(classMenu)
        this.$btn = $('.js-lk-a-menu-btn')
        const menu = $('.js-header-agent-menu').clone()
        this.$menu.append(menu[0].innerHTML)
        this.$overlay = $(classOverlay)

        this.$box = $(classMenu).find('[data-acco-ex]');
        if(this.$box.length) {
            this.$openers = this.$box.find('[data-acco-ex-opener]');
            this.$openers.on('click', evt => {
                this.toggleItem({
                    $item: $(evt.currentTarget).closest('[data-acco-ex-item]'),
                });
            });
        }

        this.$btn.on('click', () => {
            this.toggleItem({$item: this.$menu});
            this.toggleItem({$item: this.$overlay});
        })

        $(document).on('click', classOverlay, () => {
            this.$btn.trigger('click')
        })

    },
    /* hello  23*/
    toggleItem({$item}) {
        $item.hasClass('opened') ? this.hideItem({$item: $item}) : this.showItem({$item: $item});
    },

    hideItem({$item}) {
        $item.removeClass('opened');
    },

    showItem({$item}) {
        $item.addClass('opened');
    },
};

module.exports = accoSimpleLkA;
