const bannerChanger = {
    run() {
        this.$box = $('[data-banner-changer]');

        if(this.$box.length) {
            this.$changers = this.$box.find('[data-banner-img]');

            this.$changers.on('click', evt => {
                this.changeImg({
                    $changer: $(evt.currentTarget),
                })
            });
        }
    },

    changeImg(props) {
        const $box = props.$changer.closest('[data-banner-changer]');
        const $mainImg = $box.find('[data-banner-main-img]');

        $mainImg.css('background-image', 'url(' + props.$changer.attr('data-large-img') + ')');
    },

};

module.exports = bannerChanger;