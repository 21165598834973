let cabinTypesFilter = {
    run() {
        $(document).on('click', '[data-cabin-type-head]', event => {
            let type = $(event.target).data('cabin-type-head');
            if (type == 'all') {
                cabinTypesFilter.showAll();
                if(typeof $(event.currentTarget).attr('data-cabin-with-tags') != 'undefined') {
                    this.removeAllTagItem({
                        $box: $(event.currentTarget).closest('[data-cabins-types-head]'),
                    });
                }
            } else {
                if ($('[data-cabin-type-head=all]').hasClass('active')) {
                    $('[data-cabin-type-head]').each(function () {
                        $(this).removeClass('active');
                    });
                    $('[data-cabin-type-content]').each(function () {
                        $(this).hide();
                    });
                }

                if ($(event.target).hasClass('active')) {
                    $(event.target).removeClass('active');
                } else {
                    $(event.target).addClass('active');
                }

                let clickedType = $('[data-cabin-type-content=' + type + ']');

                if (clickedType.is(":hidden")) {
                    clickedType.show();
                } else {
                    clickedType.hide();
                }

                if ($('[data-cabin-type-head].active').length == 0) {
                    cabinTypesFilter.showAll();
                }

                if(typeof $(event.currentTarget).attr('data-cabin-with-tags') != 'undefined') {
                    this.updCabinTags({
                        $box: $(event.currentTarget).closest('[data-cabins-types-head]'),
                    });
                }
            }
        });
    },
    showAll() {
        $('[data-cabin-type-content]').each(function () {
            $(this).show();
        });
        $('[data-cabin-type-head]').each(function () {
            $(this).removeClass('active');
        });
        $('[data-cabin-type-head=all]').addClass('active')
    },
    updCabinTags({$box}) {
        if(!$box.length) {
            console.error(`Cant find [data-cabins-types-head]. $box = ${$box}`);
            return false;
        }

        const $actives = $box.find('[data-cabin-type-head].active');

        $actives.each((i, item) => {
           this.addTagItem({
               $box: $box,
               $item: $(item),
           });
        });
    },

    addTagItem({$box, $item}) {
        if(!$box.find('[data-cabins-tags]').length) {
            console.error(`Cant find [data-cabins-tags]. ${$box.find('[data-cabins-tags]')}`);
            return false;
        }

        const $tagBox = $box.find('[data-cabins-tags]');
        const isTagExist = !!$tagBox.find('[data-cabin-tag-type-head="'+ $item.attr('data-cabin-type-head') +'"]').length;

        if(!isTagExist && $item.attr('data-cabin-type-head') != 'all') {
            const $newTag = this.createTagItem({
                name: $item.text(),
                type: $item.attr('data-cabin-type-head'),
            });


            $newTag.find('.d-tabs__tags-item-cross').on("click", (evt) => {
                this.removeTagItem({
                    $tagBox: $tagBox,
                    type: $(evt.currentTarget).closest('.d-tabs__tags-item').attr('data-cabin-tag-type-head'),
                });
            });

            $tagBox.append($newTag);
        }
    },

    createTagItem({name, type}) {
        if(typeof type != 'string') {
            console.error(`method - createTagItem. prop - type. Not a string. type = ${type}`);
            return false;
        }
        return $(`<div class="d-tabs__tags-item" data-cabin-tag-type-head="${type}">
                    <span class="d-tabs__tags-item-text">${name}</span>
                    <svg class="d-tabs__tags-item-cross" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.18748 4.00916C5.03031 3.85737 4.81981 3.77337 4.60131 3.77527C4.38281 3.77717 4.1738 3.86481 4.0193 4.01932C3.86479 4.17382 3.77715 4.38283 3.77525 4.60133C3.77335 4.81983 3.85735 5.03033 4.00915 5.1875L8.82165 10L4.00831 14.8125C3.92872 14.8894 3.86524 14.9813 3.82156 15.083C3.77789 15.1847 3.7549 15.294 3.75394 15.4047C3.75298 15.5153 3.77406 15.625 3.81596 15.7275C3.85786 15.8299 3.91974 15.9229 3.99798 16.0012C4.07623 16.0794 4.16927 16.1413 4.27168 16.1832C4.3741 16.2251 4.48383 16.2462 4.59448 16.2452C4.70513 16.2442 4.81448 16.2213 4.91615 16.1776C5.01782 16.1339 5.10977 16.0704 5.18665 15.9908L9.99998 11.1783L14.8125 15.9908C14.9696 16.1426 15.1801 16.2266 15.3986 16.2247C15.6171 16.2228 15.8262 16.1352 15.9807 15.9807C16.1352 15.8262 16.2228 15.6172 16.2247 15.3987C16.2266 15.1802 16.1426 14.9697 15.9908 14.8125L11.1783 10L15.9908 5.1875C16.1426 5.03033 16.2266 4.81983 16.2247 4.60133C16.2228 4.38283 16.1352 4.17382 15.9807 4.01932C15.8262 3.86481 15.6171 3.77717 15.3986 3.77527C15.1801 3.77337 14.9696 3.85737 14.8125 4.00916L9.99998 8.82167L5.18748 4.00833V4.00916Z" fill="#BEBEBE"/>
                    </svg>
                </div>`);
    },

    removeTagItem({$tagBox, type}) {
        const $box = $tagBox.closest('[data-cabins-types-head]');
        const $tag = $tagBox.find(`[data-cabin-tag-type-head="${type}"]`);
        const $el = $box.find(`[data-cabin-type-head="${type}"]`);
        
        if ($tag.length) {
            $tag.remove();
        }

        if ($el.hasClass('active')) {
            $el.trigger('click');
        }
    },

    removeAllTagItem(props) {
        const $tagBox = props.$box.find('[data-cabins-tags]');
        $tagBox.children().remove();
    }
};

module.exports = cabinTypesFilter;