<template>
	<a
		v-if="items && items.length > 0"
		href="javascript:;"
		class="btn-header-compare btn btn--red btn-bdrs"
		@click="toggleSidebar"
		>СРАВНЕНИЕ
		<span
			>({{ items ? items.length : 0 }})
			<span style="display: none">{{ status }}</span>
		</span>
	</a>
</template>

<script>
import {mapActions} from 'vuex'
import ClickOutside from 'vue-click-outside'
export default {
	name: 'MobileBtnCompare',
	directives: {
		ClickOutside,
	},
	data() {
		return {
			opened: false,
			loading: false,
		}
	},
	computed: {
		items() {
			return this.$store.state.items
		},
		status() {
			this.opened = this.$store.state.status
			return this.$store.state.status
		},
	},
	methods: {
		...mapActions(['TOGGLE_SIDEBAR']),

		toggleSidebar() {
			if (this.items) {
				this.opened = !this.opened
				this.TOGGLE_SIDEBAR(this.opened)
				if ($('.d-prices-basket .b-modal-side__close').length) {
					$('.d-prices-basket .b-modal-side__close').trigger('click')
				}
			}
		},
	},
}
</script>
