import Vue from 'vue'
import {mapActions} from 'vuex'
export default Vue.component('CompareAdd', {
	props: ['cruise'],
	data() {
		return {
			compare: false,
			length: 0,
			load: false,
			visible: 'clip-path: unset',
		}
	},
	computed: {
		items() {
			const result = this.$store.state.items
			if (result) {
				this.length = this.$store.state.items ? this.$store.state.items.length : 0
				for (let i = 0; i < result.length; i++) {
					if (result[i].ID == this.cruise) {
						this.compare = true
						return true
					}
				}
			}
			this.compare = false
			return false
		},
	},
	methods: {
		...mapActions(['addToCompare', 'removeFromCompare']),
		toggleCompare() {
			if (this.length < 10) {
				this.load = true
				this.compare = !this.compare
				if (this.compare) {
					this.addToCompare(this.cruise).then(() => {
						this.load = false
					})
					return
				}
				this.removeFromCompare(this.cruise).then(() => {
					this.load = false
				})
			}
		},
	},
})
