const imgChanger = {
    run() {
        this.$box = $('[data-img-changer]');

        if(this.$box.length) {
            this.$changers = this.$box.find('[data-small-img]');

            this.$changers.on('click', evt => {
                this.changeImg({
                    $changer: $(evt.currentTarget),
                })
            });
        }
    },

    changeImg(props) {
        const $box = props.$changer.closest('[data-img-changer]');
        const $mainImg = $box.find('[data-main-img]');

        $mainImg.attr('src', props.$changer.attr('data-small-img'));
    },

};

module.exports = imgChanger;