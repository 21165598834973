let reviewsForm = {
    run() {
        $(document).on('change', '[data-reviews-form] [data-file-upload]', function () {
			const input = $(this)[0];
			let $container = $(this).closest('[data-file-wrap]');
            let fieldErrorExtension = $container.find('[data-form-error-extension]');
            if (input.files && input.files[0]) {
                if (input.files[0].type.match('image.*')) {
					const reader = new FileReader();
					reader.onload = function (e) {
						const div = document.createElement('div');
						div.classList.add('b-modal-i__img');
                        div.setAttribute('data-photos-item', null);
                        div.innerHTML = ['<img src="', e.target.result, '">' + '<a href="javascript:void(0)" class="b-modal-i__del" data-photos-del>' + BX.message('WEBFORM_REVIEW_FORM_PHOTOS_DELETE') + '</a>'].join('');
						const newFileId = window.reviewsForm.fileAnswerIds.shift();
						if (window.reviewsForm.fileAnswerIds.length === 0) {
                            $('[data-add-photos-btn]').addClass('hidden');
                        }
                        div.innerHTML += '<input name="form_file_' + newFileId + '" data-file-answer-id="' + newFileId + '" type="file" style="display: none;">';
                        $('[data-photos]')[0].appendChild(div);
                        $('[name=form_file_' + newFileId + ']')[0].files = input.files;
                    };
                    reader.readAsDataURL(input.files[0]);
					const photosWrap = $('[data-photos-wrap]')
                    if (photosWrap.hasClass('null')) {
						photosWrap.removeClass('null');
                    }
                    $container.removeClass('error');
                    fieldErrorExtension.addClass('hidden');
                } else {
                    $container.addClass('error');
                    fieldErrorExtension.removeClass('hidden');
                }
            }
        });

        $(document).on('click', '[data-reviews-form] [data-photos-del]', function (e) {
            e.preventDefault();
            let $this = $(this);
            let $wrapper = $this.closest('[data-photos-wrap]');
            let $gallery = $this.closest('[data-photos]');
            let $photos = $gallery.find('[data-photos-item]');
            let $img = $this.parent();
            window.reviewsForm.fileAnswerIds.push($img.find('[data-file-answer-id]').attr('data-file-answer-id'));
            if ($photos.length === 1) {
                $wrapper.addClass('null');
            } else if ($photos.length <= 20) {
                $('[data-add-photos-btn]').removeClass('hidden');
            }
            $img.remove();
        })
    },
};

module.exports = reviewsForm;
