import {OrdersFilter} from "./ordersFilter";

class OrdersWidgetFilter extends OrdersFilter
{
    init() {
        super.init()
        const cruiseArrivalCitySelect = $('[name="filter[cruise_arrival_city]"]')
        if (cruiseArrivalCitySelect.length > 0) {
            this.setCitiesList(cruiseArrivalCitySelect, {'filter[canDisembark]': 1})
        }
    }
}
const ordersWidgetFilter = new OrdersWidgetFilter()
module.exports = ordersWidgetFilter