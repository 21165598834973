<template>
	<div v-if="items && items.length > 0" class="compare-cart" :class="['compare-cart--' + cls]">
		<button
			v-if="items"
			class="compare-cart__button"
			:class="cls ? 'compare-cart__button--' + cls : ''"
			@click="toggleSidebar"
		>
			<img src="/local/client/icons/icon-compare-carts.svg?inline" alt="" />
			<span>({{ items ? items.length : 0 }})</span>
		</button>
		<div style="display: none">{{ status }}</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex'
import ClickOutside from 'vue-click-outside'
export default {
	directives: {
		ClickOutside,
	},
	props: {
		cls: '',
	},
	data() {
		return {
			opened: false,
			loading: false,
		}
	},
	computed: {
		items() {
			return this.$store.state.items
		},
		status() {
			this.opened = this.$store.state.status
			return this.$store.state.status
		},
	},
	methods: {
		...mapActions(['TOGGLE_SIDEBAR']),

		toggleSidebar() {
			if (this.items) {
				this.opened = !this.opened
				this.TOGGLE_SIDEBAR(this.opened)
        const sideClose = $('.d-prices-basket .b-modal-side__close')
				if (sideClose.length) {
					sideClose.trigger('click')
				}
			}
		},
	},
}
</script>
