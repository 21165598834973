/* мобильная навигация лк / статика */
let sectionAsideMobile = {
    run() {
        if(window.innerWidth < 1024)
            this.init();
    },
    init() {
        const $wrap = $($('.section-aside-menu__items')[0]);
        const $activeBtn = $wrap.find('.active');
        const $firstBtn = $wrap.find('.first-link');
        var minHeight; /* высота активного элемента */
        var maxHeight; /* высота активного элемента */
        const animationTime = 300;
    
        let isOpen = false;

        if ($activeBtn.length > 0) {
            customListener($activeBtn, 'active');
            if ($firstBtn) {
                $firstBtn.removeClass('first-link');
            }
        } else {
            customListener($firstBtn, 'first-link');
        }

        function customListener(btn, string) {
            minHeight = btn.innerHeight(); /* высота активного элемента */
            maxHeight = minHeight * ($wrap.find(' > a:not(.'+string+')').length + 1); /* высота активного элемента */
            if (string === 'first-link') {
                string = 'first-link:not(.opened)';
            }
            $(document).on('click', '.section-aside-menu__items .'+string+'', (e) => {
                e.preventDefault();
                $wrap.animate({'height': isOpen ? minHeight : maxHeight}, animationTime); /* свернуть / развернуть */

                btn.toggleClass('opened');

                isOpen = !isOpen;

                $(document).off('click.closeNav');
                $(document).on('click.closeNav', (e)=> {
                    const $this = $(e.target);

                    if(!$this.parents('.b-nav').length) { /* свернуть по клику вне меню */
                        $wrap.animate({'height': minHeight}, animationTime);

                        btn.removeClass('opened');

                        $(document).off('click.closeNav');
                        isOpen = false;
                    }
                })
            });
        }
    }
};

module.exports = sectionAsideMobile;