
import Vue from "vue";
import compareAdd from "./vue/compare/compareAdd/compare-add";
import store from "./vue/compare/store/index";

document.addEventListener('mainJsLoaded', function () {
  $(document).on('click', '.show-more.link.searchAjaxPnav', function () {
      var container = $(this.closest('[data-pagination-container]'));
      var containerName = container.attr('data-pagination-container');
      var targetContainer = $('[data-pagination-items="' + containerName + '"]');//  Контейнер, в котором хранятся элементы
      var url = $(this).attr('data-url');    //  URL, из которого будем брать элементы
      if (url !== undefined && !App.isPageShowMoreRequested) {
          App.isPageShowMoreRequested = true;
          $.ajax({
              type: 'GET',
              url: url,
              dataType: 'html',
              success: function (data) {
                  App.isPageShowMoreRequested = false;
                  var elements = $(data).find('[data-pagination-items="' + containerName + '"]').find('[data-pagination-item]');  //  Ищем элементы
                  var paginationBlock = $(data).find('.b-pagination').html();//  Ищем навигацию
                  targetContainer.append(elements);   //  Добавляем посты в конец контейнера
                  $('.b-pagination').html(paginationBlock); // Добавляем постраничку
                  App.lazy.update();
                  App.tooltip.run();
                  App.loadPrices.run();

                  const items = document.querySelectorAll('.compare-add-app')

                    items.forEach((el) => {
                    new Vue({
                        el,
                        components: {
                        compareAdd,
                        },
                        store,
                    })
                    })
              }
          });
      }

  });
});

