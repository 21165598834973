import {rootDomain} from "./config/config.axios.local";
import vodohodClient from './rest/vodohod-client'


let cookie = {
    cookies: [
        {
            id: 'cookie-alert',
            cookie: 'cookie_notify',
            value: 'showed',
            isBottom: true,
        },
        {
            id: 'info-alert',
            cookie: 'info_notify',
            value: 'showed',
            isBottom: false,
        },
		{
			id: 'info-alert-uniq',
			cookie: 'info_notify_uniq',
			value: 'showed',
			isBottom: false,
		},
        {
            id: 'info-alert-lk',
            cookie: 'info_notify_lk',
            value: 'showed',
            isBottom: false,
        },
        {
            id: 'old-design',
            cookie: 'old-design',
            value: 'showed',
            isBottom: false,
        },
    ],
    run() {
        this.cookies.forEach(item => this.init(item));
        window.setCookie = this.setCookie.bind(this);
        window.getCookie = this.getCookie.bind(this);
        window.deleteCookie = this.deleteCookie.bind(this);
    },

    /* всплывающие плашки верх / низ */
    init(item) {
        const cookieNotify = this.getCookie(item.cookie);
        const $item = $(`#${item.id}`);
        const $close = $item.find('.cookie-alert__close');
        if(!cookieNotify || cookieNotify !== item.value) {
            setTimeout(() => $item.removeClass("hidden"), 1000);
        }
        $close.click(() => {
            $item.addClass( item.isBottom ? "fadeOutDown" : "fadeOutUp" );
            setTimeout(() => $item.addClass("hidden"), 1000);
            this.setCookie(item.cookie, item.value, {'expires':10800, 'path':'/'});
        });
    },

    // устанавливает cookie с именем name и значением value
    // options - объект с свойствами cookie (expires, path, domain, secure)
    setCookie(name, value, options = {path: '/'}) {
        if (!name) {
            return;
        }
        options = options || {};
        options.domain = rootDomain
        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        if (value instanceof Object) {
            value = JSON.stringify(value);
        }
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        document.cookie = updatedCookie;
    },

    // возвращает cookie с именем name, если есть, если нет, то undefined
    getCookie(name, json = false) {
        if (!name) {
            return undefined;
        }
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
        ));
        if (matches) {
            let res = decodeURIComponent(matches[1]);
            if (json) {
                try {
                    return JSON.parse(res);
                } catch (e) {
                }
            }
            return res;
        }
        return undefined;
    },
    deleteCookie(name) {
        this.setCookie(name, null, {
            expires: new Date(),
            path: '/'
        })
    },
    async getUserToken() {
        let token = this.getCookie('user_token') || this.getCookie('website_token')
        if (!token) {
            const res = await vodohodClient.getToken()
            token = res.data.accessToken
            this.setCookie('website_token', token)
            this.setCookie('website_refresh_token', res.data.refreshToken)
        }
        return token
    }
};

module.exports = cookie;



