const Banner = {
  run() {
    if ($("[data-dir-banner]").length) {
      this.$banner = $("[data-dir-banner]");
      this.$img = this.$banner.find(".dir__image");
      this.$items = $(".dir-banner__gallery .dir-banner__item-link");
      const self = this;

      if (this.$items.length) {
        this.$items.on("click", function(evt) {
          evt.preventDefault();
          self.changeBanner({
            path: $(this).attr("data-path"),
          });
        });
      }
    }
  },
  changeBanner(props) {
    this.$img.css("background-image", "url(" + props.path + ")");
  },
};

module.exports = Banner;
