require('../../icons/icon-3d-model.svg')
require('../../icons/icon-3d-tour-eng.svg')
require('../../icons/icon-3d-tour.svg')
require('../../icons/icon-3d-tour-desktop.svg')
require('../../icons/icon-arrow-bottom-grey.svg')
require('../../icons/icon-arrow-bottom-white.svg')
require('../../icons/icon-arrow-r.svg')
require('../../icons/icon-arrow-red-down.svg')
require('../../icons/icon-arrow-red.svg')
require('../../icons/icon-arrow-right-white.svg')
require('../../icons/icon-arrow-select.svg')
require('../../icons/icon-arrow-small.svg')
require('../../icons/icon-arrow-sort.svg')
require('../../icons/icon-arrow-sp.svg')
require('../../icons/icon-arrow-sp.svg')
require('../../icons/icon-arrow-styler.svg')
require('../../icons/icon-arrow.svg')
require('../../icons/icon-b-double.svg')
require('../../icons/icon-b-single-single.svg')
require('../../icons/icon-banner-play.svg')
require('../../icons/icon-bDay.svg')
require('../../icons/icon-bell.svg')
require('../../icons/icon-c-f-toggle.svg')
require('../../icons/icon-calendar-grey.svg')
require('../../icons/icon-calendar.svg')
require('../../icons/icon-checkmark-red.svg')
require('../../icons/icon-compare-add-page.svg')
require('../../icons/icon-compare-add.svg')
require('../../icons/icon-compare-arrow.svg')
require('../../icons/icon-compare-calendar.svg')
require('../../icons/icon-compare-cart.svg')
require('../../icons/icon-compare-carts-alt.svg')
require('../../icons/icon-compare-carts.svg')
require('../../icons/icon-compare-clock.svg')
require('../../icons/icon-compare-controll.svg')
require('../../icons/icon-compare-place.svg')
require('../../icons/icon-compare-remove.svg')
require('../../icons/icon-compare-ship.svg')
require('../../icons/icon-copy-light.svg')
require('../../icons/icon-copy-solid.svg')
require('../../icons/icon-copy.svg')
require('../../icons/icon-cross-simple.svg')
// require('../../icons/icon-door.svg') // Перепроверить svg
require('../../icons/icon-double-bed.svg')
require('../../icons/icon-download.svg')
require('../../icons/icon-downloads.svg')
require('../../icons/icon-edit.svg')
require('../../icons/icon-ex-bus.svg')
require('../../icons/icon-ex-photo.svg')
require('../../icons/icon-ex-variant.svg')
require('../../icons/icon-ex-warning.svg')
require('../../icons/icon-filter-lines.svg')
require('../../icons/icon-filter.svg')
require('../../icons/icon-filter-grey.svg')
require('../../icons/icon-icon.svg')
require('../../icons/icon-image-styler.svg')
require('../../icons/icon-info.svg')
require('../../icons/icon-inst.svg')
require('../../icons/icon-link_arrow.svg')
require('../../icons/icon-list-new.svg')
require('../../icons/icon-lk-close.svg')
require('../../icons/icon-lk-login.svg')
require('../../icons/icon-load.svg')
require('../../icons/icon-m-c-arrow.svg')
require('../../icons/icon-mail-light.svg')
require('../../icons/icon-print.svg')
require('../../icons/icon-mail-r.svg')
require('../../icons/icon-mail.svg')
require('../../icons/icon-mails.svg')
require('../../icons/icon-map_marker.svg')
require('../../icons/icon-map-light.svg')
require('../../icons/icon-map-r.svg')
require('../../icons/icon-map.svg')
require('../../icons/icon-marker.svg')
require('../../icons/icon-mastercard-logo.svg')
require('../../icons/icon-mastercard.svg')
require('../../icons/icon-menu.svg')
require('../../icons/icon-mir-logo.svg')
require('../../icons/icon-mir.svg')
require('../../icons/icon-next.svg')
require('../../icons/icon-no-avatar-2.svg')
require('../../icons/icon-no-avatar.svg')
require('../../icons/icon-ok.svg')
require('../../icons/icon-overview.svg')
require('../../icons/icon-pagination.svg')
require('../../icons/icon-pass-eye.svg')
require('../../icons/icon-phone-blue.svg')
require('../../icons/icon-phone-r.svg')
require('../../icons/icon-phone.svg')
require('../../icons/icon-photo.svg')
require('../../icons/icon-play.svg')
require('../../icons/icon-play-video-white.svg')
require('../../icons/icon-price-block-alert.svg')
require('../../icons/icon-price-table-link.svg')
require('../../icons/icon-price-table-sheme.svg')
require('../../icons/icon-print-blue.svg')
require('../../icons/icon-print-light.svg')
require('../../icons/icon-print.svg')
require('../../icons/icon-printer.svg')
require('../../icons/icon-question.svg')
require('../../icons/icon-red-alert.svg')
require('../../icons/icon-refresh.svg')
// require('../../icons/icon-remix.svg') // Перепроверить svg
require('../../icons/icon-remove.svg')
require('../../icons/icon-rutube.svg')
require('../../icons/icon-sale-arrow.svg')
require('../../icons/icon-scroll-top.svg')
require('../../icons/icon-search.svg')
require('../../icons/icon-send-light.svg')
require('../../icons/icon-share.svg')
require('../../icons/icon-ship-new.svg')
require('../../icons/icon-ship-offer.svg')
require('../../icons/icon-ship.svg')
require('../../icons/icon-simple-menu-arrow.svg')
require('../../icons/icon-single-bed.svg')
require('../../icons/icon-stars.svg')
require('../../icons/icon-stat.svg')
require('../../icons/icon-table-new.svg')
require('../../icons/icon-tabs-filter.svg')
require('../../icons/icon-telegram.svg')
require('../../icons/icon-time.svg')
require('../../icons/icon-timer.svg')
require('../../icons/icon-toggle.svg')
require('../../icons/icon-tour-360.svg')
require('../../icons/icon-trash.svg')
require('../../icons/icon-uploads.svg')
require('../../icons/icon-video.svg')
require('../../icons/icon-video-ship.svg')
require('../../icons/icon-more-red.svg')
require('../../icons/icon-visa-logo.svg')
require('../../icons/icon-visa.svg')
require('../../icons/icon-vk.svg')
require('../../icons/icon-youtube.svg')
require('../../icons/pdf-icon-file.svg')
require('../../icons/icon-print-new.svg')
require('../../icons/icon-share-new.svg')
require('../../icons/icon-photogallery-new.svg')
require('../../icons/icon-gallery-ship.svg')
require('../../icons/icon-cruise-day-gallery.svg')
require('../../icons/icon-cruise-day-bus.svg')
require('../../icons/icon-cruise-day-zoom.svg')
require('../../icons/icon-cruise-day-time.svg')
require('../../icons/icon-cruise-day-map.svg')
require('../../icons/icon-cruise-day-route-download.svg')
require('../../icons/icon-class-gallery-zoom.svg')
require('../../icons/icon-class-gallery-play.svg')
require('../../icons/icon-ship-class-pdf.svg')
require('../../icons/icon-ship-class-renova.svg')
