<template>
	<div class="compare-cart">
		<div class="compare-cart__sidebar">
			<div
				class="b-modal-side b-modal-side__active"
				:class="[{'d-prices-basket-menu__active': opened && items}, {loading: loading}]"
			>
				<div class="b-modal-side__inner">
					<div class="b-modal-side-header">
						<div class="b-modal-side-top">
							<a :href="`/cruises/compare/${url ? '?' + url : ''}`" class="b-modal-side__title">{{
								$t('sb.title')
							}}</a>
							<button class="b-modal-side__close" @click="close">
								<div class="plus plus--cross"></div>
							</button>
						</div>
						<a :href="`/cruises/compare/${url ? '?' + url : ''}`" class="compare-cart__cityname">
							<img alt="" src="/local/client/icons/icon-compare-carts.svg?inline" />
							<span>{{ $t('sb.btn-to-compare') }}</span>
						</a>
					</div>
					<div class="b-modal-side__body">
						<div class="compare-cart__list">
							<div v-for="(item, index) in items" :key="index" class="compare-cart__item">
								<div class="compare-cart__header">
									<div>
										<a
											:href="item.DETAIL_PAGE_URL"
											class="compare-cart__cityname"
											v-html="item.NAME"
										>
										</a>
									</div>
									<a class="compare-cart__remove" @click="removeCompare(item.ID)">
										{{ $t('btn-delete') }}
									</a>
								</div>
								<div class="compare-cart__inner">
									<div class="compare-cart__ship">
										<div class="compare-cart__ship-icon">
											<img alt="" src="/local/client/icons/icon-compare-ship.svg?inline" />
										</div>
										<a
											:href="item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.DETAIL_PAGE_URL"
											class="compare-cart__ship-text link"
										>
											{{ item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.NAME }}
										</a>
									</div>
									<p
										class="compare-cart__badge"
										:class="[
											'compare-cart__badge--' +
												item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.CLASS_CSS,
											'p__tag--' + item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.CLASS_CSS,
										]"
									>
										{{ item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.CLASS_NAME }}
									</p>
								</div>
								<div class="compare-cart__date">
									<div class="compare-cart__date-icon">
										<img alt="" src="/local/client/icons/icon-compare-calendar.svg?inline" />
									</div>
									<div class="compare-cart__date-text">
										{{ item.DISPLAY_PROPERTIES.DATE_START.VALUE }}
									</div>
								</div>
								<div class="compare-cart__sum" v-html="item.DISPLAY_PROPERTIES.MIN_PRICE.VALUE"></div>
								<div class="compare-cart__total">
									<div class="compare-cart__buy">
										<a
											v-if="item['DISPLAY_PROPERTIES']['ROOMS_AVAILABLE_COUNT']['~VALUE'] > 0"
											class="btn btn--red btn--fill"
											:href="item.booking_link"
										>
											{{ $t('reserv') }}
										</a>
										<template v-else>
											<a
												href="javascript:void(0);"
												class="btn--red btn-has-bell-waiting js-btn-open-waiting-popup price-block-room__waiting btn-has-bell-waiting_compare"
												data-mfp-src="#waiting-popup"
												:data-cruise-id="item.XML_ID"
											>
												<span>
													<svg
														class=""
														width="17"
														height="20"
														viewBox="0 0 17 20"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13 15H4V8.5C4 6 6 4 8.5 4C11 4 13 6 13 8.5V15ZM15 14V8.5C15 5.43 12.86 2.86 10 2.18V1.5C10 1.10218 9.84196 0.720645 9.56066 0.43934C9.27936 0.158035 8.89782 0 8.5 0C8.10218 0 7.72064 0.158035 7.43934 0.43934C7.15804 0.720645 7 1.10218 7 1.5V2.18C4.13 2.86 2 5.43 2 8.5V14L0 16V17H17V16L15 14ZM8.5 20C9.03043 20 9.53914 19.7893 9.91421 19.4142C10.2893 19.0391 10.5 18.5304 10.5 18H6.5C6.5 18.5304 6.71071 19.0391 7.08579 19.4142C7.46086 19.7893 7.96957 20 8.5 20Z"
														/>
													</svg>
												</span>
											</a>
										</template>
										<a href="/cruises/compare/" class="compare-cart__compare">
											<img alt="" src="/local/client/icons/icon-compare-carts.svg?inline" />
											<span>{{ $t('compare') }}</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex'
import ClickOutside from 'vue-click-outside'
export default {
	directives: {
		ClickOutside,
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		items() {
			return this.$store.state.items
		},
		opened() {
			return this.$store.state.status
		},
		url() {
			return this.$store.state.url
		},
	},
	methods: {
		...mapActions(['removeFromCompare', 'TOGGLE_SIDEBAR']),
		removeCompare(id) {
			this.loading = true
			this.removeFromCompare(id).then(() => {
				this.loading = false
			})
		},
		close() {
			this.TOGGLE_SIDEBAR(false)
		},
	},
}
</script>
<style scoped>
.btn-has-bell-waiting_compare {
	margin: 0;
	max-width: 133px;
}

@media (max-width: 1023px) {
	.btn-has-bell-waiting_compare {
		min-width: 133px;
	}
}
</style>
