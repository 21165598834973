import ajaxLoad from "./ajax-load";
import lazy from './lazy';
import modals from "./modals";
import sliders from "./slider/sliders"

let reviewsFilter = {
    run() {
        $(document).on('change', '[data-reviews-filter] [data-f-field]', this.updateFilter);

        $(document).on('click', '[data-review-filter-mobile-show]', e => {
            e.preventDefault();
            this.hideFilter();
        });

        $(document).on('click', '[data-review-filter-mobile-reset]', e => {
            e.preventDefault();
            $('[data-reviews-filter]')[0].reset();
            $('select').selectric('refresh');
            this.updateFilter();
        });
    },

    updateFilter() {
        ajaxLoad.load({
            container: '[data-reviews-items]',
            url: window.location.origin + window.location.pathname + '?' + $('[data-reviews-filter]').serialize(),
            success: function () {
				sliders.reviewsEx();
                lazy.update();
                modals.run();
            }
        }, true);
    },

    hideFilter() {
        $('html').toggleClass('locked');
        $('[data-filter]').toggleClass('active');
    }
};

module.exports = reviewsFilter;
