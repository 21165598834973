<template>
	<div class="app-flex">
		<div class="app-disc__container" v-if="discounts.length">
			<h2 class="app-heading">{{ $t('discounts_ship') }}</h2>
			<div class="app-disc">
				<div v-for="(item, index) in discounts"
					 :key="index" class="app-disc__item"
					 :class="{ 'app-disc__item--booking' : item.name === 'Скидка ID/AD'}"
					 @click.stop="openModalDiscount(item)">
					<div class="app-disc__item-image"></div>
					<div class="app-disc__item-content">
						{{ item.name }}
						{{ item.percent }}%
					</div>
				</div>
			</div>
			<a href="/special-offers/discount/" class="app-disc__conditions">{{ $t('discounts_ship_more') }}</a>
		</div>
		<div class="app-spec__container" v-if="specials.length">
			<h2 class="app-heading">{{ $t('specials_ship') }}</h2>
			<div class="app-spec">
				<div v-for="(item, index) in specials" :key="index" class="app-spec__item"
					 @click.stop="openModal(item)">
					<div class="app-spec__item-image"></div>
					<div class="app-spec__item-content">
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>

		<div v-if="openedDiscountModal" class="disc-modal__wrapper">
			<div class="disc-modal__modal" v-click-outside="closeModalDiscount">
				<div class="disc-modal__modal-header">
					<div class="disc-modal_modal-info">
					</div>
					<button class="disc-modal__modal-close" @click="closeModalDiscount">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_437_16670)">
								<path d="M6.26264 8L0 1.7775L1.78896 0L9.84056 8L1.78896 16L0 14.2225L6.26264 8Z"
									  fill="#9A243D" />
								<path d="M9.73736 8L16 1.7775L14.211 0L6.15944 8L14.211 16L16 14.2225L9.73736 8Z"
									  fill="#9A243D" />
							</g>
							<defs>
								<clipPath id="clip0_437_16670">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</button>
				</div>
				<div class="disc-modal__modal-body">
					<div class="disc-modal__modal-body-title">
						{{ selectedDiscountItem.name }}
					</div>
					<div class="disc-modal__modal-body-percent">
						до <span class="disc-modal__modal-body-percent-value">
							{{ selectedDiscountItem.percent }} %
							</span>
					</div>
					<div class="disc-modal__modal-body-desc">
						<div class="disc-modal__modal-body-annotation">
							{{ selectedDiscountItem.annotation }}
						</div>
						<div class="disc-modal__modal-body-condition">
							Данная скидка не предоставляется вместе с другими видами скидок.
						</div>
						<div class="disc-modal__modal-body-person">
							Предоставляется частным лицам и агентствам
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="opened" class="spec-modal__wrapper">
			<div class="spec-modal__modal" v-click-outside="closeModal">
				<div class="spec-modal__modal-header">
					<div class="spec-modal_modal-info">
					</div>
					<button class="spec-modal__modal-close" @click="closeModal">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_437_16670)">
								<path d="M6.26264 8L0 1.7775L1.78896 0L9.84056 8L1.78896 16L0 14.2225L6.26264 8Z"
									  fill="#9A243D" />
								<path d="M9.73736 8L16 1.7775L14.211 0L6.15944 8L14.211 16L16 14.2225L9.73736 8Z"
									  fill="#9A243D" />
							</g>
							<defs>
								<clipPath id="clip0_437_16670">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</button>
				</div>
				<div class="spec-modal__modal-body">
					<div v-html="dataModal"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import ClickOutside from 'vue-click-outside';
import crsCruise from "../../rest/crs/crsCruise";

export default {
	props: {
		cruiseId: Number
	},
	directives: {
		ClickOutside
	},
	data() {
		return {
			opened: false,
			openedDiscountModal: false,
			specials: [],
			discounts: [],
			dataModal: '',
			selectedDiscountItem: {}
		};
	},
	mounted() {
		crsCruise.bookingDiscounts(this.cruiseId)
		.then(res => this.discounts = res.data.data);
		const specialOffers = $('#special_offers');
		if (specialOffers.length) {
			this.specials = JSON.parse(specialOffers.val());
		}

	},
	methods: {
		async openModal(offer) {
			const res = await axios.get(`/local/include/modals/special_offers_vue.php?id=${offer.id}`);
			this.dataModal = res.data;
			this.opened = true;
			this.openedDiscountModal = true;
		},
		closeModal() {
			this.opened = false;
			this.dataModal = null;
		},
		openModalDiscount(item) {
			this.selectedDiscountItem = item;
			this.openedDiscountModal = true;
		},
		closeModalDiscount() {
			this.selectedDiscountItem = {};
			this.openedDiscountModal = false;
		}
	},
	watch: {
		opened(nv) {
			const html = document.querySelector('html');
			if (nv) {
				const body = document.querySelector('body');
				body.style.paddingRight = `${window.innerWidth - document.querySelector('main').offsetWidth}px`;
				html.style.overflow = 'hidden';
			} else {
				html.style.overflow = 'auto';
				const body = document.querySelector('body');
				body.style.paddingRight = 0;
			}
		},
		openedDiscountModal(nv) {
			const html = document.querySelector('html');
			if (nv) {
				const body = document.querySelector('body');
				body.style.paddingRight = `${window.innerWidth - document.querySelector('main').offsetWidth}px`;
				html.style.overflow = 'hidden';
			} else {
				html.style.overflow = 'auto';
				const body = document.querySelector('body');
				body.style.paddingRight = 0;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.app-disc {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;

	&__item {
		cursor: pointer;
		min-height: 80px;
		display: flex;
		background-color: #C8B49A;
		border: 1px solid #C8B49A;
		align-items: center;
		width: 100%;

		&--booking {
			display: none;
		}

		&:hover {
			border: 1px solid #c1ab8f;
			background-color: #c1ab8f;
		}

		&:active {
			transform: translateY(1px);
		}

		@media (min-width: 768px) {
			width: calc(100% / 2 - 20px / 2);
		}

		@media (min-width: 1024px) {
			width: calc(100% / 3 - 20px * 2 / 3);
		}

		@media (min-width: 1440px) {
			width: calc(100% / 4 - 20px * 3 / 4);
		}

		&-image {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4SDE4LjAyTTMwIDMwSDMwLjAyTTMyIDE2TDE2IDMyTTQ0IDI0QzQ0IDM1LjA0NTcgMzUuMDQ1NyA0NCAyNCA0NEMxMi45NTQzIDQ0IDQgMzUuMDQ1NyA0IDI0QzQgMTIuOTU0MyAxMi45NTQzIDQgMjQgNEMzNS4wNDU3IDQgNDQgMTIuOTU0MyA0NCAyNFpNMTkgMThDMTkgMTguNTUyMyAxOC41NTIzIDE5IDE4IDE5QzE3LjQ0NzcgMTkgMTcgMTguNTUyMyAxNyAxOEMxNyAxNy40NDc3IDE3LjQ0NzcgMTcgMTggMTdDMTguNTUyMyAxNyAxOSAxNy40NDc3IDE5IDE4Wk0zMSAzMEMzMSAzMC41NTIzIDMwLjU1MjMgMzEgMzAgMzFDMjkuNDQ3NyAzMSAyOSAzMC41NTIzIDI5IDMwQzI5IDI5LjQ0NzcgMjkuNDQ3NyAyOSAzMCAyOUMzMC41NTIzIDI5IDMxIDI5LjQ0NzcgMzEgMzBaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
			background-position: center;
			background-size: 48px;
			background-repeat: no-repeat;
			width: 80px;
			flex-shrink: 0;
			height: 100%;
		}

		&-content {
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			color: #171717;
			padding: 16px;
			background-color: #FFF;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
		}
	}

	&__conditions {
		margin-top: 20px;
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #9A243D;
		gap: 20px;

		&:after {
			content: "";
			display: block;
			transition: 0.2s all ease;
			width: 18px;
			height: 18px;
			background-size: contain;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMzRfMTU4MTYpIj4KPHBhdGggZD0iTTkuODc5NDkgOS4wMDAwNUw2LjE2Njk5IDUuMjg3NTVMNy4yMjc0OSA0LjIyNzA1TDEyLjAwMDUgOS4wMDAwNUw3LjIyNzQ5IDEzLjc3MzFMNi4xNjY5OSAxMi43MTI2TDkuODc5NDkgOS4wMDAwNVoiIGZpbGw9IiM5QTI0M0QiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xMDM0XzE1ODE2Ij4KPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=);
		}

		&:hover:after {
			margin-left: 4px;
		}
	}
}

.app-spec {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;

	&__item {
		min-height: 80px;
		display: flex;
		border: 1px solid #9a243d;
		background-color: #9a243d;
		align-items: center;
		width: 100%;
		cursor: pointer;

		&:hover {
			background-color: rgba(195, 26, 60, 0.9);
			border-color: rgba(195, 26, 60, 0.9);
		}

		&:active {
			transform: translateY(1px);
		}

		@media (min-width: 768px) {
			width: calc(100% / 2 - 20px / 2);
		}

		@media (min-width: 1024px) {
			width: calc(100% / 3 - 20px * 2 / 3);
		}

		@media (min-width: 1440px) {
			width: calc(100% / 4 - 20px * 3 / 4);
		}

		&-image {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDE2SDE2LjAyTTQgMTAuNEw0IDE5LjM0OUM0IDIwLjMyNzQgNCAyMC44MTY2IDQuMTEwNTIgMjEuMjc2OUM0LjIwODUxIDIxLjY4NTEgNC4zNzAxMiAyMi4wNzUyIDQuNTg5NDQgMjIuNDMzMUM0LjgzNjgxIDIyLjgzNjggNS4xODI3MSAyMy4xODI3IDUuODc0NTEgMjMuODc0NUwyMS4yMTE4IDM5LjIxMThDMjMuNTg3OSA0MS41ODc5IDI0Ljc3NTkgNDIuNzc1OSAyNi4xNDU5IDQzLjIyMTFDMjcuMzUxIDQzLjYxMjYgMjguNjQ5IDQzLjYxMjYgMjkuODU0MSA0My4yMjExQzMxLjIyNDEgNDIuNzc1OSAzMi40MTIxIDQxLjU4NzkgMzQuNzg4MiAzOS4yMTE4TDM5LjIxMTggMzQuNzg4MkM0MS41ODc5IDMyLjQxMjEgNDIuNzc1OSAzMS4yMjQxIDQzLjIyMTEgMjkuODU0MUM0My42MTI2IDI4LjY0OSA0My42MTI2IDI3LjM1MSA0My4yMjExIDI2LjE0NTlDNDIuNzc1OSAyNC43NzU5IDQxLjU4NzkgMjMuNTg3OSAzOS4yMTE4IDIxLjIxMThMMjMuODc0NSA1Ljg3NDUyQzIzLjE4MjcgNS4xODI3MSAyMi44MzY4IDQuODM2ODEgMjIuNDMzMSA0LjU4OTQ0QzIyLjA3NTIgNC4zNzAxMiAyMS42ODUxIDQuMjA4NTEgMjEuMjc2OSA0LjExMDUyQzIwLjgxNjYgNCAyMC4zMjc0IDQgMTkuMzQ5IDRMMTAuNCA0QzguMTU5NzkgNCA3LjAzOTY5IDQgNi4xODQwNCA0LjQzNTk3QzUuNDMxMzkgNC44MTk0NyA0LjgxOTQ3IDUuNDMxMzkgNC40MzU5OCA2LjE4NDA0QzQgNy4wMzk2OSA0IDguMTU5NzkgNCAxMC40Wk0xNyAxNkMxNyAxNi41NTIzIDE2LjU1MjMgMTcgMTYgMTdDMTUuNDQ3NyAxNyAxNSAxNi41NTIzIDE1IDE2QzE1IDE1LjQ0NzcgMTUuNDQ3NyAxNSAxNiAxNUMxNi41NTIzIDE1IDE3IDE1LjQ0NzcgMTcgMTZaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
			background-position: center;
			background-size: 48px;
			background-repeat: no-repeat;
			width: 100px;
			flex-shrink: 0;
			height: 100%;
		}

		&-content {
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			color: #FFFFFF;
			padding: 16px;
		}
	}
}

.app-heading {
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	margin-bottom: 32px;

	@media (min-width: 769px) {
		margin-bottom: 48px;
	}
}

.app-flex {
	display: flex;
	flex-direction: column;
	gap: 54px;
}

.spec-modal {
	&__wrapper {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 106;
	}

	&__modal {
		width: 320px;
		background-color: #ffffff;
		max-height: calc(100vh - 60px);
		overflow-y: auto;
		padding: 24px;

		@media (min-width: 540px) {
			width: calc(100% - 96px);
			max-width: 800px;
		}

		&-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		&-body {
			font-weight: 400;
			font-size: 14px;
			line-height: 120%;

			h1, .title-1 {
				font-weight: 500;
				font-size: 20px;
				line-height: 130%;
				margin-bottom: 16px;
			}
		}

		&-close {
			width: 16px;
			height: 16px;
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: center;
			position: relative;

			svg {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

	}
}

.disc-modal {
	&__wrapper {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 106;
	}

	&__modal {
		width: 320px;
		background-color: #ffffff;
		max-height: calc(100vh - 60px);
		overflow-y: auto;
		padding: 24px;

		@media (min-width: 540px) {
			width: 400px;
		}

		&-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		&-body {
			font-weight: 400;
			font-size: 14px;
			line-height: 120%;

			h1, .title-1 {
				font-weight: 500;
				font-size: 20px;
				line-height: 130%;
				margin-bottom: 16px;
			}

			&-title {
				padding: 16px 20px;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
			}

			&-description {
				padding: 20px 20px 48px 20px;
			}

			&-annotation {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 120%;
			}

			&-condition {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 120%;
				margin-top: 12px;
			}

			&-percent {
				background: #C8B49A;
				color: #FFF;
				font-weight: 700;
				font-size: 16px;
				line-height: 130%;
				padding: 24px 0;
				width: 100%;
				text-align: center;
				width: calc(100% + 48px);
				margin-left: -24px;

				&-value {
					font-size: 22px;
				}
			}


			&-person {
				background: #C8B49A;
				padding: 5px 16px;
				font-weight: 500;
				font-size: 11px;
				line-height: 16px;
				text-transform: uppercase;
				text-align: center;
				color: #FFFFFF;
				margin-top: 20px;
				margin-bottom: 24px;

				@media (min-width: 540px) {
					width: max-content;
					margin: 20px auto 24px;
					max-width: 240px;
				}
			}
		}

		&-close {
			width: 16px;
			height: 16px;
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: center;
			position: relative;

			svg {
				width: 16px;
				height: 16px;
				position: absolute;
				left: 0;
				top: 0;
			}
		}

	}
}

</style>
