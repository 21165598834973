import Swiper from 'swiper/dist/js/swiper.min.js';

let mainSlider = {
  run() {
    this.swiperInit();
  },

  swiperInit() {
    const $sliderElem = document.querySelector('[data-slider="main"]');
    if (!$sliderElem) return;

    const $titles = $('.m-slider__title span, .m-slider__subtitle');
    const $paginations = $('.slider__paginations');

    let $interval;
    let $thisSlider = this;
    let $delayOptions = 5000;

    if (!!$sliderElem)
      if ($sliderElem.hasAttribute('data-autoplay'))
        $delayOptions = +$sliderElem.getAttribute('data-autoplay') || 5000;

    this.$images = $('[data-img]');
    this.spriteImagesSrc = [];

    this.$images.each((i, item) => {
      this.spriteImagesSrc.push($(item).data('img'));
    });

    this.swiper = new Swiper('[data-slider="main"]', {
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      lazy: {
        loadPrevNext: true,
        loadOnTransitionStart: true
      },
      speed: 400,
      pagination: {
        el: `[data-slider-p='main']`,
        type: 'bullets',
        bulletClass: 'slider__pagination',
        bulletActiveClass: 'active',
        renderBullet: (index, className) => {
          return `<span class="${className}" data-pagination-item="${index}"><svg class="i-icon"><use xlink:href="#icon-pagination"/></svg></span>`;
        },
      },
      preventInteractionOnTransition: true,
      resistanceRatio: 0,
      simulateTouch: false,
      touchRatio: 0,
      autoplay: {
        delay: $delayOptions
      },
      on: {
        init: () => {
          $('.loader').fadeOut();
          $('.m-slider__inner').addClass('init');
          $paginations.addClass('init');
        },
        autoplayStop: () => {
          this.swiper.autoplay.start();
        },
        paginationUpdate: () => {
          animateActions()
        },
        slideChange: () => {
          animateActions();
        }
      },
      breakpoints: {
        1023: {
          simulateTouch: true,
          resistanceRatio: 1,
          touchRatio: 1,
          loop: true
        }
      }
    });

    $(document).on('click', '.m-slider .slider__pagination:not(.active)', function () {
      const index = this.getAttribute('data-pagination-item');
      $paginations.addClass('disabled');
      $titles.removeClass('active');
      animationDelay('reverse');

      setTimeout(() => {
        $thisSlider.swiper.slideTo(+index, 500);
        $thisSlider.swiper.pagination.update()
      }, 1300)

    });

    function animationDelay(className) {
      setTimeout(() => $titles.addClass(className), 50);
    }

    function animateActions() {
      clearTimeout($interval);
      $paginations.removeClass('disabled');
      $titles.removeClass('reverse');
      animationDelay('active');

      $interval = setTimeout(() => {
        $paginations.addClass('disabled');
        $titles.removeClass('active');
        animationDelay('reverse');
      }, $delayOptions - 1000);
    }

    function animate({ timing, draw, duration }) {
      let start = performance.now();
      requestAnimationFrame(function animate(time) {
        // timeFraction изменяется от 0 до 1
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;

        // вычисление текущего состояния анимации
        let progress = timing(timeFraction);

        draw(progress); // отрисовать её

        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        }

      });
    }
  }
};
module.exports = mainSlider;



