import './_vendor/clamp.min';

const clampText = {
    run() {
        this.$texts = $('[data-clamp-text]');

        if(this.$texts.length) {
            this.$texts.each((index, text) => {
                $clamp(text, {
                    clamp: $(text).attr('data-clamp-text') ? $(text).attr('data-clamp-text') : 2,
                });
                if($(text).attr('data-clamp-text-display-clear') !== undefined) $(text).css('display', '');
            });
        }
    }
};

module.exports = clampText;