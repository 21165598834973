const SearchIndividual = {
    searchTypes() {
        const searchInput = $('#lk-suggestion-input')
        return [
            {
                label: 'ФИО',
                value: 'fullName',
                params: (request) => {
                    return {
                        method: 'POST',
                        url: `/users/check-name`,
                        data: {name: request}
                    }
                },
                processResponse: (response) => {
                    response.data.forEach(data => {
                        data.label = data.fio
                        data.content = `<span class="lk-dropdown__title lk-dropdown__title--individual">${data.fio}</span>
                                        ${data.birthDate}, ${data.email ? data.email : "no email"}, CRM id ${data.crmID}`
                    })
                    return response
                },
                set: () => {
                    searchInput.attr('placeholder', 'Иванов Иван Иванович')
                }
            },
            {
                label: 'E-mail',
                value: 'email',
                params: (request) => {
                    return {
                        method: 'POST',
                        url: `/users/check-email`,
                        data: {email: request}
                    }
                },
                processResponse: (response) => {
                    let data = response.data
                    data.label = data.email
                    data.content = `${data.lastName} ${data.firstName} ${data.patronymicName}, ${data.birthDate}, <span class="lk-dropdown__title lk-dropdown__title--individual">${data.email}</span>, CRM id ${data.crmID}`
                    response.data = [data]
                    return response
                },
                validate: (val) => {
                    return window.App.validation.validEmailTest(val)
                }
            },
            {
                label: 'CRM id',
                value: 'crmId',
                params: (request) => {
                    return {
                        method: 'POST',
                        url: `/users/list-by-crm-id`,
                        params: {crmId: request}
                    }
                },
                validate: (val) => {
                    return Number.isInteger(Number(val))
                },
                processResponse: (response) => {
                    response.data = response.data.websiteUsers
                    response.data.forEach(data => {
                        data.label = data.crmID
                        data.content = `${data.lastName} ${data.firstName} ${data.patronymicName}, ${data.birthDate}, ${data.email}, <span class="lk-dropdown__title lk-dropdown__title--individual">CRM id ${data.crmID}</span>`
                    })
                    return response;
                }
            },
            {
                label: 'Телефон',
                value: 'phone',
                params: (request) => {
                    return {
                        method: 'POST',
                        url: `/users/check-phone`,
                        data: {phone: request}
                    }
                },
                set: () => {
                    $('#lk-suggestion-input').attr('data-mask', 'phone')
                },
                validate: (val) => {
                    return Number.isInteger(Number(val))
                },
                processResponse: (response) => {
                    let data = response.data
                    data.label = data.phone
                    data.content = `${data.lastName} ${data.firstName} ${data.patronymicName}, ${data.birthDate}, <span class="lk-dropdown__title lk-dropdown__title--individual">${data.phone}</span>, CRM id ${data.crmID}`
                    response.data = [data]
                    return response
                },
            }
        ]
    },
    selectItem(id) {
        $('[name="form_orders_filter"]').append(`<input type="hidden" value="${id}"  name="filter[owner]" id="lk-suggestion__dropdown-selected"/>`)
    },
    processResponse(response) {
        if (response.result === 'OK') {
            const lkSuggestionDropdown = $('.lk-suggestion__dropdown')
            lkSuggestionDropdown.empty()
            response.data.filter(user => user.type === undefined || Number(user.type) === 3).forEach(data => {
                $('.lk-suggestion__dropdown').append(this.getHtml(data))
            })
            lkSuggestionDropdown.show()
        }
    },
    getHtml(data) {
        if (data.fio === undefined) {
            data.fio = `${data.lastName} ${data.firstName} ${data.patronymicName}`
        }
        return `<div class="user_item lk-dropdown__item" data-id="${data.id}" data-label="${data.label}">
                    ${data.content}
                </div>`
    }
}

module.exports = SearchIndividual