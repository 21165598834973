import cookie from "../js/cookie"
import reInitForms from "../js/reInitForms"
import locales from './lang/locales'

const cookieHandler = {
	cookies: [
		{
			name: 'authorization_expired',
			handle() {
				$.magnificPopup.close();
				$.magnificPopup.open({
					items: {
						src: '/local/include/modals/auth.php',
						type: 'ajax'
					},
					preloader: false,
					fixedBgPos: true,
					showCloseBtn: false,
					closeOnBgClick: false,
					removalDelay: 300,
					mainClass: 'mfp-fade',
					callbacks: {
						ajaxContentAdded: function () {
							$('#authorize_form_modal .errors_cont').append(locales.authorization_expired)
							reInitForms.run()
						},
					},
				})
				cookie.deleteCookie(this.name)
			}
		},
		{
			name: 'must_select_office',
			handle() {
				if(location.pathname !== '/account/manager/settings/') {
					$.magnificPopup.close();
					$.magnificPopup.open({
						items: {
							src: '/local/include/modals/select_office.php',
							type: 'ajax'
						},
						preloader: false,
						fixedBgPos: true,
						showCloseBtn: false,
						closeOnBgClick: false,
						removalDelay: 300,
						mainClass: 'mfp-fade'
					})
				}
			}
		}
	],
	run() {
		this.cookies.forEach(_cookie => {
			if(cookie.getCookie(_cookie.name)) {
				_cookie.handle()
			}
		})
	}
}

module.exports = cookieHandler
