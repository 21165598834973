import SearchIndividual from "./searchIndividual"
import AgentSearch from "./agentSearch"
import {CrsClient} from "../rest/crs-client"
import crsUser from "../rest/crs/crsUser";

const OwnerSearch = {
    inited: false,
    crsClient: new CrsClient(),
    init() {
        const searchInput = $('#lk-suggestion-input')
        this.searchInput = searchInput[0].outerHTML
        this.configs = JSON.parse($('[name="ownerSearchConfigs"]').val())
        this.setSearch()
        const _this = this
        const delay = (() => {
            let timer = 0
            return (callback, ms) => {
                clearTimeout(timer)
                timer = setTimeout(callback, ms)
            }
        })()
        $(document).on('click', '#lk-suggestion-input, #lk-suggestion__dropdown', (e) => e.stopPropagation())
        $(document).on('change', '#search_types', () => _this.setSearchType())
        $(document).on('change', '#lk-switch-list', () => _this.setSearch())
        $(document).on('click', '#lk-suggestion__dropdown .user_item', (e) => {
            const item = $(e.currentTarget),
                searchType = _this.getSearchType()
            $('#lk-suggestion__dropdown-selected').remove()
            if (searchType.value !== 'phone') {
                $('#lk-suggestion-input').val(item.data('label'))
            }
            _this.search.selectItem(item.data('id'))
            $('#lk-suggestion__dropdown').hide()
        })
        $(document).on('click', () => {
            const dropdown = $('.lk-dropdown')
            if (dropdown.is(':visible')) {
                dropdown.hide()
            }
        })
        $(document).on('input', '#lk-suggestion-input', () => {
            const request = $('#lk-suggestion-input').val()
            const searchType = _this.getSearchType()
            if (request.length > 0 && (searchType.validate === undefined || searchType.validate(request))) {
                delay(() => {
                    let params = _this.getSearchType().params(request)
                    _this.sendRequest(params)
                }, 1000)
            } else {
                $('.lk-dropdown').empty().hide()
            }
        })
        $(document).on('focus', '#lk-suggestion-input', () => {
            if ($('#lk-suggestion-input').val().length > 0) {
                $('.lk-suggestion__dropdown').show()
            }
        })
        if (searchInput.val()) {
            searchInput.trigger('input')
        }
        this.inited = true
    },
    getSearchType() {
        const type = $('#search_types').val();
        return this.search.searchTypes().find(searType => searType.value === type)
    },
    setSearchType() {
        $('#lk-suggestion-input').replaceWith(this.searchInput)
        const searchType = this.getSearchType(),
            managersList = $('select[name="filter[owner]"]')
        managersList.find('option:first').prop('selected', true)
        managersList.find('option:gt(1)').remove()
        managersList.attr('disabled', true)
        managersList.selectric('refresh')
        if (searchType.set !== undefined) {
            searchType.set()
        }
        window.App.inputMask.run()
    },
    sendRequest(params) {
		const searchType = this.getSearchType()
        params.before = () => {
            $('#lk-suggestion-input').prop('disabled', true)
        }
        this.crsClient.service(params).then(response => {
            if (['phone', 'email'].includes(searchType.value)) {
                crsUser.getOne(response.data.data.id)
                    .then(response => {
                        this.processResponse(response)
                    })
                    .catch(error => console.error(error))
            } else {
                this.processResponse(response)
            }
        })
            .catch(error => console.error(error))
            .finally(() => $('#lk-suggestion-input').prop('disabled', false))
    },
    processResponse(response) {
        const searchType = this.getSearchType()
        if (searchType.processResponse !== undefined) {
            response.data = searchType.processResponse(response.data)
        }
        this.search.processResponse(response.data)
    },
    setSearch() {
        const search_types = $('#search_types')
        if ($('#lk-switch-list').prop('checked') === true) {
            this.search = AgentSearch
        } else {
            this.search = SearchIndividual
        }
        search_types.find('option').remove()
        this.search.searchTypes().forEach(type => {
            $('#search_types').append(`<option value="${type.value}">${type.label}</option>`)
        })
        if (this.configs.type) {
            $(`#search_types option[value="${this.configs.type}"]`).prop('selected', true)
        }
        search_types.selectric('refresh');
        this.setSearchType()
    },
    refresh() {
        const searchInput = $('#lk-suggestion-input')[0]
        $('#lk-switch-list').removeAttr('checked')
        $('#lk-suggestion__dropdown-selected').remove()
        $('input[name="ownerSearchConfigs"]').remove()
        searchInput.value = ''
        searchInput.defaultValue = ''
        this.searchInput = searchInput.outerHTML
        this.configs = {}
        this.setSearch()
    }
}

module.exports = OwnerSearch;
