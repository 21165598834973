var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { "data-add-body-class": "bg-white" } }, [
    _c(
      "div",
      {
        staticClass: "compare",
        class: { loading: _vm.loading, "loading-glob": _vm.globalPreload },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.items,
                expression: "items",
              },
            ],
            staticClass: "compare__sidebar",
          },
          [
            _vm.tabs === "1"
              ? _c("form", { staticClass: "compare__mode" }, [
                  _c("p", { staticClass: "compare__mode-title" }, [
                    _vm._v(_vm._s(_vm.$t("filter.title"))),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "compare__checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.different,
                          expression: "different",
                        },
                      ],
                      staticClass: "compare__checkbox-input",
                      attrs: { type: "radio", name: "mode", value: "n" },
                      domProps: { checked: _vm._q(_vm.different, "n") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.different = "n"
                          },
                          _vm.getCompare,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "compare__checkbox-span" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("filter.check-box-compare-characteristics")
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.items
                    ? _c("label", { staticClass: "compare__checkbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.different,
                              expression: "different",
                            },
                          ],
                          staticClass: "compare__checkbox-input",
                          attrs: { type: "radio", name: "mode", value: "y" },
                          domProps: { checked: _vm._q(_vm.different, "y") },
                          on: {
                            click: function ($event) {
                              return _vm.getCompare(true)
                            },
                            change: function ($event) {
                              _vm.different = "y"
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "compare__checkbox-span" }, [
                          _vm._v(
                            _vm._s(_vm.$t("filter.check-box-compare-differ"))
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "compare__clear",
                on: { click: _vm.clearCompare },
              },
              [_vm._v(_vm._s(_vm.$t("filter.btn-clear")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabs === "1",
                    expression: "tabs === '1'",
                  },
                ],
                staticClass: "compare__list",
              },
              _vm._l(_vm.properties, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.NAME,
                        expression: "item.NAME",
                      },
                    ],
                    key: index,
                    staticClass: "compare__item",
                    class: [
                      "js-compare-item-" + index,
                      {
                        hide: ["ROOM_CLASSES_INFO", "STOPS"].includes(
                          item.CODE
                        ),
                      },
                    ],
                  },
                  [
                    item.NAME
                      ? _c("p", { staticClass: "compare__item-title" }, [
                          _vm._v(_vm._s(item.NAME)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ["ROOM_CLASSES_INFO", "STOPS"].includes(item.CODE)
                      ? _c(
                          "button",
                          {
                            staticClass: "compare__item-toggle",
                            on: {
                              click: function ($event) {
                                return _vm.toggle(
                                  $event,
                                  ".js-compare-item-" + index,
                                  200
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("btn-show-all")) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabs === "2",
                    expression: "tabs === '2'",
                  },
                ],
                staticClass: "compare__list compare__list-two",
                class: { "is-active": _vm.showFilter },
              },
              [
                _c("div", {
                  staticClass: "cs__btn-close",
                  on: {
                    click: function ($event) {
                      _vm.showFilter = false
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "cs__list-title" }, [
                  _vm._v(_vm._s(_vm.$t("filters"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cs-compare-wrap__items" },
                  _vm._l(_vm.filters, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "compare__item" },
                      [
                        _c("compare-select", {
                          attrs: {
                            id: index,
                            label:
                              index === 0
                                ? _vm.$t("filter.accommodation")
                                : _vm.$t("filter.tariff"),
                            name:
                              index === 0
                                ? _vm.$t("filter.select-btn-accommodation")
                                : _vm.$t("filter.select-btn-tariff"),
                            options: item,
                            values: _vm.selectedValue[index],
                          },
                          on: { select: _vm.selected },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("p", { staticClass: "compare__alert" }, [
                  _vm._v(_vm._s(_vm.$t("filter.text"))),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.items,
                expression: "items",
              },
            ],
            staticClass: "compare__body",
            class: { "cs-filter": _vm.tabs === "2" },
            style: { height: _vm.tabs === "1" ? "auto" : _vm.sliderHeight },
          },
          [
            _c("div", { staticClass: "compare__tabs" }, [
              _c("div", { staticClass: "compare__tab" }, [
                _c("label", { staticClass: "compare__checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tabs,
                        expression: "tabs",
                      },
                    ],
                    staticClass: "compare__checkbox-input",
                    attrs: { type: "radio", name: "tabs", value: "1" },
                    domProps: { checked: _vm._q(_vm.tabs, "1") },
                    on: {
                      change: function ($event) {
                        _vm.tabs = "1"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "compare__checkbox-span" }, [
                    _vm._v(_vm._s(_vm.$t("tabs.tab-condition"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.language === "ru"
                ? _c("div", { staticClass: "compare__tab" }, [
                    _c("label", { staticClass: "compare__checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tabs,
                            expression: "tabs",
                          },
                        ],
                        staticClass: "compare__checkbox-input",
                        attrs: { type: "radio", name: "tabs", value: "2" },
                        domProps: { checked: _vm._q(_vm.tabs, "2") },
                        on: {
                          change: [
                            function ($event) {
                              _vm.tabs = "2"
                            },
                            _vm.comparePrices,
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "compare__checkbox-span" }, [
                        _vm._v(_vm._s(_vm.$t("tabs.tab-price"))),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabs === "2",
                    expression: "tabs === '2'",
                  },
                ],
                staticClass: "compare-btn-filter",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    _vm.showFilter = !_vm.showFilter
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("filters")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "compare__controls" }, [
              _c("button", {
                ref: "prev",
                staticClass: "compare__controll prev",
              }),
              _vm._v(" "),
              _c("button", { ref: "next", staticClass: "compare__controll" }),
            ]),
            _vm._v(" "),
            _c("div", { ref: "container", staticClass: "compare__slider" }, [
              _c(
                "div",
                { staticClass: "compare__wrapper" },
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "compare__slide" },
                    [
                      _c("div", { staticClass: "compare-product" }, [
                        _c("div", { staticClass: "compare-product__header" }, [
                          _c(
                            "div",
                            { staticClass: "compare-product__headline" },
                            [
                              item.NAME
                                ? _c("a", {
                                    staticClass: "compare-product__title",
                                    attrs: { href: item.DETAIL_PAGE_URL },
                                    domProps: { innerHTML: _vm._s(item.NAME) },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item["DISPLAY_PROPERTIES"][
                                "ROOMS_AVAILABLE_COUNT"
                              ]["~VALUE"] > 0
                                ? _c("a", {
                                    staticClass: "compare-product__buy",
                                    attrs: { href: item.booking_link },
                                  })
                                : _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "btn-svg-bell-waiting js-btn-open-waiting-popup",
                                      attrs: {
                                        "data-mfp-src": "#waiting-popup",
                                        "data-cruise-id": item.XML_ID,
                                        width: "17",
                                        height: "20",
                                        viewBox: "0 0 17 20",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M13 15H4V8.5C4 6 6 4 8.5 4C11 4 13 6 13 8.5V15ZM15 14V8.5C15 5.43 12.86 2.86 10 2.18V1.5C10 1.10218 9.84196 0.720645 9.56066 0.43934C9.27936 0.158035 8.89782 0 8.5 0C8.10218 0 7.72064 0.158035 7.43934 0.43934C7.15804 0.720645 7 1.10218 7 1.5V2.18C4.13 2.86 2 5.43 2 8.5V14L0 16V17H17V16L15 14ZM8.5 20C9.03043 20 9.53914 19.7893 9.91421 19.4142C10.2893 19.0391 10.5 18.5304 10.5 18H6.5C6.5 18.5304 6.71071 19.0391 7.08579 19.4142C7.46086 19.7893 7.96957 20 8.5 20Z",
                                          fill: "#8f2e3f",
                                        },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "compare-product__remove",
                              on: {
                                click: function ($event) {
                                  return _vm.removeCompare(item.ID)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("btn-delete")) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "compare-product__body" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tabs === "1",
                                  expression: "tabs === '1'",
                                },
                              ],
                              staticClass: "compare-product__list",
                            },
                            _vm._l(_vm.properties, function (property, index) {
                              return _c(
                                "div",
                                {
                                  staticClass: "compare-product__item",
                                  class: [
                                    "js-compare-item-" + index,
                                    {
                                      hide: [
                                        "ROOM_CLASSES_INFO",
                                        "STOPS",
                                      ].includes(item.CODE),
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "compare-product__name" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            item.DISPLAY_PROPERTIES[
                                              property.CODE
                                            ].NAME
                                          ) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  property.CODE === "MIN_PRICE"
                                    ? _c("p", {
                                        staticClass: "compare-product__price",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.DISPLAY_PROPERTIES[
                                              property.CODE
                                            ].VALUE
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "DATE_START"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "compare-product__icon",
                                            attrs: {
                                              src: "/local/client/icons/icon-compare-calendar.svg?inline",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__date",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "DAYS_COUNT"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "compare-product__icon",
                                            attrs: {
                                              src: "/local/client/icons/icon-compare-clock.svg?inline",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__date",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "MOTOR_SHIP"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "compare-product__icon",
                                            attrs: {
                                              src: "/local/client/icons/icon-compare-ship.svg?inline",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "compare-product__link",
                                              attrs: {
                                                href: item.DISPLAY_PROPERTIES[
                                                  property.CODE
                                                ].VALUE.DETAIL_PAGE_URL,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE.NAME
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__badge",
                                              class:
                                                "compare-product__badge--" +
                                                item.DISPLAY_PROPERTIES[
                                                  property.CODE
                                                ].VALUE.CLASS_CSS,
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE.CLASS_NAME
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "ROUTE_CITIES"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__address-short",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE.SHORT
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "ROUTE_CITIES"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "compare-product__icon",
                                            attrs: {
                                              src: "/local/client/icons/icon-compare-place.svg?inline",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__address",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE.FULL
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "ROOM_CLASSES_INFO"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "compare__item-toggle",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggle(
                                                $event,
                                                ".js-compare-item-" + index,
                                                200
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.$t("btn-show-all")) +
                                              "\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    item.DISPLAY_PROPERTIES[property.CODE]
                                      .VALUE,
                                    function (roomClass, key) {
                                      return property.CODE ===
                                        "ROOM_CLASSES_INFO"
                                        ? _c(
                                            "div",
                                            {
                                              key: key,
                                              staticClass:
                                                "compare-product__cabin",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "compare-product__cabin-cover",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "compare-product__cabin-img",
                                                    attrs: {
                                                      src: roomClass.PREVIEW_PICTURE,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "compare-product__cabin-info",
                                                },
                                                [
                                                  roomClass.NAME
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "compare-product__cabin-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                roomClass.NAME
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "compare-product__cabin-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(
                                                            _vm.$t("from")
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            roomClass.PRICE
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t("valuta")
                                                          ) +
                                                          "/\n                            " +
                                                          _vm._s(
                                                            _vm.$t("person")
                                                          ) +
                                                          "\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  _vm._v(" "),
                                  property.CODE === "ROOMS_AVAILABLE_COUNT"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__alert",
                                              class: {
                                                "compare-product__alert--green":
                                                  item.DISPLAY_PROPERTIES[
                                                    property.CODE
                                                  ].VALUE.includes(">"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE
                                                  ) +
                                                  "\n                          " +
                                                  _vm._s(
                                                    +item.DISPLAY_PROPERTIES
                                                      .MOTOR_SHIP.VALUE
                                                      .MOTOR_SHIP_ID !== 74535
                                                      ? _vm.$t("place")
                                                      : _vm.$t("place_tour")
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "SPECIAL_OFFERS"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "compare-product__info",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__text",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES[
                                                      property.CODE
                                                    ].VALUE
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "CONDITIONS"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "compare-product__services",
                                        },
                                        _vm._l(
                                          item.DISPLAY_PROPERTIES[property.CODE]
                                            .VALUE,
                                          function (condition, index) {
                                            return _c("img", {
                                              key: index,
                                              staticClass:
                                                "compare-product__service",
                                              attrs: {
                                                src: condition.ICON,
                                                alt: condition.NAME,
                                              },
                                            })
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "INCLUDED"
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.DISPLAY_PROPERTIES[
                                              property.CODE
                                            ].VALUE
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  property.CODE === "STOPS"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "compare__item-toggle",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggle(
                                                $event,
                                                ".js-compare-item-" + _vm.i,
                                                200
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.$t("btn-show-all")) +
                                              "\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    item.DISPLAY_PROPERTIES[property.CODE]
                                      .VALUE,
                                    function (item, index) {
                                      return property.CODE === "STOPS"
                                        ? _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "compare-product__day",
                                            },
                                            [
                                              item.TITLE
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "compare-product__day-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(item.TITLE) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm._l(
                                                item.ITEMS,
                                                function (city) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "compare-product__block",
                                                    },
                                                    [
                                                      item.ITEMS.length > 1
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    city.PORT
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.DATE
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-date",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "program.date"
                                                                    )
                                                                  ) +
                                                                  ": " +
                                                                  _vm._s(
                                                                    city.DATE
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.TIME_IN
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-date",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "program.time-arrival"
                                                                    )
                                                                  ) +
                                                                  ": " +
                                                                  _vm._s(
                                                                    city.TIME_IN
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.TIME_OUT
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-date",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "program.time-departure"
                                                                    )
                                                                  ) +
                                                                  ": " +
                                                                  _vm._s(
                                                                    city.TIME_OUT
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.TIME_STOP
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-date",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "program.time-parking"
                                                                    )
                                                                  ) +
                                                                  ": " +
                                                                  _vm._s(
                                                                    city.TIME_STOP
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.DAY_PROGRAM
                                                        ? _c("p", {
                                                            staticClass:
                                                              "compare-product__day-alert",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                city.DAY_PROGRAM
                                                              ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.MAIN_PROGRAM
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-title",
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "compare-product__day-title compare-product__day-title--programm",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "program.main-program"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                city.MAIN_PROGRAM,
                                                                function (
                                                                  program,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: index,
                                                                    },
                                                                    [
                                                                      city
                                                                        .MAIN_PROGRAM
                                                                        .length >
                                                                      1
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "compare-product__day-title",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "program.variant"
                                                                                    )
                                                                                  ) +
                                                                                  " - " +
                                                                                  _vm._s(
                                                                                    index +
                                                                                      1
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "compare-product__day-title",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "ul",
                                                                            _vm._l(
                                                                              program,
                                                                              function (
                                                                                excursion
                                                                              ) {
                                                                                return _c(
                                                                                  "li",
                                                                                  {
                                                                                    staticClass:
                                                                                      "compare-product__day-alert",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                    " +
                                                                                        _vm._s(
                                                                                          excursion.NAME.split(
                                                                                            '"'
                                                                                          ).join(
                                                                                            '"'
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                  "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      city.ADDITIONAL_PROGRAM
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "compare-product__day-title",
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "compare-product__day-title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "program.additional"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "compare-product__day-title",
                                                                },
                                                                [
                                                                  _c(
                                                                    "ul",
                                                                    _vm._l(
                                                                      city.ADDITIONAL_PROGRAM,
                                                                      function (
                                                                        excursion
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            staticClass:
                                                                              "compare-product__day-alert",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  " +
                                                                                _vm._s(
                                                                                  excursion.NAME
                                                                                ) +
                                                                                "\n                                "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          item.PRICES && _vm.tabs === "2"
                            ? _c(
                                "div",
                                { staticClass: "compare-product__prices" },
                                [
                                  item.DISPLAY_PROPERTIES.MOTOR_SHIP
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "compare-product__item js-compare-item-3",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__name",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES
                                                      .MOTOR_SHIP.NAME
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "compare-product__info",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "compare-product__icon",
                                                attrs: {
                                                  src: "/local/client/icons/icon-compare-ship.svg?inline",
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "compare-product__link",
                                                  attrs: {
                                                    href: item
                                                      .DISPLAY_PROPERTIES
                                                      .MOTOR_SHIP.VALUE
                                                      .DETAIL_PAGE_URL,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        item.DISPLAY_PROPERTIES
                                                          .MOTOR_SHIP.VALUE.NAME
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "compare-product__badge",
                                                  class:
                                                    "compare-product__badge--" +
                                                    item.DISPLAY_PROPERTIES
                                                      .MOTOR_SHIP.VALUE
                                                      .CLASS_CSS,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        item.DISPLAY_PROPERTIES
                                                          .MOTOR_SHIP.VALUE
                                                          .CLASS_NAME
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.DISPLAY_PROPERTIES.DATE_START
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "compare-product__item js-compare-item-1",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__name",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES
                                                      .DATE_START.NAME
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "compare-product__info",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "compare-product__icon",
                                                attrs: {
                                                  src: "/local/client/icons/icon-compare-calendar.svg?inline",
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "compare-product__date",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        item.DISPLAY_PROPERTIES
                                                          .DATE_START.VALUE
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.DISPLAY_PROPERTIES.ROUTE_CITIES
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "compare-product__item js-compare-item-4",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__name",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    item.DISPLAY_PROPERTIES
                                                      .ROUTE_CITIES.NAME
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "compare-product__info",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "compare-product__address-short",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        item.DISPLAY_PROPERTIES
                                                          .ROUTE_CITIES.VALUE
                                                          .SHORT
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "compare-product__info",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "compare-product__icon",
                                                attrs: {
                                                  src: "/local/client/icons/icon-compare-place.svg?inline",
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "compare-product__address",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        item.DISPLAY_PROPERTIES
                                                          .ROUTE_CITIES.VALUE
                                                          .FULL
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    item.PRICES.decks,
                                    function (price, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "compare-product__price",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "compare-product__price-title",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(price.name) + " палуба"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "compare-table" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "compare-table__tr",
                                                  class:
                                                    _vm.classCountSelectValue(
                                                      item.PRICES.metaTariffs
                                                    ),
                                                },
                                                [
                                                  _vm._m(0, true),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.PRICES.metaTariffs,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.selectedValue[1].includes(
                                                                  item.id
                                                                ) ||
                                                                _vm
                                                                  .selectedValue[1]
                                                                  .length == 0,
                                                              expression:
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[1].includes(item.id) ||\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[1].length == 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                            },
                                                          ],
                                                          key: index,
                                                          staticClass:
                                                            "compare-table__td",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                price.roomClasses,
                                                function (roomClasses, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "compare-table__trow",
                                                      class:
                                                        _vm.classCountSelectValue(
                                                          item.PRICES
                                                            .metaTariffs
                                                        ),
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "compare-table__name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              roomClasses.name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "compare-table__tr",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "compare-table__td is-prices",
                                                            },
                                                            _vm._l(
                                                              roomClasses.accommodationTypes,
                                                              function (
                                                                accommodation,
                                                                g
                                                              ) {
                                                                return _c(
                                                                  "p",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            _vm.selectedValue[0].includes(
                                                                              accommodation.id
                                                                            ) ||
                                                                            _vm
                                                                              .selectedValue[0]
                                                                              .length ===
                                                                              0,
                                                                          expression:
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[0].includes(accommodation.id) ||\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[0].length === 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                        },
                                                                      ],
                                                                    key: g,
                                                                    staticClass:
                                                                      "compare-table__price",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          accommodation.name
                                                                        ) +
                                                                        "\n                              "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            item.PRICES
                                                              .metaTariffs,
                                                            function (col, i) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.selectedValue[1].includes(
                                                                          col.id
                                                                        ) ||
                                                                        _vm
                                                                          .selectedValue[1]
                                                                          .length ===
                                                                          0,
                                                                      expression:
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[1].includes(col.id) ||\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[1].length === 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                    },
                                                                  ],
                                                                  key: i,
                                                                  staticClass:
                                                                    "compare-table__td is-prices price-nowrap",
                                                                },
                                                                _vm._l(
                                                                  roomClasses.accommodationTypes,
                                                                  function (
                                                                    accommodation,
                                                                    q
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                _vm.selectedValue[0].includes(
                                                                                  accommodation.id
                                                                                ) ||
                                                                                _vm
                                                                                  .selectedValue[0]
                                                                                  .length ===
                                                                                  0,
                                                                              expression:
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[0].includes(accommodation.id) ||\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tselectedValue[0].length === 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                            },
                                                                          ],
                                                                        key: q,
                                                                        staticClass:
                                                                          "compare-table__price",
                                                                      },
                                                                      [
                                                                        roomClasses
                                                                          .tariffs[
                                                                          col.id
                                                                        ] ===
                                                                          undefined ||
                                                                        roomClasses
                                                                          .tariffs[
                                                                          col.id
                                                                        ]
                                                                          .accommodations[
                                                                          accommodation
                                                                            .id
                                                                        ] ===
                                                                          undefined ||
                                                                        +roomClasses.tariffs[
                                                                          col.id
                                                                        ].accommodations[
                                                                          accommodation
                                                                            .id
                                                                        ].price.value.trim()[0] ===
                                                                          0
                                                                          ? _c(
                                                                              "p",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  -\n                                "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "p",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        roomClasses
                                                                                          .tariffs[
                                                                                          col
                                                                                            .id
                                                                                        ]
                                                                                          .accommodations[
                                                                                          accommodation
                                                                                            .id
                                                                                        ]
                                                                                          .price
                                                                                          .value
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", {
              ref: "scroll",
              staticClass: "compare__scroll swiper-scrollbar",
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "h2",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.items,
                expression: "!items",
              },
            ],
          },
          [_vm._v(_vm._s(_vm.$t("msg-null")) + ".")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "compare-table__td" }, [
      _c("p", [_vm._v("Размещение")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }