import inputMask from './inputMask.js'
// import toggleList from './toggleList';
// import counter from './counter';
import datepicker from './datepicker'
import select from './select'
import inputs from './inputs'
import sliders from './slider/sliders'
import toggleList from './toggleList'
import tooltip from './tooltip'
import customScroll from './customScroll'

const modals = {
	run() {
		this.ajax()
		this.inline()
		this.ajaxMarketing()
		this.video()
		this.gallery()
		this.gallerySlider()
		this.galleryDaySlider()
		this.close()
		this.callbackform()
		this.officeRegistrationForm()

		$('[data-modal]').on('click', e => e.stopPropagation())
		$('[data-modal]').css('pointerEvents', 'all')
	},

	reInit() {
		this.ajax()
		this.ajaxMarketing()
		this.video()
	},

	indentItems: $('.h__top, body:not(.m) .h__bottom, .m-filter, .m-slider .slider__paginations, body:not(.m) main'),

	thisPopup: null,

	callback: {
		open() {
			$('header, main').css('marginRight', this.scrollbarSize * -1)
			modals.indentItems.css('paddingRight', this.scrollbarSize)
			modals.thisPopup = $.magnificPopup.instance
			$(document).on('mousedown', '.mfp-wrap', e => {
				if ($(e.target).is('.mfp-container, .mfp-content') && modals.thisPopup) {
					/* проверка для отмены закрытия модалки с введенными данными */
					const $form = $('.mfp-content form')
					const $inputs = $form.find('.b-form__item input')
					const $textarea = $form.find('.b-form__item textarea')
					let counter = 0
					if ($form.length > 0) {
						$inputs.each(function () {
							if ($(this).val().length > 0) {
								counter++
							}
						})
						$textarea.each(function () {
							if ($(this).val().length > 0) {
								counter++
							}
						})
						if (counter === 0) {
							modals.thisPopup.close()
						}
					} else {
						modals.thisPopup.close()
					}
				}
			})
		},
		close() {
			$('header, main').css('marginRight', 0)
			modals.indentItems.css('paddingRight', '')
		},
		ajaxContentAdded() {
			inputMask.run()
			inputs.placeholder()
			datepicker.run()
			select.run()
			sliders.souvenirsSlider()
			sliders.cardModalSlider()
			sliders.shipServiceSliderNEW()
			toggleList.password()
			tooltip.run()
			customScroll.scroll()
			// showFileName.run();
			// toggleList.run();
			// counter.run();
		},
		elementParse(item) {
			if (item.el.hasClass('d-slider__preview--video')) {
				item.type = 'iframe'
			}
			if (item.el.hasClass('popup-day-gallery--video')) {
				item.type = 'iframe'
			}
		},
	},

	callbackMarketing: {
		elementParse() {
			const ids = []

			$('input[type="checkbox"]:checked').each(function () {
				ids.push($(this).attr('data-id'))
			})

			const postData = {
				ids,
			}

			const mp = $.magnificPopup.instance
			mp.st.ajax.settings.data = postData
		},
		open() {
			$('header, main').css('marginRight', this.scrollbarSize * -1)
			modals.header.css('paddingRight', this.scrollbarSize)
			modals.thisPopup = $.magnificPopup.instance

			$(document).on('mousedown', '.mfp-wrap', e => {
				if ($(e.target).is('.mfp-container, .mfp-content') && modals.thisPopup) modals.thisPopup.close()
			})
		},
		close() {
			$('header, main').css('marginRight', 0)
			modals.header.css('paddingRight', '')
		},
		ajaxContentAdded() {
			inputMask.run()
			inputs.placeholder()
			// datepicker.run();
			showFileName.run()
			select.run()
			toggleList.run()
			counter.run()
		},
	},
	
	close() {
		$(document).on('click', '[data-modal-close], [data-nav-mobile]', $.magnificPopup.close)
	},

	ajax($element = false) {
		const ajaxModals = $element || $('[data-modal="ajax"]')
		if (ajaxModals.length) {
			ajaxModals.magnificPopup({
				type: 'ajax',
				preloader: false,
				fixedBgPos: true,
				showCloseBtn: false,
				closeOnBgClick: false,
				removalDelay: 300,
				mainClass: 'mfp-fade',
				callbacks: modals.callback,
			})
		}
	},

	ajaxMarketing($element = false) {
		const ajaxModals = $element || $('[data-modal="marketing"]')
		if (ajaxModals.length) {
			ajaxModals.magnificPopup({
				type: 'ajax',
				ajax: {
					settings: {
						type: 'POST',
						data: {},
					},
				},
				preloader: false,
				fixedBgPos: true,
				showCloseBtn: false,
				closeOnBgClick: false,
				removalDelay: 300,
				mainClass: 'mfp-fade',
				callbacks: modals.callbackMarketing,
			})
		}
	},

	video() {
		const videoModals = $('[data-modal="video"]')
		if (videoModals.length) {
			videoModals.magnificPopup({
				type: 'iframe',
				removalDelay: 300,
				showCloseBtn: false,
				midClick: true,
				mainClass: 'mfp-fade',
				callbacks: modals.callback,
			})
		}
	},

	inline() {
		const inlineModals = $('[data-modal="inline"]')
		if (inlineModals.length) {
			inlineModals.magnificPopup({
				type: 'inline',
				preloader: false,
				focus: '#username',
				modal: true,
			})
		}
	},
	gallerySlider() {
		const isMobile = window.innerWidth < 768
		const imageModals = $('[data-modal="gallerySlider"]')
		if (imageModals.length) {
			imageModals.each(function () {
				$(this).magnificPopup({
					delegate: '.swiper-slide:not(.swiper-slide-duplicate) a',
					type: 'image',
					gallery: {
						enabled: true,
						navigateByImgClick: true,
						tCounter: '%curr% / %total%', // markup of counter
					},
					preloader: false,
					fixedContentPos: true,
					fixedBgPos: true,
					overflowY: 'hidden',
					mainClass: 'mfp-fade',
					showCloseBtn: false,
					removalDelay: 300,
				})
			})
		}
	},

	galleryDaySlider() {
		const isMobile = window.innerWidth < 768
		const imageModals = $('.popup-day-gallery')
		if (imageModals.length) {
			imageModals.each(function () {
				$(this).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true,
						navigateByImgClick: true,
						tCounter: '%curr% / %total%', // markup of counter
					},
					preloader: false,
					fixedContentPos: true,
					fixedBgPos: true,
					overflowY: 'hidden',
					mainClass: 'mfp-fade',
					showCloseBtn: false,
					removalDelay: 300,
					callbacks: modals.callback,
				})
			})
		}
	},

	gallery() {
		const isMobile = window.innerWidth < 768
		const imageModals = $('[data-modal="gallery"]')
		if (imageModals.length) {
			imageModals.each(function () {
				$(this).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true,
						tCounter: '%curr% / %total%', // markup of counter
					},
					preloader: false,
					fixedContentPos: true,
					fixedBgPos: true,
					overflowY: 'hidden',
					mainClass: 'mfp-fade',
					showCloseBtn: false,
					removalDelay: 300,
					callbacks: modals.callback,
				})
			})
		}

		$('[data-modal-image-btn]').on('click', function () {
			$(this).parents('[data-modal-image-wrap]').find('a:first').click()
		})

		if (isMobile) {
			imageModals.on('click', function () {
				if (!$(this).hasClass('awards')) {
					$(this).find('a:first-child:not(.no-photo)').click()
				}
			})
		}

		$('.b-media-zoom').addClass('init')
		/* добавление анимации при инициализации */
	},

	showDialog(url, afterOpenCallBack) {
		$.magnificPopup.open({
			items: {
				src: url,
				type: 'ajax',
			},
			preloader: false,
			showCloseBtn: false,
			mainClass: 'mfp-fade',
			callbacks: {
				ajaxContentAdded() {
					App.inputs.run()
					App.select.run()
					App.inputMask.run()

					if (afterOpenCallBack && typeof afterOpenCallBack === 'function') {
						afterOpenCallBack()
					}
				},
			},
		})
	},

	callbackform() {
		$(function () {
			$('.popup-callback-modal').magnificPopup({
				type: 'inline',
				preloader: false,
				focus: '#username',
				modal: true,
			})
			$(document).on('click', '.popup-modal-dismiss', function (e) {
				e.preventDefault()
				$.magnificPopup.close()
			})
		})
	},

	officeRegistrationForm() {
		$(function () {
			$('[data-modal="form-office"]').magnificPopup({
				type: 'ajax',
				preloader: true,
				removalDelay: 310,
				mainClass: 'mfp-fade',
				modal: true,
			})
			$(document).on('click', '.popup-modal-dismiss', function (e) {
				e.preventDefault()
				$.magnificPopup.close()
			})
		})
		window.removeFormPreloader = () => {
			$('.js-fo-preloader').removeClass('d-flex')
		}
	},
}

module.exports = modals
