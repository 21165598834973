const accoSimple = {
    run() {
        this.$box = $('.js-header-agent-menu').find('[data-acco-ex]');

        if(this.$box.length) {
            this.$openers = this.$box.find('[data-acco-ex-opener]');
            this.$openers.on('click', evt => {
                this.toggleItem({
                    $item: $(evt.currentTarget).closest('[data-acco-ex-item]'),
                });
            });
        }
    },

    toggleItem({$item}) {
        $item.hasClass('opened') ? this.hideItem({$item: $item}) : this.showItem({$item: $item});
    },

    hideItem({$item}) {
        $item.removeClass('opened');
    },

    showItem({$item}) {
        $item.addClass('opened');
    },
};

module.exports = accoSimple;