let scroll = {
    scrollToElement(element) {
        let self = this;
        if (!element.length) {
            return;
        }

        let wrapModal = element.parents('.b-modal');
        if (wrapModal && wrapModal.length) {
            return;
        }

        self.stateCheck = false;
        let heightHeader = $("header.h").height() + 50;
        $('html, body').animate({
            scrollTop: element.offset().top - heightHeader
        }, 700, () => {
            self.stateCheck = true;
        });
    },

    scrollContact(el) {
        let $btn = el.find('.d-tabs-link.active');
        if ($btn.length > 0) {
            el.scrollLeft($btn[0].offsetLeft);
        }
    }
};

module.exports = scroll;