<template>
  <div data-add-body-class="bg-white">
    <div class="compare" :class="{loading: loading, 'loading-glob': globalPreload}">
      <div v-show="items" class="compare__sidebar">
        <form v-if="tabs === '1'" class="compare__mode">
          <p class="compare__mode-title">{{ $t('filter.title') }}</p>
          <label class="compare__checkbox">
            <input
                v-model="different"
                type="radio"
                name="mode"
                class="compare__checkbox-input"
                value="n"
                @change="getCompare"
            />
            <span class="compare__checkbox-span">{{ $t('filter.check-box-compare-characteristics') }}</span>
          </label>
          <label v-if="items" class="compare__checkbox">
            <input
                v-model="different"
                type="radio"
                name="mode"
                value="y"
                class="compare__checkbox-input"
                @click="getCompare(true)"
            />
            <span class="compare__checkbox-span">{{ $t('filter.check-box-compare-differ') }}</span>
          </label>
        </form>
        <button class="compare__clear" @click="clearCompare">{{ $t('filter.btn-clear') }}</button>
        <div v-show="tabs === '1'" class="compare__list">
          <div
              v-for="(item, index) in properties"
              v-show="item.NAME"
              :key="index"
              class="compare__item"
              :class="[
							`js-compare-item-${index}`,
							{
								hide: ['ROOM_CLASSES_INFO', 'STOPS'].includes(item.CODE)
							},
						]"
          >
            <p v-if="item.NAME" class="compare__item-title">{{ item.NAME }}</p>
            <button
                v-if="['ROOM_CLASSES_INFO', 'STOPS'].includes(item.CODE)"
                class="compare__item-toggle"
                @click="toggle($event, `.js-compare-item-${index}`, 200)"
            >
              {{ $t('btn-show-all') }}
            </button>
          </div>
        </div>
        <div v-show="tabs === '2'" class="compare__list compare__list-two" :class="{'is-active': showFilter}">
          <div class="cs__btn-close" @click="showFilter = false"></div>
          <p class="cs__list-title">{{ $t('filters') }}</p>
          <div class="cs-compare-wrap__items">
            <div v-for="(item, index) in filters" :key="index" class="compare__item">
              <compare-select
                  :id="index"
                  :label="index === 0 ? $t('filter.accommodation') : $t('filter.tariff')"
                  :name="
									index === 0 ? $t('filter.select-btn-accommodation') : $t('filter.select-btn-tariff')
								"
                  :options="item"
                  :values="selectedValue[index]"
                  @select="selected"
              />
            </div>
          </div>

          <p class="compare__alert">{{ $t('filter.text') }}</p>
        </div>
      </div>
      <div
          v-show="items"
          class="compare__body"
          :class="{'cs-filter': tabs === '2'}"
          :style="{height: tabs === '1' ? 'auto' : sliderHeight}"
      >
        <!-- tabs -->
        <div class="compare__tabs">
          <div class="compare__tab">
            <label class="compare__checkbox">
              <input v-model="tabs" type="radio" name="tabs" value="1" class="compare__checkbox-input" />
              <span class="compare__checkbox-span">{{ $t('tabs.tab-condition') }}</span>
            </label>
          </div>
          <div v-if="language === 'ru'" class="compare__tab">
            <label class="compare__checkbox">
              <input
                  v-model="tabs"
                  type="radio"
                  name="tabs"
                  value="2"
                  class="compare__checkbox-input"
                  @change="comparePrices"
              />
              <span class="compare__checkbox-span">{{ $t('tabs.tab-price') }}</span>
            </label>
          </div>
        </div>
        <!-- btn -->
        <a
            v-show="tabs === '2'"
            class="compare-btn-filter"
            href="javascript:;"
            @click="showFilter = !showFilter"
        >{{ $t('filters') }}</a
        >

        <div class="compare__controls">
          <button ref="prev" class="compare__controll prev"></button>
          <button ref="next" class="compare__controll"></button>
        </div>
        <div ref="container" class="compare__slider">
          <div class="compare__wrapper">
            <div v-for="(item, index) in items" :key="index" class="compare__slide">
              <div class="compare-product">
                <div class="compare-product__header">
                  <div class="compare-product__headline">
                    <a
                        v-if="item.NAME"
                        :href="item.DETAIL_PAGE_URL"
                        class="compare-product__title"
                        v-html="item.NAME"
                    ></a>
                    <a
                        v-if="item['DISPLAY_PROPERTIES']['ROOMS_AVAILABLE_COUNT']['~VALUE'] > 0"
                        class="compare-product__buy"
                        :href="item.booking_link"
                    ></a>
                    <svg
                        v-else
                        class="btn-svg-bell-waiting js-btn-open-waiting-popup"
                        data-mfp-src="#waiting-popup"
                        :data-cruise-id="item.XML_ID"
                        width="17"
                        height="20"
                        viewBox="0 0 17 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M13 15H4V8.5C4 6 6 4 8.5 4C11 4 13 6 13 8.5V15ZM15 14V8.5C15 5.43 12.86 2.86 10 2.18V1.5C10 1.10218 9.84196 0.720645 9.56066 0.43934C9.27936 0.158035 8.89782 0 8.5 0C8.10218 0 7.72064 0.158035 7.43934 0.43934C7.15804 0.720645 7 1.10218 7 1.5V2.18C4.13 2.86 2 5.43 2 8.5V14L0 16V17H17V16L15 14ZM8.5 20C9.03043 20 9.53914 19.7893 9.91421 19.4142C10.2893 19.0391 10.5 18.5304 10.5 18H6.5C6.5 18.5304 6.71071 19.0391 7.08579 19.4142C7.46086 19.7893 7.96957 20 8.5 20Z"
                          fill="#8f2e3f"
                      />
                    </svg>
                  </div>
                  <button class="compare-product__remove" @click="removeCompare(item.ID)">
                    {{ $t('btn-delete') }}
                  </button>
                </div>
                <div class="compare-product__body">
                  <div v-show="tabs === '1'" class="compare-product__list">
                    <div
                        class="compare-product__item"
                        v-for="(property, index) in properties"
                        :class="[
                            `js-compare-item-${index}`,
                            {
                              hide: ['ROOM_CLASSES_INFO', 'STOPS'].includes(item.CODE)
                            },
                        ]"
                    >
                      <p class="compare-product__name">
                        {{ item.DISPLAY_PROPERTIES[property.CODE].NAME }}
                      </p>
                      <p class="compare-product__price"
                         v-if="property.CODE === 'MIN_PRICE'"
                          v-html="item.DISPLAY_PROPERTIES[property.CODE].VALUE"
                      ></p>
                      <div class="compare-product__info" v-if="property.CODE === 'DATE_START'">
                        <img
                            src="/local/client/icons/icon-compare-calendar.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <p class="compare-product__date">
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE }}
                        </p>
                      </div>
                      <div class="compare-product__info" v-if="property.CODE === 'DAYS_COUNT'">
                        <img
                            src="/local/client/icons/icon-compare-clock.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <p class="compare-product__date">
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE }}
                        </p>
                      </div>
                      <div class="compare-product__info" v-if="property.CODE === 'MOTOR_SHIP'">
                        <img
                            src="/local/client/icons/icon-compare-ship.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <a
                            :href="item.DISPLAY_PROPERTIES[property.CODE].VALUE.DETAIL_PAGE_URL"
                            class="compare-product__link"
                        >
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE.NAME }}
                        </a>
                        <p
                            class="compare-product__badge"
                            :class="`compare-product__badge--${item.DISPLAY_PROPERTIES[property.CODE].VALUE.CLASS_CSS}`"
                        >
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE.CLASS_NAME }}
                        </p>
                      </div>
                      <div class="compare-product__info" v-if="property.CODE === 'ROUTE_CITIES'">
                        <p class="compare-product__address-short">
                          {{item.DISPLAY_PROPERTIES[property.CODE].VALUE.SHORT }}
                        </p>
                      </div>
                      <div class="compare-product__info" v-if="property.CODE === 'ROUTE_CITIES'">
                        <img
                            src="/local/client/icons/icon-compare-place.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <p class="compare-product__address">
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE.FULL }}
                        </p>
                      </div>
                      <button v-if="property.CODE === 'ROOM_CLASSES_INFO'"
                          class="compare__item-toggle"
                          @click="toggle($event, `.js-compare-item-${index}`, 200)"
                      >
                        {{ $t('btn-show-all') }}
                      </button>
                      <div v-if="property.CODE === 'ROOM_CLASSES_INFO'"
                          v-for="(roomClass, key) in item.DISPLAY_PROPERTIES[property.CODE].VALUE"
                          :key="key"
                          class="compare-product__cabin"
                      >
                        <div class="compare-product__cabin-cover">
                          <img
                              :src="roomClass.PREVIEW_PICTURE"
                              alt=""
                              class="compare-product__cabin-img"
                          />
                        </div>
                        <div class="compare-product__cabin-info">
                          <p v-if="roomClass.NAME" class="compare-product__cabin-title">
                            {{ roomClass.NAME }}
                          </p>
                          <p class="compare-product__cabin-price">
                            {{ $t('from') }} {{ roomClass.PRICE }} {{ $t('valuta') }}/
                            {{ $t('person') }}
                          </p>
                        </div>
                      </div>
                      <div class="compare-product__info" v-if="property.CODE === 'ROOMS_AVAILABLE_COUNT'">
                        <p
                            class="compare-product__alert"
                            :class="{
														'compare-product__alert--green':
															item.DISPLAY_PROPERTIES[property.CODE].VALUE.includes(
																'>',
															),
													}"
                        >
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE }}
                          {{
                            +item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.MOTOR_SHIP_ID !== 74535
                                ? $t('place')
                                : $t('place_tour')
                          }}
                        </p>
                      </div>
                      <div class="compare-product__info" v-if="property.CODE === 'SPECIAL_OFFERS'">
                        <p class="compare-product__text">
                          {{ item.DISPLAY_PROPERTIES[property.CODE].VALUE }}
                        </p>
                      </div>
                      <div class="compare-product__services" v-if="property.CODE === 'CONDITIONS'">
                        <img
                            v-for="(condition, index) in item.DISPLAY_PROPERTIES[property.CODE].VALUE"
                            :key="index"
                            :src="condition.ICON"
                            :alt="condition.NAME"
                            class="compare-product__service"
                        />
                      </div>
                      <div v-if="property.CODE === 'INCLUDED'"
                          v-html="item.DISPLAY_PROPERTIES[property.CODE].VALUE"></div>
                      <button v-if="property.CODE === 'STOPS'"
                          class="compare__item-toggle"
                          @click="toggle($event, `.js-compare-item-${i}`, 200)"
                      >
                        {{ $t('btn-show-all') }}
                      </button>
                      <div v-if="property.CODE === 'STOPS'"
                          v-for="(item, index) in item.DISPLAY_PROPERTIES[property.CODE].VALUE"
                          :key="index"
                          class="compare-product__day"
                      >
                        <p v-if="item.TITLE" class="compare-product__day-title">
                          {{ item.TITLE }}
                        </p>
                        <!-- :key="city.CITY.ID" => null по этому ошибка  -->
                        <div v-for="city in item.ITEMS" class="compare-product__block">
                          <p v-if="item.ITEMS.length > 1" class="compare-product__day-title">
                            {{ city.PORT }}
                          </p>
                          <p v-if="city.DATE" class="compare-product__day-date">
                            {{ $t('program.date') }}: {{ city.DATE }}
                          </p>
                          <p v-if="city.TIME_IN" class="compare-product__day-date">
                            {{ $t('program.time-arrival') }}: {{ city.TIME_IN }}
                          </p>
                          <p v-if="city.TIME_OUT" class="compare-product__day-date">
                            {{ $t('program.time-departure') }}: {{ city.TIME_OUT }}
                          </p>
                          <p v-if="city.TIME_STOP" class="compare-product__day-date">
                            {{ $t('program.time-parking') }}: {{ city.TIME_STOP }}
                          </p>
                          <p
                              v-if="city.DAY_PROGRAM"
                              class="compare-product__day-alert"
                              v-html="city.DAY_PROGRAM"
                          ></p>
                          <div v-if="city.MAIN_PROGRAM" class="compare-product__day-title">
                            <p
                                class="compare-product__day-title compare-product__day-title--programm"
                            >
                              {{ $t('program.main-program') }}
                            </p>
                            <div v-for="(program, index) in city.MAIN_PROGRAM" :key="index">
                              <p
                                  v-if="city.MAIN_PROGRAM.length > 1"
                                  class="compare-product__day-title"
                              >
                                {{ $t('program.variant') }} - {{ index + 1 }}
                              </p>
                              <div class="compare-product__day-title">
                                <ul>
                                  <li
                                      v-for="excursion in program"
                                      class="compare-product__day-alert"
                                  >
                                    {{ excursion.NAME.split('&quot;').join('"') }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                              v-if="city.ADDITIONAL_PROGRAM"
                              class="compare-product__day-title"
                          >
                            <p class="compare-product__day-title">
                              {{ $t('program.additional') }}
                            </p>
                            <div class="compare-product__day-title">
                              <ul>
                                <li
                                    v-for="excursion in city.ADDITIONAL_PROGRAM"
                                    class="compare-product__day-alert"
                                >
                                  {{ excursion.NAME }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.PRICES && tabs === '2'" class="compare-product__prices">
                    <div
                        v-if="item.DISPLAY_PROPERTIES.MOTOR_SHIP"
                        class="compare-product__item js-compare-item-3"
                    >
                      <p class="compare-product__name">
                        {{ item.DISPLAY_PROPERTIES.MOTOR_SHIP.NAME }}
                      </p>
                      <div class="compare-product__info">
                        <img
                            src="/local/client/icons/icon-compare-ship.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <a
                            :href="item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.DETAIL_PAGE_URL"
                            class="compare-product__link"
                        >
                          {{ item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.NAME }}
                        </a>
                        <p
                            class="compare-product__badge"
                            :class="`compare-product__badge--${item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.CLASS_CSS}`"
                        >
                          {{ item.DISPLAY_PROPERTIES.MOTOR_SHIP.VALUE.CLASS_NAME }}
                        </p>
                      </div>
                    </div>
                    <div
                        v-if="item.DISPLAY_PROPERTIES.DATE_START"
                        class="compare-product__item js-compare-item-1"
                    >
                      <p class="compare-product__name">
                        {{ item.DISPLAY_PROPERTIES.DATE_START.NAME }}
                      </p>
                      <div class="compare-product__info">
                        <img
                            src="/local/client/icons/icon-compare-calendar.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <p class="compare-product__date">
                          {{ item.DISPLAY_PROPERTIES.DATE_START.VALUE }}
                        </p>
                      </div>
                    </div>
                    <div
                        v-if="item.DISPLAY_PROPERTIES.ROUTE_CITIES"
                        class="compare-product__item js-compare-item-4"
                    >
                      <p class="compare-product__name">
                        {{ item.DISPLAY_PROPERTIES.ROUTE_CITIES.NAME }}
                      </p>
                      <div class="compare-product__info">
                        <p class="compare-product__address-short">
                          {{ item.DISPLAY_PROPERTIES.ROUTE_CITIES.VALUE.SHORT }}
                        </p>
                      </div>
                      <div class="compare-product__info">
                        <img
                            src="/local/client/icons/icon-compare-place.svg?inline"
                            alt=""
                            class="compare-product__icon"
                        />
                        <p class="compare-product__address">
                          {{ item.DISPLAY_PROPERTIES.ROUTE_CITIES.VALUE.FULL }}
                        </p>
                      </div>
                    </div>
                    <div
                        v-for="(price, index) in item.PRICES.decks"
                        :key="index"
                        class="compare-product__price"
                    >
                      <!-- <pre>{{price}}</pre> -->
                      <p class="compare-product__price-title">{{ price.name }} палуба</p>
                      <div class="compare-table">
                        <div
                            class="compare-table__tr"
                            :class="classCountSelectValue(item.PRICES.metaTariffs)"
                        >
                          <div class="compare-table__td"><p>Размещение</p></div>
                          <div
                              v-for="(item, index) in item.PRICES.metaTariffs"
                              v-show="
															selectedValue[1].includes(item.id) ||
															selectedValue[1].length == 0
														"
                              :key="index"
                              class="compare-table__td"
                          >
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div
                            v-for="(roomClasses, index) in price.roomClasses"
                            :key="index"
                            class="compare-table__trow"
                            :class="classCountSelectValue(item.PRICES.metaTariffs)"
                        >
                          <div class="compare-table__name">{{ roomClasses.name }}</div>
                          <div class="compare-table__tr">
                            <div class="compare-table__td is-prices">
                              <p
                                  v-for="(
																	accommodation, g
																) in roomClasses.accommodationTypes"
                                  v-show="
																	selectedValue[0].includes(accommodation.id) ||
																	selectedValue[0].length === 0
																"
                                  :key="g"
                                  class="compare-table__price"
                              >
                                {{ accommodation.name }}
                              </p>
                            </div>
                            <div
                                v-for="(col, i) in item.PRICES.metaTariffs"
                                v-show="
																selectedValue[1].includes(col.id) ||
																selectedValue[1].length === 0
															"
                                :key="i"
                                class="compare-table__td is-prices price-nowrap"
                            >
                              <div
                                  v-for="(
																	accommodation, q
																) in roomClasses.accommodationTypes"
                                  v-show="
																	selectedValue[0].includes(accommodation.id) ||
																	selectedValue[0].length === 0
																"
                                  :key="q"
                                  class="compare-table__price"
                              >
                                <p
                                    v-if="
																		roomClasses.tariffs[col.id] === undefined ||
																		roomClasses.tariffs[col.id].accommodations[
																			accommodation.id
																		] === undefined ||
																		+roomClasses.tariffs[col.id].accommodations[
																			accommodation.id
																		].price.value.trim()[0] === 0
																	"
                                >
                                  -
                                </p>
                                <p
                                    v-else
                                    v-html="
																		roomClasses.tariffs[col.id].accommodations[
																			accommodation.id
																		].price.value
																	"
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="scroll" class="compare__scroll swiper-scrollbar"></div>
      </div>
      <h2 v-show="!items">{{ $t('msg-null') }}.</h2>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.min.js'
import compareSelect from './compare-select.vue'
import {mapActions, mapState} from "vuex"

export default {
  components: {
    compareSelect,
  },
  data() {
    return {
      tabs: '1',
      different: 'n',
      swiper: null,
      selectedValue: [
        [1, 2],
        [2, 4],
      ],
      loading: false,
      sliderHeight: null,
      showFilter: false,
      flagInitSliderOne: true,
    }
  },
  computed: {
    ...mapState(['items', 'language', 'properties', 'filters', 'globalPreload']),
  },
  watch: {
    items() {
      setTimeout(() => {
        this.initSlider()
        this.flagInitSliderOne = false
      }, 1000)
    }
  },
  created() {
    const blockLoaderBefore = document.querySelector('.js-compare-loading')
    if (blockLoaderBefore) {
      blockLoaderBefore.classList.remove('loading')
    }
  },
  methods: {
    ...mapActions(['removeFromCompare', 'getCompare', 'clearCompareList', 'getPrices', 'SHOW_ALL']),
    comparePrices() {
      this.loading = true
      this.getPrices().then(() => {
        this.loading = false
        this.sliderHeight = this.$refs.container.scrollHeight + 100 + 'px'
      })
    },
    setLocation(curLoc) {
      try {
        history.pushState(null, null, curLoc ? '?' + curLoc : '/cruises/compare/')
      } catch (e) {}
    },
    removeCompare(id) {
      this.loading = true
      this.removeFromCompare(id).then(response => {
        if (this.tabs === '2' && response) {
          this.getCompare().then(() => {
            this.loading = false
          })
        } else {
          this.loading = false
        }
        this.setLocation(this.$store.state.url)
      })
    },
    clearCompare() {
      this.loading = true
      this.clearCompareList().then(() => {
        this.loading = false
      })
    },
    initSlider() {
      this.swiper = new Swiper(this.$refs.container, {
        wrapperClass: 'compare__wrapper',
        slideClass: 'compare__slide',
        simulateTouch: false,
        slidesPerView: 'auto',
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
        scrollbar: {
          el: this.$refs.scroll,
          draggable: true,
        },
        breakpoints: {
          767: {
            width: 300,
          },
          1024: {
            width: 300,
          },
        },
      })
      this.getHeight()
    },
    getHeight() {
      for (let i = 0; i < this.properties.length; i++) {
        let maxHeight = 0
        const compareItem = $(`.js-compare-item-${i}`)
        compareItem.each(function () {
          if ($(this).height() > maxHeight) {
            maxHeight = $(this).height()
          }
        })
        if (!compareItem.attr('data-height')) {
          compareItem.attr('data-height', maxHeight).height(maxHeight)
        }
      }
      let index = this.properties.findIndex(property => property.CODE === 'ROOM_CLASSES_INFO')
      if(index !== undefined) {
        $(`.js-compare-item-${index}`).height(255)
      }
      index = this.properties.findIndex(property => property.CODE === 'ROOMS')
      if(index !== undefined) {
        $(`.js-compare-item-${index}`).height(170)
      }
    },
    selected(data) {
      const result = []
      for (let i = 0; i < data.value.length; i++) {
        result.push(data.value[i].id)
      }
      this.$set(this.selectedValue, data.id, result)
      if (this.selectedValue[1].length === 0) {
        this.$set(this.selectedValue, 1, [2])
      }
      if (this.selectedValue[0].length === 0) {
        this.$set(this.selectedValue, 0, [1])
      }
      setTimeout(() => {
        this.sliderHeight = this.$refs.container.scrollHeight + 100 + 'px'
      }, 300)
    },
    toggle(el, className, height) {
      let animeHeight = height
      if ($(className).hasClass('hide')) {
        $(`${className} > .compare__item-toggle`).text(this.$t('turn')).addClass('full')
        $(className)
            .animate({height: $(className).data('height') + 30}, animeHeight)
            .removeClass('hide')
      } else {
        $(`${className} > .compare__item-toggle`).text(this.$t('btn-show-all')).removeClass('full')
        if (className === '.js-compare-item-5') {
          animeHeight = 293
        }
        $(className).animate({height: animeHeight}, 200).addClass('hide')
      }
    },
    classCountSelectValue(arr) {
      const arrIncludes = arr.filter(
          item => this.selectedValue[1].includes(item.id), // includes => true or false
      )
      const classEnd = {
        1: 'one',
        2: 'two',
        3: 'three',
      }
      return 'compare-table__tr--' + classEnd[arrIncludes.length]
    },
  },
}
</script>
