import ajaxLoad from "./ajax-load";
import lazy from './lazy';
import modals from "./modals";

let excursionsFilter = {
    run() {
        $(document).on('click', '[data-excursions-submit]', () => {
            this.updateFilter();
        });

        $(document).on('click', '[data-excursions-filter-mobile-show]', e => {
            e.preventDefault();
            this.hideFilter();
            this.updateFilter();
        });

        $(document).on('click', '[data-excursions-filter-mobile-reset]', e => {
            e.preventDefault();
            $('[data-excursions-filter]')[0].reset();
            $('select').selectric('refresh');
            this.updateFilter();
        });
    },

    updateFilter() {
        ajaxLoad.load({
            container: '[data-excursions-items]',
            url: window.location.origin + window.location.pathname + '?' + $('[data-excursions-filter]').serialize(),
            success: function () {
                lazy.update();
                modals.run();
            }
        }, true);
    },

    hideFilter() {
        $('html').toggleClass('locked');
        $('[data-filter]').toggleClass('active');
    }
};

module.exports = excursionsFilter;