let inputSecondName = {
	run() {

		if($('.js-second-name-checkbox .b-checkbox__input').length) {
			$('.js-second-name-checkbox .b-checkbox__input').each((index, item) => {
				initSecondNameRequired($(item));
			});
		}

		$(document).on('click', '.js-second-name-checkbox .b-checkbox__input', function () {
			toggleSecondNameRequired($(this));
		});

		function toggleSecondNameRequired($input) {
			const $wrapper = $input.closest('.b-form__item-second-name');
			const $inpSecondName = $wrapper.find('.b-form__input-second-name');

			if($input.is(':checked')) {
				$inpSecondName.addClass('disabled');
				$inpSecondName.attr('data-required', '');
				$inpSecondName.attr('tabindex', '-1');
				$inpSecondName.val('')
			} else {
				$inpSecondName.removeClass('disabled');
				$inpSecondName.attr('tabindex', '');
				$inpSecondName.attr('data-required', 'Y');
			}
		}

		function initSecondNameRequired($input) {
			const $wrapper = $input.closest('.b-form__item-second-name');
			const $inpSecondName = $wrapper.find('.b-form__input-second-name');

			if($input.is(':checked')) {
				$inpSecondName.addClass('disabled');
				$inpSecondName.attr('data-required', '');
				$inpSecondName.attr('tabindex', '-1');
			} else {
				$inpSecondName.removeClass('disabled');
				$inpSecondName.attr('data-required', 'Y');
				$inpSecondName.attr('tabindex', '');
			}
		}
	}
}

module.exports = inputSecondName;
