import lazy from './lazy';
import sliders from './slider/sliders';

let tabs = {
    run() {
        this.default();
    },

    default() {
        const isDesktop = $(window).innerWidth() > 1023;
        var firstVisit = true;

        if (firstVisit && !isDesktop) {
            firstVisit = false;
            $('[data-tab-btn].d-p-tabs-m__btn').each(function (ind, iter) {
                if (!$(this).hasClass('d-p-tabs-m__btn--filter')) {
                    $(this).removeClass('active');
                    $($('[data-tab-body].d-p-tabs__content')[ind]).removeClass('active');
                }
            });
        }
        if (isDesktop) {
            if ($('.d[data-tab-group]').length) {
                let dataTabGroup = $('.d[data-tab-group]');
                scrollToActiveOnInit(dataTabGroup);
                // $(window).on('scroll', () => setActiveOnScroll(dataTabGroup));
            } else if ($('.ship-d[data-tab-group]').length) {
                let dataTabGroup = $('.ship-d[data-tab-group]');
                // scrollToActiveOnInit(dataTabGroup);
                // $(window).on('scroll', () => setActiveOnScroll(dataTabGroup));
            }
        }

        $(document).on('click', '[data-tab-btn]', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const $this = $(e.target);
            const dataLink = $this.data('tab-btn');
            const $item = $(`[data-tab-body="${dataLink}"]`);

            const $wrap = $this.parents('[data-tab-group]:first');
            const $header = $this.parents('[data-tab-header]:first');
            const $links = $header.children('[data-tab-btn]');
            const $items = $wrap.find('[data-tab-content]:first > [data-tab-body]');

            const isDetail = $wrap.hasClass('d') || $wrap.hasClass('ship-d');

            if ($this.hasClass('active') && !isDetail) {
                return;
            } else {
                if ($this.attr('data-tab-filter')) {
                    return;
                } else {
					if (!$item.hasClass('active') && !!window.vym && !!$this.attr('ya-click-target')) {
						window.vym.reachGoal($this.attr('ya-click-target'))
					}
                    $links.removeClass('active');
                    $items.removeClass('active');
                    $item.addClass('active');
                    $this.addClass('active');
                    pluginsUpdate();

                    if (isDetail) {
                        let offset = 50;
                        if ($(window).innerWidth() <= 767) offset = 120;

                        $('html, body').stop().animate({scrollTop: $item.offset().top - offset}, 500);
                    } else if ($(e.target).hasClass('d-p-tabs-m__btn')) {
                        scrollToContent(isDesktop);
                    }
                }
            }
            if (isDetail && $header.hasClass('fixed') && ($(e.target).hasClass('d-p-tabs-m__btn') || $(e.target).parent('.d-p-tabs__inner.fixed')))
                scrollToContent(isDesktop);

            function pluginsUpdate() {
                lazy.update();
                sliders.shipDetailSlider();
                sliders.convSliders();
            }

            function scrollToContent(isDesktop) {
                if (isDesktop) {
                    $(window).scrollTop($('[data-tab-content]').offset().top - 20)
                } else {
                    $(window).scrollTop($('[data-tab-content]').offset().top - 46)
                }
            }
        })

		$('[js-cn-tabs-controller]').each(function() {
			const self = $(this);
			function containerResize() {
				const contentContainer = self.next('.d-p-tabs__content');
				const contentInner = contentContainer.children('.d-p-tabs__content-inner');

				if (!contentContainer.hasClass('d-p-tabs__content--collapsed')) {
					if (contentInner.outerHeight() > contentContainer.outerHeight()) {
						contentContainer.css('max-height', contentInner.outerHeight() + 'px')
					}
				}
			}
			new ResizeObserver(containerResize).observe($(this).next('.d-p-tabs__content').children('.d-p-tabs__content-inner')[0]);
		})

		$(document).on('click', '[js-cn-tabs-controller]', function(e) {
			function isShown(target) {
				const wt = $(window).scrollTop();
				const wh = $(window).height();
				const eh = target.outerHeight();
				const et = target.offset().top;

				if (wt + wh >= et && wt + wh - eh * 2 <= et + (wh - eh)){
					return true;
				} else {
					return false;
				}
			}

			//Код без сворачивания открытых блоков
			$(this).toggleClass('cn-tabs__controller--active');

			const contentContainer = $(this).next('.d-p-tabs__content');
			const contentInner = contentContainer.children('.d-p-tabs__content-inner');
			if (contentContainer.hasClass('d-p-tabs__content--expanded')) {
				contentContainer.css('max-height', '')
			}

			$(this).next('.d-p-tabs__content').toggleClass('d-p-tabs__content--expanded d-p-tabs__content--collapsed');

			if (!contentContainer.hasClass('d-p-tabs__content--collapsed')) {
				if (contentInner.outerHeight() > contentContainer.outerHeight()) {
					contentContainer.css('max-height', contentInner.outerHeight() + 'px')
				}
			}

			setTimeout(() => {
				let offset = 0;
				if ($('.d-p-tabs__content--expanded').length) {
					offset = $('.d-p-tabs__content--expanded').last().offset().top - 105
				} else {
					offset = $(this).offset().top - 62
				}

				if (!isShown($(this))) {
					window.scrollTo({
						top: offset,
						behavior: 'smooth'
					});
				}
			}, 420)
			//Код со сворачиванием открытых блоков

			/*if ($(this).hasClass('cn-tabs__controller--active')) {
				$(this).removeClass('cn-tabs__controller--active');
				$(this).next('.d-p-tabs__content').toggleClass('d-p-tabs__content--expanded d-p-tabs__content--collapsed');
			} else {
				$(this).toggleClass('cn-tabs__controller--active');
				$(this).next('.d-p-tabs__content').toggleClass('d-p-tabs__content--expanded d-p-tabs__content--collapsed');

				if ($('.cn-tabs__controller--active').length === 0) {
					$(this).toggleClass('cn-tabs__controller--active');
				} else {
					setTimeout(() => {
						$(this).toggleClass('cn-tabs__controller--active');
					}, 830)
				}
				$(this).next('.d-p-tabs__content').toggleClass('d-p-tabs__content--expanded d-p-tabs__content--collapsed');
				setTimeout(() => {
					var $thisController = $(this).get(0);
					$('[js-cn-tabs-controller]').not(this).each(function() {
						if ($thisController !== $(this) && $(this).hasClass('cn-tabs__controller--active')) {
							$(this).removeClass('cn-tabs__controller--active')
							$(this).next('.d-p-tabs__content').toggleClass('d-p-tabs__content--expanded d-p-tabs__content--collapsed');
						}
					})
				}, 410)

				setTimeout(() => {
					if (!isShown($(this))) {
						window.scrollTo({
							top: $(this).offset().top - 62,
							behavior: 'smooth'
						});
					}
				}, 820)
			}*/

			if ($(this).hasClass('cn-tabs__controller--active') && !!window.vym && !!$(this).attr('ya-click-target')) {
				window.vym.reachGoal($(this).attr('ya-click-target'))
			}

			if (window.blazy) {
				window.blazy.revalidate();
			}

		})

        $(document).on('change', '[data-tab-checkbox]', (e) => {
            const $this = $(e.target);
            const dataLink = $this.data('tab-checkbox');
            const $item = $(`[data-tab-body="${dataLink}"]`);

            const $wrap = $this.parents('[data-tab-group]:first');
            const $header = $this.parents('[data-tab-header]:first');
            const $links = $header.find('[data-tab-checkbox]');
            const $items = $wrap.find('[data-tab-content]:first > [data-tab-body]');

            const isDetail = $wrap.hasClass('d') || $wrap.hasClass('ship-d');
            if($this.hasClass('active') && !isDetail) {
                return;
            } else {
                if($this.attr('data-tab-filter')) {
                    return;
                } else {
                    $links.removeClass('active');
                    $items.removeClass('active');
                    $item.addClass('active');
                    $this.addClass('active');
                    pluginsUpdate();

                    if (isDetail) {
                        let offset = 20;
                        if($(window).innerWidth() <= 767) offset = 120;

                        $('html, body').stop().animate({scrollTop: $item.offset().top - offset}, 500);
                    } else if ($(e.target).hasClass('d-p-tabs-m__btn')) {
                        scrollToContent(isDesktop);
                    }
                }
            }

            if(isDetail && $header.hasClass('fixed') && ($(e.target).hasClass('d-p-tabs-m__btn') || $(e.target).parent('.d-p-tabs__inner.fixed')))
                scrollToContent(isDesktop);

            function pluginsUpdate() {
                lazy.update();
                sliders.shipDetailSlider();
                sliders.convSliders();
            }

            function scrollToContent(isDesktop) {
                if (isDesktop) {
                    $(window).scrollTop($('[data-tab-content]').offset().top - 20)
                } else {
                    $(window).scrollTop($('[data-tab-content]').offset().top - 46)
                }
            }
        })

        // метод дублирует логику checkTabs из файла tabFixOnScroll, но вызывает glitch эффект контента табов
        // function setActiveOnScroll(dataTabGroup) {
            // let scrollPosition = $(window).scrollTop();

            // const $items = dataTabGroup.find('[data-tab-content]:first > [data-tab-body]');
            // const $header = dataTabGroup.find('[data-tab-header]:first');
            // const $links = $header.children('[data-tab-btn]');

            // $items.each(function () {
            //     if ($(this).offset().top - 75 <= scrollPosition) {
            //         $items.removeClass('active');
            //         $links.removeClass('active');
            //         $(this).addClass('active');
            //         let tabIndex = $(this).data('tab-body');
            //         $header.find(`[data-tab-btn="${tabIndex}"]`).addClass('active');
            //     }
            // });
        // }

        function scrollToActiveOnInit(dataTabGroup) {
            const $header = dataTabGroup.find('[data-tab-header]:first');
            const $links = $header.children('[data-tab-btn]');

            if ($($links[0]).not('.active').length) {
                const $activeTabBody = $header.parents('[data-tab-group]:first').find('[data-tab-content]:first > .active[data-tab-body]');
                const scrollPosition = $activeTabBody.position().top - 20;
                $(window).scrollTop(scrollPosition);
            }
        }
    },

    mobileNav() {
        const $wrap = $('[data-nav]');
        const $activeBtn = $('[data-nav-btn].active');

        const minHeight = $activeBtn.innerHeight(); /* высота активного элемента */
        const maxHeight = minHeight * $('[data-nav-btn]').length; /* высота активного элемента */

        const animationTime = 300;

        let isOpen = false;

        $(document).on('click', '[data-nav-btn].active', (e) => {
            e.preventDefault();
            $wrap.animate({'height': isOpen ? minHeight : maxHeight}, animationTime); /* свернуть / развернуть */

            $activeBtn.toggleClass('opened');

            isOpen = !isOpen;

            $(document).off('click.closeNav');
            $(document).on('click.closeNav', (e) => {
                const $this = $(e.target);

                if (!$this.parents('.b-nav').length) { /* свернуть по клику вне меню */
                    $wrap.animate({'height': minHeight}, animationTime);

                    $activeBtn.removeClass('opened');

                    $(document).off('click.closeNav');
                    isOpen = false;
                }
            })
        })
    }
};

module.exports = tabs;
