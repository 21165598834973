let copy_detail = {
    run() {
        let copy = document.querySelectorAll('.booking__id-info')
        copy.forEach(item => {
            let content = item.querySelector('.booking__id-info_content')
            let copy_btn = item.querySelector('.booking__id-info_content-copy')
            let text_field = item.querySelector('.booking__id-info_content-id')
            let icon = item.querySelector('.booking__id-info_icon')
            icon.addEventListener('click', ()=>{
                icon.classList.toggle('active')
                content.classList.remove('copied')
            })
            copy_btn.addEventListener('click', () => {
                content.classList.add('copied')
                navigator.clipboard.writeText(text_field.textContent)
                    .then(() => {

                    })
                    .catch(err => {

                    });
            })
        })
    },
};

module.exports = copy_detail;
