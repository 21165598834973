import axios from "axios"
import cookie from "../cookie"
import {baseUrl} from "../config/config.axios.local"
import {conversionJsonToFormDataDef} from "../vue/orderDetail/utils/core/conversionJsonToFormData"
import createAuthRefreshInterceptor from "axios-auth-refresh";

export class CrsClient {
    constructor() {
        this.service = axios.create()
        this.service.interceptors.request.use(async config => {
            const locale = document.documentElement.lang !== "ru" ? "eu" : "ru"
            config.baseURL = `${baseUrl}${locale}`
            config.headers = {Authorization: `Bearer ${await cookie.getUserToken()}`}
            return config
        }, (error) => {
            return Promise.reject(error)
        })
        createAuthRefreshInterceptor(
            this.service,
            this.refreshRequest,
            {
                statusCodes: [401, 403],
            }
        )
        createAuthRefreshInterceptor(
            this.service,
            () => {
                document.dispatchEvent(new Event("crsNotAvailable"))
            },
            {
                statusCodes: [500],
            }
        )
    }

    authoriseAs(userID) {
        return axios.post(`${baseUrl}security/authorise-as`, conversionJsonToFormDataDef({userID}), {
            headers: {Authorization: `Bearer ${cookie.getCookie("user_token")}`}
        })
    }

    securityRefresh(refreshToken) {
        return axios.post(`${baseUrl}security/refresh`, conversionJsonToFormDataDef({refreshToken}))
    }

    async refreshRequest(failedRequest) {
        const _this = new CrsClient()
        let accessToken
        const cookieName = {
            user_refresh_token: "user_token",
            website_refresh_token: "website_token"
        }
        for (const name in cookieName) {
            const refreshToken = cookie.getCookie(name)
            if (refreshToken) {
                try {
                    const res = await _this.securityRefresh(refreshToken)
                    accessToken = res.data.result.accessToken.token
                    cookie.setCookie(cookieName[name], accessToken)
                    cookie.setCookie(name, res.data.result.refreshToken.token)
                    break
                } catch (ex) {
                    cookie.deleteCookie(cookieName[name])
                    cookie.deleteCookie(name)
                    console.error(ex)
                }
            }
        }
		if(!accessToken) {
			cookie.deleteCookie('website_token')
			accessToken = await cookie.getUserToken()
		}
        if (accessToken) {
            failedRequest.response.config.headers["Authorization"] = `Bearer ${accessToken}`
            return Promise.resolve();
        }
    }
}
