import PerfectScrollbar from "perfect-scrollbar";

let perfectScroll = {
	run() {
		this.scroll();
		window.addEventListener("resize", this.scroll);
	},

	scroll() {
		var ps;
		var pss;
		if ($("[data-perfect-scroll]").length) {
			$("[data-perfect-scroll]").each(function () {
				let self = $(this)[0];
				ps = new PerfectScrollbar(self, {
					wheelSpeed: 0.5,
					wheelPropagation: true,
				});
				ps.update();
			});
		}

		if ($("[data-perfect-scroll-menu-top]").length) {
			const scrolls = [];
			$("[data-perfect-scroll-menu-top]").each(function () {
				let self = $(this)[0];
				ps = new PerfectScrollbar(self, {
					wheelSpeed: 0.5,
					wheelPropagation: true,
				});
				scrolls.push(ps);
			});
			document.addEventListener("top-menu-opened", () => {
				scrolls.forEach((scroll) => scroll.update());
			});
		}

		if ($("[data-perfect-scroll-author-achive]").length) {
			$("[data-perfect-scroll-author-achive]").each(function () {
				let self = $(this)[0];
				pss = new PerfectScrollbar(self, {
					wheelSpeed: 0.5,
					wheelPropagation: true,
					useBothWheelAxes: true,
					suppressScrollY: true,
					scrollingThreshold : 0,
				});
				pss.update();
			});
		}
	},
};

module.exports = perfectScroll;
