var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-flex" }, [
    _vm.discounts.length
      ? _c("div", { staticClass: "app-disc__container" }, [
          _c("h2", { staticClass: "app-heading" }, [
            _vm._v(_vm._s(_vm.$t("discounts_ship"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-disc" },
            _vm._l(_vm.discounts, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "app-disc__item",
                  class: {
                    "app-disc__item--booking": item.name === "Скидка ID/AD",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openModalDiscount(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "app-disc__item-image" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "app-disc__item-content" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(item.name) +
                        "\n\t\t\t\t\t" +
                        _vm._s(item.percent) +
                        "%\n\t\t\t\t"
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "app-disc__conditions",
              attrs: { href: "/special-offers/discount/" },
            },
            [_vm._v(_vm._s(_vm.$t("discounts_ship_more")))]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.specials.length
      ? _c("div", { staticClass: "app-spec__container" }, [
          _c("h2", { staticClass: "app-heading" }, [
            _vm._v(_vm._s(_vm.$t("specials_ship"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-spec" },
            _vm._l(_vm.specials, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "app-spec__item",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openModal(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "app-spec__item-image" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "app-spec__item-content" }, [
                    _vm._v("\n\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t"),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.openedDiscountModal
      ? _c("div", { staticClass: "disc-modal__wrapper" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeModalDiscount,
                  expression: "closeModalDiscount",
                },
              ],
              staticClass: "disc-modal__modal",
            },
            [
              _c("div", { staticClass: "disc-modal__modal-header" }, [
                _c("div", { staticClass: "disc-modal_modal-info" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "disc-modal__modal-close",
                    on: { click: _vm.closeModalDiscount },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { "clip-path": "url(#clip0_437_16670)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: "M6.26264 8L0 1.7775L1.78896 0L9.84056 8L1.78896 16L0 14.2225L6.26264 8Z",
                                fill: "#9A243D",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M9.73736 8L16 1.7775L14.211 0L6.15944 8L14.211 16L16 14.2225L9.73736 8Z",
                                fill: "#9A243D",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", [
                          _c("clipPath", { attrs: { id: "clip0_437_16670" } }, [
                            _c("rect", {
                              attrs: {
                                width: "16",
                                height: "16",
                                fill: "white",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "disc-modal__modal-body" }, [
                _c("div", { staticClass: "disc-modal__modal-body-title" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.selectedDiscountItem.name) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "disc-modal__modal-body-percent" }, [
                  _vm._v("\n\t\t\t\t\tдо "),
                  _c(
                    "span",
                    { staticClass: "disc-modal__modal-body-percent-value" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.selectedDiscountItem.percent) +
                          " %\n\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "disc-modal__modal-body-desc" }, [
                  _c(
                    "div",
                    { staticClass: "disc-modal__modal-body-annotation" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.selectedDiscountItem.annotation) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "disc-modal__modal-body-condition" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tДанная скидка не предоставляется вместе с другими видами скидок.\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "disc-modal__modal-body-person" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\tПредоставляется частным лицам и агентствам\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.opened
      ? _c("div", { staticClass: "spec-modal__wrapper" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeModal,
                  expression: "closeModal",
                },
              ],
              staticClass: "spec-modal__modal",
            },
            [
              _c("div", { staticClass: "spec-modal__modal-header" }, [
                _c("div", { staticClass: "spec-modal_modal-info" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "spec-modal__modal-close",
                    on: { click: _vm.closeModal },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { "clip-path": "url(#clip0_437_16670)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: "M6.26264 8L0 1.7775L1.78896 0L9.84056 8L1.78896 16L0 14.2225L6.26264 8Z",
                                fill: "#9A243D",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M9.73736 8L16 1.7775L14.211 0L6.15944 8L14.211 16L16 14.2225L9.73736 8Z",
                                fill: "#9A243D",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", [
                          _c("clipPath", { attrs: { id: "clip0_437_16670" } }, [
                            _c("rect", {
                              attrs: {
                                width: "16",
                                height: "16",
                                fill: "white",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "spec-modal__modal-body" }, [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.dataModal) } }),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }