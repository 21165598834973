$(function () {

    function replaceText(data){
        $('.tooltip .path__info').each(function(){
            $(this).html($(this).attr(data));
        });
    }

    $('.list-item__head').on('click', (evt) => {
        const $inner = $(evt.currentTarget).closest('.path__inner');
        const $allOpener = $inner.find('.path__info-btn.close');

        $(evt.currentTarget).closest('.path__list-item').toggleClass('opened');

        $allOpener.each(function (){
            if($inner.find('.path__list-item.opened').length && $(this).attr('data-status') == 'hide')
            {
                $(this).html($(this).attr('data-hide-text'));
                $(this).attr('data-status', 'show');
                replaceText('data-hide-text')
            }
            else if(!$inner.find('.path__list-item.opened').length && $(this).attr('data-status') == 'show')
            {
                $(this).html($(this).attr('data-show-text'));
                $(this).attr('data-status', 'hide');
                replaceText('data-show-text')
            }
        })
    });

    const PathInfoBtnController = function() {
        this.$btns = $('.path__info-btn.close');

        this.run = function () {
            this.$btns.on('click', (evt) => {
                this.onClickPathInfo({
                    evt: evt,
                });
            })
        };

        this.onClickPathInfo = function ({evt}) {
            const status = $(evt.currentTarget).attr('data-status');
            const showText = $(evt.currentTarget).attr('data-show-text');
            const hideText = $(evt.currentTarget).attr('data-hide-text');

            if(typeof status != 'string')
            {
                console.error(`Должен быть указан статус в атрибуте data-status - ${status}, ${evt.currentTarget}`);
                return false;
            }

            if(typeof showText != 'string')
            {
                console.error(`Должен быть атрибут data-show-text - ${showText}, ${evt.currentTarget}`);
                return false;
            }

            if(typeof hideText != 'string')
            {
                console.error(`Должен быть атрибут data-hide-text - ${hideText}, ${evt.currentTarget}`);
                return false;
            }

            switch (status) {
                case 'show':
                    $(evt.currentTarget).closest('.path__inner').find('.path__list-item.opened').removeClass('opened');
                    $(evt.currentTarget).html(showText);
                    $(evt.currentTarget).attr('data-status', 'hide');
                    $('.path__info-btn.close').each(function(){
                        $(this).attr('data-status', 'hide');
                    });
                    $('.tooltip .path__info').each(function(){
                        $(this).html($(this).attr('data-show-text'));
                    });
                    break;
                case 'hide':
                    $(evt.currentTarget).closest('.path__inner').find('.path__list-item').addClass('opened');
                    $(evt.currentTarget).html(hideText);
                    $(evt.currentTarget).attr('data-status', 'show');
                    $('.path__info-btn.close').each(function (){
                        $(this).attr('data-status', 'show');
                    });
                    $('.tooltip .path__info').each(function(){
                        $(this).html($(this).attr('data-hide-text'));
                    });
                    break;
                default:
                    console.error(`Неизвестный статус в data-status - ${status}, ${evt.currentTarget}`);
            }

        }

    };

    const infoBtnsPath = new PathInfoBtnController();
    infoBtnsPath.run();
});