export const conversionJsonToFormDataDef = function (jsonData) {
	const result = new FormData()
	for (const key in jsonData) {
		result.append(key, jsonData[key])
	}
	return result
}

export const conversionJsonToFormData = function (jsonData) {
	const result = new FormData()
	for (const key in jsonData) {
		if (jsonData[key]) {
			result.append(key, jsonData[key])
		}
	}
	return result
}

export const conversionJsonToFormDataForPassenger = function (
	jsonData,
	type,
	haveCrm = false,
	mbId = false,
	sId = false,
) {
	const result = new FormData()
	let arrDate
	for (const key in jsonData) {
		if (jsonData[key]) {
			switch (key) {
				case 'documentType':
					result.append(key, jsonData[key].id)
					break
				case 'nationality':
					result.append(key, jsonData[key].id)
					break
				case 'birthDate':
				case 'documentIssueDate':
					arrDate = jsonData[key].split('.')
					const timeZoneConst = (new Date().getTimezoneOffset() / 60 - 1) * 3600000
					result.append(
						key,
						String(
							((new Date(Number(arrDate[2]), Number(arrDate[1]) - 1, Number(arrDate[0])).getTime() -
								timeZoneConst) /
								1000) |
								0,
						),
					)
					break
				case 'sex':
					result.append(key, jsonData[key] === 'male' || jsonData[key] === 1 ? '1' : '0')
					break
				case 'phone':
					result.append(key, jsonData[key].replace(/\D/gi, ''))
					break
				case 'crmID':
					if (haveCrm) {
						break
					} else {
						result.append(key, jsonData[key])
						break
					}
				default:
					result.append(key, jsonData[key])
			}
		}
	}
	if (type) {
		result.append('type', '4') // TODO: ПОКА НЕ РАБОТАЕТ СПРАВОЧНИК - СТАТИЧНО
	}
	return result
}
