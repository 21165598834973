<template>
	<div class="compare-select">
		<div class="compare-select__header">
			<p class="compare-select__title">{{ label }}:</p>
			<div class="compare-select__options">
				<multiselect
					v-model="value"
					:options="items"
					:multiple="true"
					:close-on-select="true"
					:clear-on-select="false"
					:preserve-search="false"
					placeholder=""
					label="name"
					track-by="name"
					:preselect-first="false"
					:searchable="false"
					:disabled="allowedCount"
					@input="selected"
				>
					<template slot="selection" slot-scope="{values, search, isOpen}">
						<span class="multiselect__single"> {{ name }} </span>
					</template>
				</multiselect>
			</div>
		</div>
		<div class="compare-select__body">
			<div v-for="(item, index) in value" :key="index" class="compare-select__item">
				<p class="compare-select__name">{{ item.name }}</p>
				<button class="compare-select__remove" @click="removeOptions(item)"></button>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
	components: {
		Multiselect,
	},
	props: ['options', 'name', 'label', 'id', 'values'],
	data() {
		return {
			value: [],
			items: [],
		}
	},
	computed: {
		allowedCount() {
			return this.value.length >= 2 + this.id
		},
	},
	mounted() {
		for (const val in this.options) {
			this.items.push(this.options[val])
		}
		this.values.forEach(item => {
			this.value.push(this.items.find(a => a.id === item))
		})
	},
	methods: {
		removeOptions(item) {
			const index = this.value.indexOf(item)
			this.value.splice(index, 1)
			this.$emit('select', {id: this.id, value: this.value})
		},
		selected() {
			this.$emit('select', {id: this.id, value: this.value})
		},
	},
}
</script>
