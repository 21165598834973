import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const globalTooltips = {
	run() {
		const cruiseTooltips = document.querySelectorAll('[data-tippy-cruise]');
		cruiseTooltips.forEach(elem => {
			tippy(elem, {
				content: elem.getAttribute('data-tippy-cruise'),
				animation: 'fade',
				placement: 'top'
			});
		})
	},
};

module.exports = globalTooltips;
