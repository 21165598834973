module.exports = {
	$wrFrame: $('.js-p-v-wr-frame'),
	$img: $('.js-p-v-frame-img'),
	$btnPlay: $('.js-p-v-btn-play'),
	$frame: $('.js-p-v-frame'),
	url: '',

	run() {
		this.$img.on('click', () => {
			this.getUrl()
			this.addUrlToFrame()

			this.togglePreviewToFrame()
		})
	},

	getUrl() {
		this.url = this.$frame.data('url')

		if (this.url.includes('youtube')) {
			const youtubeId = this.youTubeGetID(this.url)
			this.url = `https://www.youtube.com/embed/${youtubeId}`
		}
	},

	addUrlToFrame() {
		this.$frame.attr('src', this.url)
	},

	hidePreview() {
		this.$img.addClass('hidden')
		this.$btnPlay.addClass('hidden')
		this.$wrFrame.removeClass('has-img')
	},

	showFrame() {
		this.$frame.removeClass('hidden')
	},

	togglePreviewToFrame() {
		this.hidePreview()
		this.showFrame()
	},

	youTubeGetID(url) {
		url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
		return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
	},
}
