var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "compare-select" }, [
    _c("div", { staticClass: "compare-select__header" }, [
      _c("p", { staticClass: "compare-select__title" }, [
        _vm._v(_vm._s(_vm.label) + ":"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "compare-select__options" },
        [
          _c("multiselect", {
            attrs: {
              options: _vm.items,
              multiple: true,
              "close-on-select": true,
              "clear-on-select": false,
              "preserve-search": false,
              placeholder: "",
              label: "name",
              "track-by": "name",
              "preselect-first": false,
              searchable: false,
              disabled: _vm.allowedCount,
            },
            on: { input: _vm.selected },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function (ref) {
                  var values = ref.values
                  var search = ref.search
                  var isOpen = ref.isOpen
                  return [
                    _c("span", { staticClass: "multiselect__single" }, [
                      _vm._v(" " + _vm._s(_vm.name) + " "),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "compare-select__body" },
      _vm._l(_vm.value, function (item, index) {
        return _c("div", { key: index, staticClass: "compare-select__item" }, [
          _c("p", { staticClass: "compare-select__name" }, [
            _vm._v(_vm._s(item.name)),
          ]),
          _vm._v(" "),
          _c("button", {
            staticClass: "compare-select__remove",
            on: {
              click: function ($event) {
                return _vm.removeOptions(item)
              },
            },
          }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }