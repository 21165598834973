let lazyPrint = {
    run() {
        $(document).ready(function() {

            var print_mq = window.matchMedia('print');
            print_mq.addListener(function (mql) {

                if (mql.matches) {
                    /*работает в яндексе и опере*/
                    $("img.lazy").each(function(){
                        if ($(this).attr('data-src-small')) {
                            $(this).attr('src',$(this).attr('data-src-small'));
                        } else {
                            $(this).attr('src',$(this).attr('data-src'));
                        }
                    });
                    window.onbeforeprint = function () {
                        if ($(this).attr('data-src-small')) {
                            $(this).attr('src',$(this).attr('data-src-small'));
                        } else {
                            $(this).attr('src',$(this).attr('data-src'));
                        }
                    }
                } else {
                }
            });

        });
    },
};

module.exports = lazyPrint;
