import intlTelInput from 'intl-tel-input';

const simpleForm = {
    run() {
        this.$form = $('[data-simple-form]');
        this.iti = null;

        if(!this.$form.length) return false;

        this.$form.each((index, form) => {
            this.initSubmit({
                $form: $(form),
            });
        });
    },

    initSubmit({$form}) {
        const $btn = $form.find('[data-simple-form-submit]');
        const $personalInfo = $form.find('[data-simple-form-personal-info]');
        const $phone = $form.find('[data-iti-phone]');

        this.checkPersonalInfo({
            $info: $personalInfo,
            $btn: $btn,
        });
        
        this.initPhone({$phone: $phone});

        $personalInfo.on('change', (evt) => {
            this.checkPersonalInfo({
                $info: $(evt.currentTarget),
                $btn: $btn,
            });
        });

        // $form.on('submit', (evt) => {
        //     evt.preventDefault();
        //     let isValid = this.validateFields({$form: $(evt.currentTarget)});
        // });
    },

    checkPersonalInfo({$info, $btn}) {
        $info.is(":checked") ? $btn.removeClass('disabled') : $btn.addClass('disabled');
    },

    validateFields({$form}) {
        const $fields = $form.find('.simple-form__element input');
        let result = [];
        
        $fields.each((index, field) => {
           switch($(field).attr('data-validate-type')) {
               case 'required':
                   result.push({
                       $field: $(field),
                       isValid: this.checkRequired({$field: $(field)}),
                   });
                   break;
               case 'email':
                   result.push({
                       $field: $(field),
                       isValid: this.checkEmail({$field: $(field)}),
                   });
                   break;
               case 'phone':
                   result.push({
                       $field: $(field),
                       isValid: this.checkPhone({$field: $(field)}),
                   });
                   break;
           } 
        });

        this.initErrors({validation: result});
        this.finishSubmit({$form: $form, validation: result});
    },

    finishSubmit({$form, validation}) {
        let result = true;

        validation.forEach((item) => {
            if(!item.isValid) {
                result = false;
                return;
            }
        });

        if(result) $form.submit();
    },

    initErrors({validation}) {
        validation.forEach((item) => {
            if(item.isValid) {
                this.hideError({$fieldBox: item.$field.parent()})
            } else {
                this.showError({$fieldBox: item.$field.parent()})
            }
        });
    },

    showError({$fieldBox}) {
        const $error = $fieldBox.find('[data-error]');
        $error.addClass('show');
    },

    hideError({$fieldBox}) {
        const $error = $fieldBox.find('[data-error]');
        $error.removeClass('show');
    },

    checkRequired({$field}) {
        return !!$field.val().length;
    },

    checkEmail({$field}) {
        let re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re_email.test($field.val());
    },

    checkPhone({$field}) {
        return !!$field.val().length;
    },

    initPhone({$phone}){
        // this.iti = intlTelInput($phone[0], {
        //     autoPlaceholder: "polite",
        //     initialCountry: "ru",
        //     defaultCountry: 'ru',
        //     preferredCountries: ['ru'],
        //     placeholderNumberType: "MOBILE",
        //     customPlaceholder: function(Placeholder, CountryData){
        //         var Mask = "";
        //         if(CountryData.name == "Russia (Россия)"){
        //             Placeholder = "8 (912) 345-67-89";
        //             Mask = Placeholder.replace(/\d/g,"0");
        //             Mask = Mask.replace(/^0/g,"7");
        //             Placeholder = Placeholder.replace(/^8/g,"7");
        //         } else {
        //             Mask = CountryData.dialCode+" "+Placeholder.replace(/\d/g,"0");
        //             Placeholder = CountryData.dialCode+" "+Placeholder;
        //         }
        //         $phone.val("");
        //         $phone.unmask().mask(Mask);
        //         return Placeholder;
        //     },
        //     utilsScript: "/local/templates/vodohod/assets/js/intl-tel-input/utils.js",
        // });
    }
};

module.exports = simpleForm;