import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
	const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

const dateTimeFormats = {
	en: {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric',
		},
	},
}
const numberFormats = {
	en: {
		currency: {
			style: 'currency',
			currency: 'USD',
		},
	},
}

const element = document.querySelector('#compare-sidebar')
let lang = 'ru'
if (element) {
	lang = element.dataset.language
}

export default new VueI18n({
	locale: lang,
	fallbackLocale: lang,
	messages: loadLocaleMessages(),
	dateTimeFormats,
	numberFormats,
})
