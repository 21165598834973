var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items && _vm.items.length > 0
    ? _c(
        "a",
        {
          staticClass: "btn-header-compare btn btn--red btn-bdrs",
          attrs: { href: "javascript:;" },
          on: { click: _vm.toggleSidebar },
        },
        [
          _vm._v("СРАВНЕНИЕ\n\t"),
          _c("span", [
            _vm._v("(" + _vm._s(_vm.items ? _vm.items.length : 0) + ")\n\t\t"),
            _c("span", { staticStyle: { display: "none" } }, [
              _vm._v(_vm._s(_vm.status)),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }