let inputs = {
	run() {
		this.placeholder()
		this.coincidesWith()
	},

	placeholder() {
		$('[data-f-item]').each(function () {
			const $this = $(this)
			const $field = $this.find('[data-f-field]')
			const $label = $this.find('[data-f-label]')
			const activeStatic = $this.data('f-item') === 'active'
			const hasValue = (function () {
				if ($field.is('select')) return $field.find('option:selected').length > 0 || !$field.is('[multiple]')
				return $field.val() !== ''
			})()

			if (activeStatic) return

			if ($field.prop('disabled') === true) {
				$label.addClass('disabled')
			}

			if (hasValue) $label.addClass('active active--origin')
			else $label.removeClass('active active--origin')

			if ($label.height() > parseInt($label.css('lineHeight')))
				$label.parents('.b-form').addClass('b-form--double-lables')

			$field.on('focus', function () {
				$label.addClass('active')
				$this.addClass('item-active')
			})

			$field.on('blur', function () {
				if ($field.val() === '') {
					$label.removeClass('active active--origin')
					$this.removeClass('item-active')
				}
			})

			/* select */
			$field.on('change', function () {
				if ($field.val() === '') $label.removeClass('active active--origin')
				else $label.addClass('active')
			})

			$this.addClass('init')
		})
	},

	coincidesWith() {
		$('[data-coincide]').each(function () {
			const $this = $(this) /* чекбокс */
			const $wrap = $this.parents('.b-form__item--checkbox')
			const $label = $wrap.find('[data-f-label]')
			const $input = $wrap.find('input[type="text"]')
			const $baseInput = $(`[name="${$this.data('coincide')}"]`)
			const setVal = value => {
				if (value !== '') $label.addClass('active')
				else $label.removeClass('active')

				$input.val(value)
			}

			$this.on('change', () => {
				const state = (() => $this.is(':checked'))()
				const baseValue = $baseInput.val()

				if (state) {
					setVal(baseValue)
					$baseInput.on('input.input', e => setVal($(e.target).val()))
				} else {
					$input.val('')
					$baseInput.off('input.input')
					$label.removeClass('active')
				}

				$input.prop('readonly', state)
			})
		})
	},
}

module.exports = inputs
