const galleryOpener = {
    run() {
        if($('[data-open-gallery]').length) {
            $('[data-open-gallery]').on('click', (evt) => {
                evt.preventDefault();
                this.onOpenerClick();
            });
        }
    },

    onOpenerClick() {
        const $galleryFirstActiveLink = $('[data-slider="detail-images"] .swiper-slide-active:first a');

        if($galleryFirstActiveLink.length) {
            $galleryFirstActiveLink.click();
        }
    },
};

module.exports = galleryOpener;
