import validation from './validation';
import crsCruise from './rest/crs/crsCruise';

let waitingForm = {
    run() {
        let self = this;
		$(document).on('click', '.js-btn-open-waiting-popup', (e) => {
			$.magnificPopup.open({
				el: e.currentTarget,
				items: {
					type: 'ajax',
					src: '/bitrix/services/main/ajax.php?mode=class&c=extyl%3Awaiting.list&action=getForm'
				},
				ajax: {
					cache: false,
					type: 'GET'
				},
				callbacks: {
					beforeOpen: function() {
						self.isDetail = false;
						const btn = this.st.el
						self.cruiseId = parseInt(btn.getAttribute('data-cruise-id'))
						self.deckId = parseInt(btn.getAttribute('data-deck-id')) || null
						self.roomClassId = parseInt(btn.getAttribute('data-room-class-id')) || null
						if(btn.hasAttribute('data-room-class-id')) {
							self.isDetail = true;
						}
						self.loadCruiseData();
					},
					ajaxContentAdded: function() {
						self.init();
					}
				}
			})
		})
    },

    init() {
        const self = this
		const waitingSubmitBtn = $('.js-waiting-form__input-submit')
        self.decksList = document.getElementById('decks-list') || null;

        $('.js-waiting-list__close,.js-waiting-response__btn').on('click', function () {
            $('.js-waiting-body').show();
            $('.js-wait-respose-success').hide();
            $('.js-wait-respose-fail').hide();
			waitingSubmitBtn.attr('disabled', false);
        });

        $('.js-waiting-form').submit(function (e) {
            e.preventDefault();
			waitingSubmitBtn.attr('disabled', true);

            let fieldFlag = true;
            let formItem = $(this).find('.waiting-form__label--list').closest('[data-f-item]');
            let errorItems = formItem.find('[data-error-item]');

            // проверяем выбрано ли "все каюты"
            const sendAll = $(this).find('.waiting-form__label--full input:checked').length > 0;

            // проверяем есть ли выбранные каюты ИЛИ выбрано все
            if (!sendAll && !$(this).find('.waiting-form__input--list input:checked').length) {
                fieldFlag = false;
            }

            // выводим/прячем ошибку о каютах (по обстоятельствам)
            if (fieldFlag) {
                $(this).find('.waiting-form__label--full').removeClass('error');
                $(this).find('.waiting-form__input--list').closest('.waiting-form__label').removeClass('error');
                $(this).find('.waiting-form__input--list .waiting-form__label').removeClass('error');
                errorItems.addClass('hidden');
            } else {
                $(this).find('.waiting-form__label--full').addClass('error');
                $(this).find('.waiting-form__input--list').closest('.waiting-form__label').addClass('error');
                $(this).find('.waiting-form__input--list .waiting-form__label').addClass('error');
                errorItems.removeClass('hidden');
            }

            // валидация оставшейся формы и остановка отправки
            if (!validation.validate($(this)) || !fieldFlag) {
                $('.js-waiting-form__input-submit').attr('disabled', false);
                return false;
            }

            // формируем данные на отправку
            let data = {
                fullName: $(this).find('input[name="user-name"]').val(),
                email: $(this).find('input[name="email"]').val(),
                cruiseId: self.cruiseId,
            };

            if (!sendAll) {
                data.cabinsId = {};
                // выбираем выбранные каюты
                $(this).find('.waiting-form__label--room input:checked').each(function() {
                    let deck = $(this).closest('ul').parent().find('.waiting-form__label--deck input');
                    if (!data.cabinsId[deck.val()]) {
                        data.cabinsId[deck.val()] = {
                            rooms: {},
                        };
                    }
                    data.cabinsId[deck.val()].rooms[$(this).val()] = 1;
                });
            }

            BX.ajax.runComponentAction('extyl:waiting.list', sendAll ? 'addCruiseToWaitList' : 'addCabinClassToWaitList', {
                mode: 'class',
                data,
            }).then(function () {
                $('.js-waiting-body').hide();
                $('.js-wait-respose-success').show();
                $('.js-wait-respose-fail').hide();
                $('.js-waiting-form__input-submit').attr('disabled', false);
            }, function () {
                $('.js-waiting-body').hide();
                $('.js-wait-respose-success').hide();
                $('.js-wait-respose-fail').show();
                $('.js-waiting-form__input-submit').attr('disabled', false);
            });
        });

        document.querySelector('.waiting-form__label--full input').addEventListener('click', evt => {
            if (evt.target.checked) {
                self.decksList.classList.add('disabled');
                self.decksList.querySelectorAll('input').forEach(input => {
                    input.disabled = true;
                })
            } else {
                self.decksList.classList.remove('disabled');
                self.decksList.querySelectorAll('input').forEach(input => {
                    input.disabled = false;
                })
            }
        });
    },

    loadCruiseData() {
        let self = this;
        crsCruise.roomTariffs(this.cruiseId, {
            'with': 'decks/svgScheme'
        }).then(response => {
            // выводим только те палубы, где есть недоступные каюты
            const data = response.data.data.decks || [];
            self.decks = data
                //выбираются только те палубы, у классов которых есть тарифы на размещение
                .filter((deck) => deck.roomClasses.some((roomClass) => roomClass.tariffs.some((tariff) => !!tariff.accommodations.length)))
                .map((deck) => {
                    return {
                        id: deck.id,
                        name: deck.name,
                        roomClasses: deck.roomClasses
                            //выборка тех классов кают у которых есть варианты размещения и хотя бы один тариф + нет свободных кают
                            .filter((roomClass) => !!roomClass.accommodationTypes && roomClass.accommodationTypes.length && roomClass.tariffs.some((tariff) => tariff.accommodations.length) && !roomClass.availability)
                            .map((roomClass) => {
                                return {
                                    id: roomClass.id,
                                    name: roomClass.name,
                                    tariffs: roomClass.tariffs, // нужно для поиска дешёвой каюты
                                }
                            })
                    }
                });
            self.showDecks();
        })
    },

    showDecks() {
        let self = this;
        // ждём пока загрузится окно чтобы было куда выводить список
        let waitPopup = setInterval(() => {
            if (!self.decksList) return;
            // если окно загрузилось - выводим сам список и вешаем события
            clearInterval(waitPopup);
            // формируем список палуб/кают для выбора
            let list = '<ul>';
            let deckId = self.deckId;
            let roomId = self.roomClassId;
            if (!deckId || !roomId) {
                // если нет предвыбора каюты - ищем вариант с самым дешевым двухместным размещением
                let minPrice = 0;
                self.decks.filter(deck => {
                    deck.roomClasses.forEach(room => {
                        let tariff = room.tariffs.find(tariff => tariff.id === 2 /* 2 - Взрослый тариф */);
                        let accomodation = tariff.accommodations.find(accommodation => accommodation.id === 2 /* 2 - Двухместное размещение */);
                        if (accomodation) {
                            if (accomodation.price.discountedValue && (accomodation.price.discountedValue < minPrice || !minPrice) ) {
                                minPrice = accomodation.price.discountedValue;
                                deckId = deck.id;
                                roomId = room.id;
                            } else if (accomodation.price.value && (accomodation.price.value < minPrice || !minPrice) ) {
                                minPrice = accomodation.price.value;
                                deckId = deck.id;
                                roomId = room.id;
                            }
                        }
                    });
                })
            }
            // пока убираем предвыборную каюту
            if(!self.isDetail) {
                deckId = null;
                roomId = null;
            }
            // погнали по палубам
            self.decks.forEach(deck => {
                // если у палубы нет кают - пропускаем
                if (!deck.roomClasses || !deck.roomClasses.length) return;
                // выводим название палубы
                list += `<li>
                    <label class="waiting-form__label waiting-form__label--deck">
                        <input
                            type="checkbox"
                            value="${deck.id}"
                            class="waiting-form__input-checkbox"
                            data-skip-validation
                        />
                        <span class="waiting-form__checkbox-box"></span>
                        <span class="waiting-form__checkbox-text">${deck.name} палуба</span>
                    </label>
                    <ul>`
                deck.roomClasses.forEach(room => {
                    list += `<li>
                        <label class="waiting-form__label waiting-form__label--room">
                            <input
                                type="checkbox"
                                value="${room.id}"
                                class="waiting-form__input-checkbox"
                                data-skip-validation
                                ${deckId === deck.id && roomId === room.id ? ' checked="checked"' : ''}
                            />
                            <span class="waiting-form__checkbox-box"></span>
                            <span class="waiting-form__checkbox-text">${room.name}</span>
                        </label>
                    </li>`;
                });
                list += `</ul></li>`;
            });
            list += '</ul>';
            // выводим список
            self.decksList.innerHTML = list;
            // события выборя/снятия палуб и кают
            self.decksList.querySelectorAll('.waiting-form__label--deck').forEach(deck => {
                deck.querySelector('input').addEventListener('change', evt => {
                    evt.target.closest('li').querySelectorAll('ul input[type="checkbox"]').forEach(room => {
                        room.checked = evt.target.checked;
                    })
                });
                deck.parentNode.querySelectorAll('.waiting-form__label--room').forEach(room => {
                    room.querySelector('input').addEventListener('change', () => {
                        deck.querySelector('input').checked = deck.parentNode.querySelectorAll('.waiting-form__label--room input').length === deck.parentNode.querySelectorAll('.waiting-form__label--room input:checked').length;
                    });
                });
            });
            self.decksList.classList.remove('loading');
        }, 50);
    },
}

module.exports = waitingForm;
