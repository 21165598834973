const ordersFilterSettings = {
	selector: {
		gear: '.history-filter__settings-gear',
		close: '.settings__close',
		container: '.settings__container',
		filterParentClass: '.history-filter__item',
		filterItems: '.history-filter [data-f-label], .history-filter .b-checkbox__label'
	},
	init() {
		this.settings = JSON.parse($('[name="ordersFilterSettings"]').val())
		const settingsContainer = $('.settings');
		$(document).on('click', this.selector.gear, (e) => {
			e.preventDefault()
			e.stopPropagation()
			settingsContainer.show()
		})

		$(document).on('click', this.selector.close, (e) => {
			e.preventDefault()
			settingsContainer.hide()
		})

		$(document).on('click', '.settings__container', (e) => e.stopPropagation())

		$(document).on('click', () => {
			if (settingsContainer.is(':visible')) {
				settingsContainer.hide();
			}
		})

		$(document).on('change', '.settings__body input', (e) => {
			this.changeInputSetting($(e.currentTarget).data('name'), $(e.currentTarget).is(':checked'))
		})

		$(document).on('change', '[name="settings_all"]', (e) => {
			this.setFullSettings($(e.currentTarget).is(':checked'))
		})
	},
	setFullSettings(isChecked) {
		const settings = []
		const filterElements = $('form[name="form_orders_filter"] .orders-filter-item')
		const settingsCheckboxes = $('.settings__body input')

		if(!isChecked) {
			filterElements.each((i, el) =>  {
				settings.push(el.name)
			})
		}
		this.setFilterSettings(settings).then(() => {
			settingsCheckboxes.each((i, el) => {
				$(el).prop('checked', isChecked);
			})
			filterElements.each((i, el) =>  {
				$(el).closest(this.selector.filterParentClass).toggle(isChecked);
			})
		}).finally(() => $('.settings__container input').prop('disabled', false))
	},
	changeInputSetting(inputName, isChecked) {
		let settings = this.settings.filter(code => code !== inputName)
		if(!isChecked) {
			settings.push(inputName)
		}
		this.setFilterSettings(settings).then(() => {
			const element = $('form[name="form_orders_filter"]').find(`[name="${inputName}"]`)
			element.closest(this.selector.filterParentClass).toggle(isChecked)
		}).finally(() => $('.settings__container input').prop('disabled', false))
	},
	setFilterSettings: async function (settings) {
		$('.settings__container input').prop('disabled', true)
		return new Promise((resolve) => {
			BX.ajax.runComponentAction('extyl:orders.filter', 'setFilterSettings', {
				mode: 'class',
				data: {settings},
			}).then((result) => resolve(result), (er) => console.error(er))
		})
	}
}

module.exports = ordersFilterSettings;
