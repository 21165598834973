const drop = {
	run: () => {
		$('.vdrop__controller').on('click', function(e) {
			const content = $(this).prev();
			const curHeight = content.height();
			if (!content.attr('data-height')) {
				content.attr('data-height', curHeight);
			}
			if (content.attr('data-expanded') === "true") {
				content.height(curHeight).animate({ height: content.data('height') }, 300);
				content.attr('data-expanded', false);
				content.parent('.vdrop').removeClass('vdrop--expanded');
			} else {
				content.attr('data-expanded', true);
				const autoHeight = content.css('height', 'auto').height();
				content.height(curHeight).animate({ height: autoHeight }, 300);
				content.parent('.vdrop').addClass('vdrop--expanded');
			}
		})
	}
}

module.exports = drop
