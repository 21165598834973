import axios from "axios";

const accountableFilter = {
    init() {
        this.scrollTop = 0
        this.label = ''
        this.delay = (() => {
            let timer = 0
            return (callback, ms) => {
                clearTimeout(timer)
                timer = setTimeout(callback, ms)
            }
        })()
        const _this = this,
            accountable = $('[name="filter[accountable]"]'),
            container = accountable.closest('.select-orders-filter-item'),
            selectScroll = container.find('.select-scroll')


        accountable.data('page', 1)
        accountable.on('selectric-before-open', () => {
            if ($('#accountable_search').length === 0) {
                selectScroll.prepend('<input id="accountable_search" style="width: 100%" placeholder="Введите фамилию" type="text">')
            }
        })

        $(document).on('click', '#accountable_search', e => e.stopPropagation())

        $(document).on('input', '#accountable_search', () => {
            const request = $('#accountable_search').val()
            _this.label = request
            if (request.length > 0) {
                this.checkName(request)
            } else {
                accountable.find('option:gt(1)').remove()
                accountable.data('page', 1)
                _this.getAccountable()
            }
        })
        // selectScroll.on('scroll', (e) => {
        //     if($('#accountable_search').val().length === 0) {
        //         const container = e.target,
        //             filterAccountable = $('[name="filter[accountable]"]'),
        //             page = +filterAccountable.data('page') + 1
        //         _this.scrollTop = container.scrollTop
        //
        //         if (container.scrollTop === container.scrollHeight - container.clientHeight) {
        //             _this.getAccountable({page})
        //             filterAccountable.data('page', page)
        //         }
        //     }
        // })
    },
    checkName(fullName) {
        const _this = this
        this.delay(() => {_this.getAccountable({fullName})}, 1000)
    },
    // Раскомментировать после добавления поиска в crs
    // checkName(request) {
    //     const searchType = SearchIndividual.searchTypes().find(searType => searType.value === 'fullName'),
    //         _this = this
    //
    //     this.delay(() => {
    //         let params = searchType.params(request),
    //             users = []
    //         _this.sendRequest(params).then(response => {
    //             response.data.data.forEach(user => {
    //                 users.push({
    //                     value: user.id,
    //                     label: user.fio
    //                 })
    //             })
    //             $('[name="filter[accountable]"]').find('option:gt(1)').remove()
    //             _this.scrollTop = 0
    //             _this.processResponse(users)
    //         })
    //     }, 1000)
    // },
    sendRequest(params) {
        const token = document.cookie.replace(
            /(?:(?:^|.*;\s*)user_token\s*\=\s*([^;]*).*$)|^.*$/,
            "$1",
        )
        params.headers = {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`
        }
        return new Promise((resolve, reject) => {
            axios(params).then(response => resolve(response), error => reject(error))
        })
    },
    getAccountable(data) {
        const _this = this
        BX.ajax.runComponentAction('extyl:orders.filter', 'getAccountable', {
            mode: 'class',
            data
        }).then(response => _this.processResponse(response.data))
    },
    processResponse(users) {
        const filterAccountable = $('[name="filter[accountable]"]'),
            Selectric = filterAccountable.data('selectric'),
            itemsScroll = Selectric.elements.itemsScroll[0]
        filterAccountable.find('option:gt(0)').remove()
        users.forEach(user => {$('[name="filter[accountable]"]').append(`<option value="${user.value}">${user.label}</option>`)})
        Selectric.refresh()
        Selectric.open()
        $('#accountable_search').val(this.label)
        itemsScroll.scrollTop = this.scrollTop
    }
}

module.exports = accountableFilter