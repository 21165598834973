
let mobileMenu = {
    run() {
        this.$html = $('html');
        this.$header = $('.h');
        this.$headerBottom = this.$header.find('.h__bottom');
        this.$btns = $('[data-m-menu-btn], [data-m-menu-mask], [data-m-menu-close]');
        this.$menu = $('[data-m-menu]');
        this.$mask = $('[data-m-menu-mask]');
        this.isDetail = $('.h__bottom--tabs').length && $(window).innerWidth() >= 1024;

        $(window).resize(() => {

            this.init();
            this.catalog();
						this.addVueEventListener();

            this.$btns.each((index,btn) => {
                if($(btn).hasClass('init')) return;
                $(btn).addClass('init');
                $(btn).on('click', () => this.toggleMenu());
            });
        }).trigger('resize');
    },

    toggleMenu() {
        this.$menu.toggleClass('active');
        this.$html.toggleClass('locked');
        this.$mask.fadeToggle();
		$('.d-prices-basket-menu__active .b-modal-side__close').trigger('click');
    },

	addVueEventListener() {
		document.addEventListener('openBasketVue', () => {
			if (this.$menu.hasClass('active')) {
				this.toggleMenu();
			}
		})
	},

    init() {
        this.$menu.addClass('init');
        this.updHeader();
    },

    updHeader() {
        if (window.matchMedia("(max-width: 1279px)").matches && !this.isDetail) {
            this.$headerBottom.addClass('fixed');
            $('body').addClass('indent-top');
        } else {
            this.$headerBottom.removeClass('fixed');
            $('body').removeClass('indent-top');
        }
    },

    catalog() {
        $('[data-m-catalog] [data-count] > a').on('click', function (e) {
            e.preventDefault();

            if ($(this).attr('href') === 'javascript:void(0)') {
                openDropdown(e, $(this));
            } else if ((e.target.nodeName === 'svg') || (e.target.nodeName === 'path')) {
                openDropdown(e, $(this));
            } else if (e.target.className === 'h-m-menu__drop') {
                openDropdown(e, $(this));
            } else {
                document.location.href = $(this)[0].href;
            }
        });

        function openDropdown(event, targetItem) {
            event.preventDefault();
            event.stopPropagation();
            let $this = targetItem.parents('[data-count]:first');
            let count = $this.data('count');
            let $firstLinks = $('[data-m-catalog] > [data-m-menu-link]');

            if(!$this.hasClass('active')) {
                $this.addClass('active');
                $this.children('[data-wrap]').show();
                $this.parent().children('[data-count]:not(.active)').hide();

                if(count === 1)
                    $firstLinks.hide()
            } else {
                $this.removeClass('active');
                $this.children('[data-wrap]').hide();
                $this.parent().children('[data-count]:not(.active)').show();

                if(count === 1)
                    $firstLinks.show()
            }
        }
    }
};

module.exports = mobileMenu;
