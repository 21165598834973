const tabFixOnScroll = {
	run() {
		const $box = $('[tabs-fix-on-scroll]')

		if (!$box.length) return false

		const self = this
		this.offsetTop = parseInt($box.offset().top, 10)
		this.$btns = $box.find('[data-tab-btn]')
		this.offsetBeforeContent = 110

		$(window).scroll(function () {
			self.onScrollWindow({
				$box,
			})
			self.checkTabs()
		})
		this.$btns.on('click', function () {
			self.onClickBtn({
				$btn: $(this),
			})
		})
	},

	checkTabs() {
		const windowOffsetTop = $(window).scrollTop()
		const $boxes = $('.d-content[data-tab-content] > [data-tab-body]')
		const $header = $('.d-p-tabs__inner[data-tab-header]')
		const self = this

		$boxes.each(function (index, item) {
			if ($(item).offset().top - self.offsetBeforeContent <= windowOffsetTop) {
				$header.find('[data-tab-btn].active').removeClass('active')
				$header.find(`[data-tab-btn="${$(item).attr('data-tab-body')}"]`).addClass('active')
			}
		})
	},

	onScrollWindow({$box}) {
		const scrollTop = $(window).scrollTop()
		if (scrollTop >= this.offsetTop) {
			if (!$box.hasClass('fixed')) {
				$box.addClass('fixed')
				if ($('.h__bottom.fixed').length) {
					this.addOffset({
						$box,
						$fixedEl: $('.h__bottom.fixed'),
					})
				}
			}
		} else {
			if ($box.hasClass('fixed')) {
				$box.removeClass('fixed')
				if ($('.h__bottom.fixed').length) {
					this.removeOffset({
						$box,
					})
				}
			}
		}
	},

	addOffset({$box, $fixedEl}) {
		const elHeight = $fixedEl.innerHeight()
		$box.css('top', elHeight)
	},

	removeOffset({$box}) {
		$box.css('top', '')
	},

	onClickBtn({$btn}) {
		const $box = $btn.closest('[data-tab-header]')
		const $btns = $box.find('[data-tab-btn]')
		const name = $btn.attr('data-tab-btn')
		const $content = $(`[data-tab-body="${name}"]`)

		if ($btn.hasClass('active')) return false

		if ($content.length) {
			$('html, body')
				.stop()
				.animate({scrollTop: $content.offset().top - this.offsetBeforeContent})
			$btns.removeClass('active')
			$btn.addClass('active')
		}
	},
}

module.exports = tabFixOnScroll
