const addReviewForm = {
	cruisesOptions: [],
	shipsOptions: [],
	destinationOptions: [],
	yearsOption: [],
	monthsOption: [],
	init(data) {
		this.cruisesOptions = data['CRUISE'].OPTIONS;
		this.destinationOptions = data['DESTINATION'].OPTIONS;
		this.shipsOptions = data['MOTOR_SHIP'].OPTIONS;
		this.yearsOption = data['TRAVEL_YEAR'].OPTIONS;
		this.monthsOption = data['TRAVEL_MONTH'].OPTIONS;
		this.prepareEvents();
		this.renderYears();
		this.renderMonths();
		this.renderShips();
		this.renderCruises();
		this.renderDestinations();
		setTimeout(()=>{this.setDisableStateForSelects()},300);
	},
	filterCruise(filter) {
		let cruises = this.cruisesOptions;
		if (+filter.ship > 0) {
			cruises = cruises.filter(cruise => cruise['PARAMS']['SHIPS'].includes(+filter.ship));
		}
		if (+filter.destination > 0) {
			cruises = cruises.filter(cruise => cruise['PARAMS']['DESTINATION'].includes(+filter.destination));
		}
		if (!!filter.cruise && filter.cruise !== '') {
			cruises = cruises.filter(cruise => cruise['VALUE'] === filter.cruise);
		}
		if (+filter.year > 0) {
			cruises = cruises.filter(cruise => cruise['PARAMS']['NAVIGATION_YEAR'].includes(+filter.year));
		}
		if (+filter.month > 0) {
			const month = this.monthsOption.find(month => +month['VALUE'] === +filter.month);
			cruises = cruises.filter(cruise => cruise['PARAMS']['MONTHS'].includes(+month['MONTH']));
		}
		return cruises;
	},
	setDisableStateForSelects() {
		let year = $(`select[data-name="years"]`).val();
		let month = $(`select[data-name="months"]`).val();
		let ship = $(`select[data-name="ships"]`).val();
		if (year) {
			$(`select[data-name="months"]`).removeAttr('disabled').selectric('refresh');
		} else {
			$(`select[data-name="months"]`).attr('disabled', 'disabled').selectric('refresh');
		}

		if (month) {
			$(`select[data-name="ships"]`).removeAttr('disabled').selectric('refresh');
		} else {
			$(`select[data-name="ships"]`).attr('disabled', 'disabled').selectric('refresh');
		}

		if (ship) {
			$(`select[data-name="cruises"]`).removeAttr('disabled').selectric('refresh');
			$(`select[data-name="destinations"]`).removeAttr('disabled').selectric('refresh');
		} else {
			$(`select[data-name="cruises"]`).prop('disabled', 'disabled').selectric('refresh');
			$(`select[data-name="destinations"]`).prop('disabled', 'disabled').selectric('refresh');
		}
	},
	renderYears() {
		let yearSelect = $(`select[data-name="years"]`);
		yearSelect.append('<option></option>');
		this.yearsOption.forEach(year => {
			yearSelect.append(
				`<option value="${year['VALUE']}">${year['CAPTION']}</option>`
			);
		});
		yearSelect.selectric('refresh');
	},
	renderMonths(filteredData, oldValue = null) {
		let monthSelect = $(`select[data-name="months"]`);
		let needUpdate = false;
		if (filteredData) {
			filteredData.months.forEach(month => {
				monthSelect.append(
					`<option value="${month['VALUE']}" ${month['VALUE'] === oldValue ? 'selected' : ''}>${month['CAPTION']}</option>`
				);
				if (!needUpdate && month['VALUE'] === oldValue) {
					needUpdate = true;
				}
			});
		}
		monthSelect.selectric('refresh');
		if (needUpdate) {
			monthSelect.trigger('change');
		}
	},
	renderShips(filteredData, oldValue = null) {
		let shipsSelect = $(`select[data-name="ships"]`);
		if (filteredData) {
			filteredData.ships.forEach(ship => {
				shipsSelect.append(
					`<option value="${ship['VALUE']}" ${ship['VALUE'] === oldValue ? 'selected' : ''}>${ship['CAPTION']}</option>`
				);
			});
		}
		shipsSelect.selectric('refresh');
	},
	renderCruises(filteredData, oldValue = null) {
		let cruiseSelect = $(`select[data-name="cruises"]`);
		if (filteredData) {
			filteredData.cruises.forEach(cruise => {
				cruiseSelect.append(
					`<option value="${cruise['VALUE']}" ${cruise['VALUE'] === oldValue ? 'selected' : ''}>${cruise['CAPTION']}</option>`
				);
			});
		}
		cruiseSelect.selectric('refresh');
	},
	renderDestinations(filteredData, oldValue = null) {
		let destinationSelect = $(`select[data-name="destinations"]`);
		if (filteredData) {
			filteredData.destinations.forEach(destinations => {
				destinationSelect.append(
					`<option value="${destinations['VALUE']}" ${destinations['VALUE'] == oldValue ? 'selected' : ''}>${destinations['CAPTION']}</option>`
				);
			});
		}
		destinationSelect.selectric('refresh');
	},
	getFilteredData(cruises) {
		let filteredCruises = cruises;
		if (filteredCruises.length === 0) {
			filteredCruises = cruises;
		}
		let shipsIds = [];
		let destinationsIds = [];
		let arYears = [];
		let arMonths = [];
		filteredCruises.forEach(cruise => {
			shipsIds.push(...cruise['PARAMS']['SHIPS']);
			destinationsIds.push(...cruise['PARAMS']['DESTINATION']);
			arMonths.push(...cruise['PARAMS']['MONTHS']);
			arYears.push(...cruise['PARAMS']['NAVIGATION_YEAR']);
		});

		return {
			destinations: this.destinationOptions.filter(description => destinationsIds.includes(description['VALUE'])),
			years: this.yearsOption.filter(year => arYears.includes(year['VALUE'])),
			months: this.monthsOption.filter(month => arMonths.includes(+month['MONTH'])),
			ships: this.shipsOptions.filter(ship => shipsIds.includes(+ship['VALUE'])),
			cruises
		};
	},
	clearSelect(name) {
		let select = $(`select[data-name="${name}"]`);
		let oldValue = select.val();
		select.empty();
		select.append('<option></option>');
		select.prop('selectedIndex', 0).selectric('refresh');
		return oldValue;
	},
	prepareEvents() {
		const reviewForm = this;
		$(document).on('change', '#add-review select[data-name="years"]', function() {
			const year = +$(this).val();
			let oldMonth = reviewForm.clearSelect('months');
			reviewForm.clearSelect('ships');
			reviewForm.clearSelect('destinations');
			reviewForm.clearSelect('cruises');
			const cruises = reviewForm.filterCruise({
				year
			});
			let filteredData = reviewForm.getFilteredData(cruises);
			reviewForm.renderMonths(filteredData, oldMonth);
			reviewForm.setDisableStateForSelects();
		});
		$(document).on('change', '#add-review select[data-name="months"]', function(e) {
			const month = +$(this).val();
			let oldShip = reviewForm.clearSelect('ships');
			let oldDestination = reviewForm.clearSelect('destinations');
			let oldCruise = reviewForm.clearSelect('cruises');
			const cruises = reviewForm.filterCruise({
				month,
				year: +$('[data-name="years"]').val()
			});
			let filteredData = reviewForm.getFilteredData(cruises);
			reviewForm.renderShips(filteredData, oldShip);
			reviewForm.renderCruises(filteredData, oldCruise);
			reviewForm.renderDestinations(filteredData, oldDestination);
			reviewForm.setDisableStateForSelects();
		});
		$(document).on('change', '#add-review select[data-name="ships"]', function() {
			const shipId = +$(this).val();
			let oldDestination = reviewForm.clearSelect('destinations');
			let oldCruise = reviewForm.clearSelect('cruises');
			const cruises = reviewForm.filterCruise({
				year: +$('[data-name="years"]').val(),
				month: +$('[data-name="months"]').val(),
				ship: shipId
			});
			let filteredData = reviewForm.getFilteredData(cruises);
			reviewForm.renderCruises(filteredData, oldCruise);
			reviewForm.renderDestinations(filteredData, oldDestination);
			reviewForm.setDisableStateForSelects();
		});
		$(document).on('change', '#add-review select[data-name="cruises"]', function() {
			const cruise = $(this).val();
			let oldDestination = reviewForm.clearSelect('destinations');
			reviewForm.clearSelect('destinations');
			const cruises = reviewForm.filterCruise({
				year: +$('[data-name="years"]').val(),
				month: +$('[data-name="months"]').val(),
				ship: +$('[data-name="ships"]').val(),
				cruise
			});
			let filteredData = reviewForm.getFilteredData(cruises);
			reviewForm.renderDestinations(filteredData, oldDestination);
			reviewForm.setDisableStateForSelects();
		});
		$(document).on('change', '#add-review select[data-name="destinations"]', function() {
			const destination = $(this).val();
			let oldCruise = reviewForm.clearSelect('cruises');
			reviewForm.clearSelect('cruises');
			const cruises = reviewForm.filterCruise({
				year: +$('[data-name="years"]').val(),
				month: +$('[data-name="months"]').val(),
				ship: +$('[data-name="ships"]').val(),
				destination
			});
			let filteredData = reviewForm.getFilteredData(cruises);
			reviewForm.renderCruises(filteredData, oldCruise);
			reviewForm.setDisableStateForSelects();
		});
	}
};

module.exports = addReviewForm;
