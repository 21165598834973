let rating = {
    run() {
        this.stars();
    },

    stars() {

        //При клике
        $(document).on('click', '.rating:not(.rating--fixed) span', function (e) {
            let $this = $(e.target);
            let $wrapper = $this.parents('[data-rating]');
            let $stars = $wrapper.find('.rating span');

            //если событие -  клик
            let isClick = e.type === 'click';
            //добавить постоянный / временный класс
            let action = 'active';
            //текущая позиция звезды
            let rating = 5 - $this.index(); // 5 - индекс это костяль, тк у нас обратное позиционирование в стилях
            //скрытое поле ввода рейтинга
            let $input = $wrapper.find('input[type="text"]');

            //если клик - фиксируем рейтинг
            if(isClick) {

                //убрать текущий класс со всех звезд
                $stars.each(function () {
                    $(this).removeClass('active');
                });

                //добавить текущий класс текущей звезде
                $this.addClass(action);
                $input.val(rating);
            }
        });
    }
};

module.exports = rating;