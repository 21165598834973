import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	namespaced: true,
	state: {
		globalPreload: true,
		counter: 0,
		items: [],
		filters: [],
		status: false,
		properties: [],
		url: '',
		language: document.documentElement.lang,
		selectedValue: [
			[1, 2],
			[2, 4],
		],
	},
	mutations: {
		UPDATE_ITEMS(state, items) {
			state.items = typeof items === 'undefined' ? [] : items
		},
		UPDATE_URL(state, payload) {
			state.url = payload ? payload.split('?')[1] : ''
		},

		UPDATE_PROPERTIES(state, items) {
			state.properties = items
			state.globalPreload = false
		},

		ADD_PRICES(state, items) {
			for (let i = 0; i < items.ITEMS.length; i++) {
				state.items[i]['PRICES'] = items.ITEMS[i].TARIFFS
			}
			state.filters = []
			state.filters.push(items.ACCOMMODATION_TYPES)
			state.filters.push(items.TARIFFS)
		},

		TOGGLE_SIDEBAR(state, payload) {
			state.status = payload
		},
	},
	actions: {
		TOGGLE_SIDEBAR({commit}, payload) {
			commit('TOGGLE_SIDEBAR', payload)
		},
		addToCompare({commit}, cruiseID) {
			return new Promise(resolve => {
				BX.ajax.runComponentAction('extyl:catalog.compare.result', 'addToCompare', {
					mode: 'class',
					data: {
						id: cruiseID
					}
				}).then(response => {
					commit('UPDATE_ITEMS', response.data.ITEMS)
					commit('UPDATE_URL', response.data.shareCompare)
					resolve(true)
				})

			})
		},

		getCompare({commit}, different = false) {
			return new Promise(resolve => {
				BX.ajax.runComponentAction('extyl:catalog.compare.result', 'getCompare', {
					mode: 'class',
					data: {
						DIFFERENT: different ? 'Y' : 'N'
					}
				}).then(response => {
					commit('UPDATE_ITEMS', response.data.ITEMS)
					commit('UPDATE_PROPERTIES', response.data.SHOW_PROPERTIES)
					commit('UPDATE_URL', response.data.shareCompare)
					resolve(response.data.ITEMS)
				})
			})
		},

		getPrices({commit}) {
			return new Promise(resolve => {
				BX.ajax.runComponentAction('extyl:catalog.compare.result', 'getPrices', {
					mode: 'class'
				}).then(response => {
					commit('ADD_PRICES', response.data)
					resolve(response.data)
				})
			})
		},

		removeFromCompare({commit}, cruiseID) {
			return new Promise(resolve => {
				BX.ajax.runComponentAction('extyl:catalog.compare.result', 'deleteFromCompare', {
					mode: 'class',
					data: {
						id: cruiseID
					}
				}).then(response => {
					commit('UPDATE_ITEMS', response.data.ITEMS)
					commit('UPDATE_URL', response.data.shareCompare)
					resolve(response.data.ITEMS)
				})
			})
		},

		clearCompareList({commit}) {
			return new Promise(resolve => {
				BX.ajax.runComponentAction('extyl:catalog.compare.result', 'clearCompareList', {
					mode: 'class'
				}).then(response => {
					commit('UPDATE_PROPERTIES', response.data.SHOW_PROPERTIES)
					commit('UPDATE_ITEMS', response.data.ITEMS)
					commit('UPDATE_URL', response.data.shareCompare)
					resolve(response.data)
				})
			})
		},
	},
})
