let selectedCruiseCookie = {

    run() {
        $(document).on('click', '[data-book-cruise]', e => {
            e.preventDefault();
            let data = {
                'URL': window.location.href,
                'NAME': encodeURI($('[data-cruise-title]')[0].innerText),
            }
            document.cookie = 'SELECTED_CRUISE=' + JSON.stringify(data) + '; path=/; max-age=' + (30 * 24 * 60 * 60);
            window.location.href = e.currentTarget.href;
        });
    },
};

module.exports = selectedCruiseCookie;