import lazy from './lazy';

const listMore = {
    run() {
        this.$box = $('[data-list-more]');

        if(this.$box.length) {
            this.$box.each((i, item) => {
                const $content = $(item).find('[data-list-more-content]');
                const $opener = $(item).find('[data-list-more-opener]');
                const count = parseInt($(item).attr('data-list-show-by'));

                if(!$content.length) {
                    console.error(`Не указана обертка списка с помощью атрибута data-list-more-content`);
                    return;
                }

                if(!$opener.length) {
                    //console.error(`Не указан элемент с помощью атрибута data-list-more-opener открывающий список`);
                    return;
                }

                if(!Number.isInteger(count)) {
                    console.error(`Не указан количество отображаемых элементов за один клик в атрибуте data-list-show-by`);
                    return;
                }

                this.initContent({
                    $box: $(item),
                });

                $opener.on('click', (evt) => {
                    evt.preventDefault();
                    this.showNextItems({
                        $box: $(evt.currentTarget).closest('[data-list-more]'),
                    });
                });
            });
        }
    },

    initContent({$box}) {
        const count = parseInt($box.attr('data-list-show-by'));
        const $items = $box.find('[data-list-more-content]').children();

        $items.each((i, item) => {
            (i + 1) <= count ?
                $(item).attr('data-status', 'show'):
                $(item).attr('data-status', 'hidden');
        });
    },

    showNextItems({$box}) {
        const count = parseInt($box.attr('data-list-show-by'));
        const $itemsHidden = $box.find('[data-list-more-content] [data-status="hidden"]');
        const $opener = $box.find('[data-list-more-opener]');
        const $counter = $opener.find('[data-list-more-opener-counter]');

        $itemsHidden.each((i, item) => {
            if((i + 1) <= count) $(item).attr('data-status', 'show');
        });

        if($itemsHidden.find('.lazy').length) lazy.update();

        if(!$box.find('[data-list-more-content] [data-status="hidden"]').length) $opener.hide();
        
        if($counter.length) $counter.html($box.find('[data-list-more-content] [data-status="hidden"]').length);
    },
};

module.exports = listMore;