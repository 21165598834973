var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items && _vm.items.length > 0
    ? _c(
        "div",
        { staticClass: "compare-cart", class: ["compare-cart--" + _vm.cls] },
        [
          _vm.items
            ? _c(
                "button",
                {
                  staticClass: "compare-cart__button",
                  class: _vm.cls ? "compare-cart__button--" + _vm.cls : "",
                  on: { click: _vm.toggleSidebar },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/local/client/icons/icon-compare-carts.svg?inline",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "(" + _vm._s(_vm.items ? _vm.items.length : 0) + ")"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "none" } }, [
            _vm._v(_vm._s(_vm.status)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }