let videoCruises = {
    $box: $('[data-video-cruises]'),

    run() {
        if(this.$box.length) {
            const $play = this.$box.find('.map-cruises-description__video-play');

            $play.on('click', (evt) => {
                this.playVideo({
                    $btn: $(evt.currentTarget),
                })
            });
        }
    },

    playVideo(props) {
        const $container = props.$btn.closest('[data-video-cruises]');
        const $video = $container.find('.map-cruises-description__video');
        const $overlay = $container.find('.map-cruises-description__video-overlay');

        $overlay.addClass('hide');
        $video.attr('controls', '');
        $video[0].play();
    },

};

module.exports = videoCruises;