let loadPrices = {

    run() {
        this.loadPrice();
    },

    loadPrice() {
        let cruises = $('[data-load-cruise-info]');
        if (cruises.length < 1) {
            return;
        }
        let data = {};
        cruises.each(function(cruiseIndex, cruise) {
            let cruiseId = $(cruise).attr('data-load-cruise-info');
            data[cruiseId] = { id: cruiseId };

            let cabins = $(cruise).find('[data-load-cruise-cabin]');
            let cabinsIds = {};
            if (cabins.length > 0) {
                cabins.each(function(cabinIndex, cabin) {
                    let cabinId = $(cabin).attr('data-load-cruise-cabin');
                    cabinsIds[cabinId] = { id: cabinId };
                });
                data[cruiseId].cabins = cabinsIds;
            }
        });

        $.ajax({
            method: "POST",
            url: '/local/include/ajax/cruises_cabins_prices.php',
            data: JSON.stringify(data),
            dataType: 'json',
        })
        .done(function(data) {
            for (let item in data) {
                let cruiseWrapper = $('[data-load-cruise-info=' + data[item].id + ']')
                cruiseWrapper.find('[data-load-cruise-price]').html(data[item].price);
                if (data[item].oldPrice) {
                    cruiseWrapper.find('[data-load-cruise-old-price]').html(data[item].oldPrice);
                } else {
                    cruiseWrapper.find('[data-load-cruise-old-price]').remove();
                }
                if (data[item].fewCabins) {
                    cruiseWrapper.find('[data-load-cruise-few-cabins]').css("display","");
                } else {
                    cruiseWrapper.find('[data-load-cruise-few-cabins]').remove();
                }
                if (data[item].cabins) {
                    $.each(data[item].cabins, function(cabinID, cabin) {
                        let cabinWrapper = cruiseWrapper.find('[data-load-cruise-cabin=' + cabin.id + ']');
                        if (cabin.price) {
                            cabinWrapper.find('[data-load-cruise-cabin-not-available]').remove();
                            cabinWrapper.find('[data-load-cruise-cabin-price-wrapper] span').html(cabin.price);
                            cabinWrapper.find('[data-load-cruise-cabin-price-wrapper]').css("display","");
                            cabinWrapper.find('[data-load-cruise-cabin-link]').attr('href', cabin.link);
                            cabinWrapper.find('[data-load-cruise-cabin-link]').css("display","");
                        } else {
                            cabinWrapper.find('[data-load-cruise-cabin-price-wrapper]').remove();
                            cabinWrapper.find('[data-load-cruise-cabin-link]').remove();
                            cabinWrapper.find('[data-load-cruise-cabin-not-available]').css("display","");
                        }
                    });
                }
            }
            $('.price-js').show()
        });
    },
};

module.exports = loadPrices;