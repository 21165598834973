
const certificates = {
    $openers: $('.certificates__item--opener-js'),

    run() {
        if(this.$openers.length) {
            this.$openers.on('click', (evt) => {
                this.openFancy($(evt.currentTarget).data('id'));
            });
        }
    },
    openFancy(id) {
        $.ajax({
            url: '/local/include/modals/certificates.php',
            data: {
                id
            },
            success: function(data) {
                $.fancybox.close();
				let dataWithoutScript = data.replace(/<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, "");
                $.fancybox.open( $(dataWithoutScript), {
                    touch: false,
                    infobar: false
                });
            }
        })
    },
}

module.exports = certificates;
