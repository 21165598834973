import Vue from 'vue'
import App from './compareHeader/App'
import MobileBtnCompare from './compareHeader/MobileBtnCompare'
import store from './store/index'
import {mapActions} from 'vuex'

const items = document.querySelectorAll('.app-compare-header')
items.forEach(el => {
	new Vue({
		el,
		render: h => h(App),
		store,
		methods: {
			...mapActions(['getCompare']),
		},
		mounted() {
			this.getCompare()
		},
	})
})

const itemsheaders = document.querySelectorAll('.app-compare-header-mobile')
itemsheaders.forEach(el => {
	new Vue({
		el,
		render: h => h(App, {props: {cls: 'h-mobile'}}),
		store,
		methods: {
			...mapActions(['getCompare']),
		},
		mounted() {
			this.getCompare()
		},
	})
})

const wrapBtnCompare = document.querySelectorAll('.mob-menu-compare-btns')
wrapBtnCompare.forEach(el => {
	new Vue({
		el,
		render: h => h(MobileBtnCompare),
		store,
		methods: {
			...mapActions(['getCompare']),
		},
		mounted() {
			this.getCompare()
		},
	})
})
