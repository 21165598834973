let langPicker = {
    $container: $('[data-lang-picker]'),

    run() {
        if(this.$container.length) {
            this.$container.find('.h__lang-arrow').on('click', (evt) => {
               this.toggleDropdown({
                   $container: $(evt.currentTarget).closest('[data-lang-picker]'),
               });
            });
        }
    },

    toggleDropdown({$container}) {
        $container.hasClass('opened') ? this.hideDropdown({$container: $container}) : this.showDropdown({$container: $container});
    },

    hideDropdown({$container}) {
        const $dropdown = $container.find('.h__lang-dropdown');
        $container.removeClass('opened');
        $dropdown.stop().slideUp();
    },

    showDropdown({$container}) {
        const $dropdown = $container.find('.h__lang-dropdown');
        $container.addClass('opened');
        $dropdown.stop().slideDown();
    },
}

module.exports = langPicker;