let paymentLinkConfirmDialogModal = {
	modalAttrs: {
		types: "data-confirm-pay-link-types",
		wrap: "data-confirm-pay-link-modal",
		content: "data-confirm-pay-link-modal-content",
		close: "data-confirm-pay-link-modal-close",
		link: "data-pay-link-value",
		href: "data-pay-link-href",
	},
	modalElem: {},
	activeContentType: null,
	run() {
		this.initModalElem();
		this.initEventLister();
	},
	initModalElem() {
		for (const key in this.modalAttrs) {
			const findItem = this.getElemByAttr(this.modalAttrs[key]);
			this.modalElem = {
				...this.modalElem,
				[key]: findItem,
			};
		}
	},
	initEventLister() {
		this.modalElem["types"].forEach(item => item.addEventListener("click", (_event) => this.openModal(_event), false));
		this.modalElem["close"].forEach(item => item.addEventListener("click", () => this.closeModal(), false));
	},
	closeModal() {
		if ("wrap" in this.modalElem) {
			this.activeContentType = null;
			this.modalElem["wrap"].forEach(item => item.classList.add("hidden"));
		}
	},
	openModal(_event) {
		if ("wrap" in this.modalElem) {
			this.setActiveContentModal(_event.currentTarget);
			this.modalElem["wrap"].forEach(item => item.classList.remove("hidden"));
		}
	},
	setCurrentPayLink(link) {
		this.modalElem["href"].forEach(item => item.setAttribute("href", link));
	},
	setActiveContentModal(currentType) {
		let currentHrefByType = "";
		for (let i = 0; i < currentType.childNodes.length; i++) {
			if (currentType.childNodes[i].hasAttribute(this.modalAttrs["link"])) {
				currentHrefByType = currentType.childNodes[i].getAttribute(this.modalAttrs["link"]);
			}
		}
		this.activeContentType = currentType.getAttribute(this.modalAttrs["types"]);
		this.modalElem["content"].forEach(item => {
			if (item.getAttribute(this.modalAttrs["content"]) === this.activeContentType) {
				this.setCurrentPayLink(currentHrefByType);
				if (this.hasCashBackMirPay()) {
					item.classList.remove("hidden");
				}
			}
			else {
				if (this.hasCashBackMirPay()) {
					item.classList.add("hidden");
				}
			}
		});
	},
	getElemByAttr(attr) {
		return document.querySelectorAll(`[${attr}]`);
	},
	hasCashBackMirPay() {
		let result = false;
		this.modalElem["types"].forEach(item => {
			if (item.getAttribute(this.modalAttrs["types"]) === "hasCashBack") {
				result = true;
			}
		});
		return result;
	},
};
module.exports = paymentLinkConfirmDialogModal;
