let tooltip = {
    run(elem) {
        if (elem) {
            new Tooltip($(elem));
        } else {
            $('.tooltip:not(.init)').each(function() {
                new Tooltip($(this));
            });
        }
    }
};

class Tooltip {
    constructor($item) {
        this.$item = $item;
        this.$icon = this.$item.find('.tooltip__icon');
        this.$text = this.$item.find('.tooltip__text');
        this.tooltipWidth = 430;

        this.handlers();

        this.init();
    }

    handlers() {
        if (this.$item.hasClass('tooltip--click')) {
            this.$item.on('click', (e) => this.click(e));
        } else {
            if ($('.bx-ios').length && $(window).innerWidth() <= 1024) {
                this.$item.on('click', (e) => this.showIos(e));
            } else {
                this.$item[0].addEventListener('mouseenter', () => this.show());
                this.$item[0].addEventListener('mouseleave', () => this.hide());
            }
        }
    }

    show() {
        this.$text.fadeIn(200);

        if($(window).innerWidth() >= 768)
            this.tooltipWidth = this.$text.innerWidth();
            this.position();
    }

    hide()  {
        this.$text.stop();
        this.$text.fadeOut(200);
    }

    showIos(e)  {
        e.preventDefault();

        this.show();

        setTimeout(() => this.hide(), 700);
    }

    click(e) {
        e.stopPropagation();

        if (this.$text.css('display') === 'none') {
            this.show();
        } else {
            this.hide();
        }

        $(document).one('click', () => this.hide());
    }

    position() {
        let positionLeft = this.$text.offset().left;

        let notEnoughSpace = () => {
            const tooltipRight = positionLeft + this.tooltipWidth;
            const $slider = this.$item.parents('[data-slider]');

            if($slider.length)
                return tooltipRight > window.innerWidth || tooltipRight > $slider.offset().left + $slider.innerWidth();
            else
                return tooltipRight > window.innerWidth
        };

        if(positionLeft < 0) { /* левый край */
            this.$text.offset({left: 15});
            this.$text.addClass('moved');
        } else if(notEnoughSpace()) { /* правый край */

            if($(window).innerWidth() > 767){ /* если десктоп - развернуть */
                this.$text.addClass('left');
            } else { /* подвинуть на мобилке */
                this.$text.offset({left: $(window).width() - this.tooltipWidth - 15});
                this.$text.addClass('moved');
            }
        } else {
            return false
        }
    }

    init() {
        this.$item.addClass('init');
    }
}

module.exports = tooltip;
