import axios from 'axios';

let partner_filter = {
  run() {
    let inputBox = document.querySelector('#agency_id');
    if (inputBox) {
      let input = inputBox.querySelector('#site_id_new');

      let agency_input = inputBox.querySelector('#agency');
      let list = inputBox.querySelector('ul');
      let timerId;

      function fetchSites(e) {
        axios.get('/bitrix/services/main/ajax.php?mode=class&c=extyl:widget.config&action=searchAgentsSite', {
          params: {
            site: e.target.value
          }
        })
          .then((response) => {
            inputBox.querySelector('.select-items').style.display = 'block';
            inputBox.querySelector('.select-items').style.width = '100%';
            inputBox.querySelector('.select-items').style.zIndex = '100';
            list.innerHTML = '';
            if (response.data.length > 0) {
              response.data.forEach(site => {
                let list_item = document.createElement('li');
                list_item.classList.add('partner-list__item');
                list_item.addEventListener('mousedown', (e) => {
                  e.stopPropagation();
                  agency_input.value = site.value;
                  document.querySelector('.cruise-filter-form').submit();
                });
                list_item.textContent = site.name;
                list.append(list_item);
              });
            } else {
              let list_item = document.createElement('li');
              list_item.classList.add('partner-list__item');
              list_item.classList.add('disabled');
              list_item.textContent = 'Сайт не найден';
              list.append(list_item);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      input.addEventListener('input', (e) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          fetchSites(e);
        }, 1000);
      });
      input.addEventListener('blur', () => {
        inputBox.querySelector('.select-items').style.display = 'none';
      });
      input.addEventListener('focus', () => {
        if (list.querySelectorAll('li').length > 0) {
          inputBox.querySelector('.select-items').style.display = 'block';
        }
      });

    }
  }
};

module.exports = partner_filter;
