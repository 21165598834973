$(function () {
    // Sidebar Dropdown
    const selectWidget = document.querySelector('.widget__select');
    const selectName = document.querySelector('.widget__select-name');

    if (selectName) {
        selectName.addEventListener('click', event => {
            selectWidget.classList.toggle('show');
			document.dispatchEvent(new Event('resize-v-drop'))
        });
    }
})
