export const baseUrl = process.env.BASE_BOOKING_API
export const baseBookingUrl = process.env.BASE_BOOKING
export const baseCRSUrl = process.env.BASE_CRS_URL
export const baseCRMUrl = process.env.BASE_CRM
export const xlsExchangeUrl = process.env.BASE_SITE_XLS
export const crmBaseAuth = process.env.CRM_BASE_AUTH || ''
export const siteBaseAuth = process.env.SITE_BASE_AUTH || ''
export const vodohodProDiscountName = process.env.VODOHOD_PRO_DISCOUNT_NAME || ''

export const rootDomain = document.domain.split('.').slice(-2).join('.')
