import inputs from './inputs';
import select from './select';
import datepicker from  './datepicker';
import inputMask from './inputMask';
import validation from "./validation";
import modals from "./modals";

let reInitForms = {
    run() {
        inputs.run();
        select.run();
        datepicker.run();
        inputMask.run();
        validation.run();
        modals.run();
    }
};

module.exports = reInitForms;