const simpleText = {
    run() {
        this.$box = $('[simple-text]');
        this.$toggler = this.$box.find('[simple-text-toggler]')
        this.init();

        this.$toggler.on('click', (evt) => {
            this.toggleContent({
                $box: $(evt.currentTarget).closest('[simple-text]'),
            });
        });
    },

    init() {

        this.$box.each((index, item) => {

            if(typeof parseInt($(item).attr('data-max-height')) !== 'number') {
                console.error('не корректное макс. значение высоты аттр. data-max-height', $(item));
                return false;
            }

            const maxHeight = parseInt($(item).attr('data-max-height'));
            const $content = $(item).find('[simple-text-content]');
            const $toggler = $(item).find('[simple-text-toggler]');

            if($content.innerHeight() > maxHeight) {
                $content.attr('data-text-status', 'hide');
                $content.css('height', maxHeight);
            } else {
                $toggler.hide();
            }
        });
    },

    toggleContent({$box}) {
        const $content = $box.find('[simple-text-content]');
        switch ($content.attr('data-text-status')) {
            case 'hide':
                this.showContent({$box: $box});
                break;

            case 'show':
                this.hideContent({$box: $box});
                break;

            default:
                console.error('Неизвестный статус data-text-status ', $content);
        }
    },

    showContent({$box}) {
        const $content = $box.find('[simple-text-content]');
        const $toggler = $box.find('[simple-text-toggler]');

        $content.css('height', '');
        $content.attr('data-text-status', 'show');

        $toggler.html($toggler.attr('data-close-text'));
        $toggler.toggleClass('active')
    },

    hideContent({$box}) {
        const $content = $box.find('[simple-text-content]');
        const $toggler = $box.find('[simple-text-toggler]');

        $content.css('height', $box.attr('data-max-height'));
        $content.attr('data-text-status', 'hide');

        $toggler.html($toggler.attr('data-open-text'));
        $toggler.toggleClass('active')
    },
};

module.exports = simpleText;