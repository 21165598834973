let uploader = {
    run(elem) {
      if (elem) {
        new ImageUploader($(elem));
      } else {
        initImageUploader();
      }
    },
  };

  class ImageUploader {
    constructor(container) {
      this.container = container;
      this.imageInput = container.querySelector('.js-form-image');
      this.imagePreview = container.querySelector('.js-preview-image');
      this.init();
    }

    uploadFile() {
        const self = this;
        let data = new FormData;

        data.append(this.imageInput.name, this.imageInput.files[0])
        BX.ajax.runComponentAction('extyl:lk.personal.crs', 'uploadAvatar', {
            mode: 'class',
            data
        }).then(function (result) {
            $(self.imagePreview).find('img').attr('src', result.data)
        })
    }

    changeInput() {
        this.imageInput.addEventListener('change', () => {
            const file = this.imageInput.files[0]
            if(this.validate(file)) {
                this.uploadFile()
            }
        })
    }

    validate(file) {
        const self = this;
        const MAX_FILE_SIZE = 3e+6;

        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
            this.container.classList.add('_err-type');
            if (this.container.classList.contains('_err-type')) {
                setTimeout( function() {
                    self.container.classList.remove('_err-type');
                }, 5000)
            }

            this.imageInput.value = '';
            return false;
        }

        if (file.size > MAX_FILE_SIZE) {
            this.container.classList.add('_err-size');
            if (this.container.classList.contains('_err-size')) {
                setTimeout( function() {
                    self.container.classList.remove('_err-size');
                }, 5000)
            }
            return false;
        }
        return true
    }

    init() {
        this.changeInput();
    }
  }

  function initImageUploader() {
    const jsImageUploader = document.querySelectorAll(
      ".upload-file__container"
    );

    jsImageUploader.forEach((el) => {
      new ImageUploader(el);
    });
  }

module.exports = uploader;
