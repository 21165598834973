let cruises = {
  run(elem) {
    if (elem) {
      new SimpleCruises($(elem));
    } else {
      initAllCruises();
    }
  },
};

class SimpleCruises {
  constructor(container) {
    this.container = container;
    this.switch = container.querySelector(".path__info-btn--switch");
    this.init();
  }

  switchView() {
    this.switch.addEventListener("click", () => {
      this.container.classList.toggle("show-simple");
      this.switch.classList.toggle("_active");
    });
  }

  init() {
    if (this.switch) {
      this.switchView();
    }
  }
}

function initAllCruises() {
  const jsSimpleCruises = document.querySelectorAll(
    ".js-simple-cruises"
  );

  jsSimpleCruises.forEach((el) => {
    new SimpleCruises(el);
  });
}

module.exports = cruises;
