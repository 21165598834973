let validation = {
    validExtension: [
        'image/jpeg',
        'image/png',
        'image/bmp',
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'text/plain',
    ],
    validFileName: [
        'jpg',
        'png',
        'jpeg',
        'bmp',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'rtf',
        'pdf',
        'txt',
    ],
    maxFileSize: 10000000,

    run() {
        this.agreeCheckBoxInit();
        this.agreeCheckBoxChange();
        this.initOKPO();

        $(document).find('form[data-validate-form-on-submit]').each(function () {
            $(this).on('submit', function (e) {
                if (validation.validate($(this)) !== true) {
                    e.preventDefault();
                    return false;
                }
            })
        });
    },

    initOKPO() {
        const $inputs = $('input[name="AGENCY[UF_OKPO]"]');

        if (!$inputs.length) return;

        $inputs.on('focus keyup change', (e) => {
            this.validateOKPO({$input: $(e.currentTarget)});
            setTimeout(() => {this.updOKPOPrompt({$input: $(e.currentTarget)});}, 10)
        });

        $inputs.on('blur', (e) => {
            this.removeOKPOPrompt({$input: $(e.currentTarget)});
        });
    },

    validateOKPO({$input}) {
        const $box = $input.closest('.b-form__item');
        const $prompt = $box.find('.b-form__item-prompt');

        !$prompt.length ? this.showOKPOPrompt({$input: $input}) : this.updOKPOPrompt({$input: $input});

        if($input.val().length > 14) {
            $input.val($input.val().slice(0,14));
            this.updOKPOPrompt({$input: $input});
        }
    },

    updOKPOPrompt({$input}) {
        const $box = $input.closest('.b-form__item');
        const $counter = $box.find('.b-form__item-prompt-counter');

        if(!$counter.length) return false;

        $counter.html($input.val().length);
    },

    showOKPOPrompt({$input}) {
        const $box = $input.closest('.b-form__item');
        const length = $input.val().length;
        const $prompt = $(`<div class="b-form__item-prompt">Поле ОКПО может содержать только 8,10 или 14 цифр.<br/> Сейчас введено - <span class="b-form__item-prompt-counter">${length}</span></div>`);

        $box.append($prompt);
    },

    removeOKPOPrompt({$input}) {
        const $box = $input.closest('.b-form__item');
        const $prompt = $box.find('.b-form__item-prompt');

        if(!$prompt.length) return false;
        $prompt.remove();
    },

    validate(form) {
        let self = this;
        let flag = true;

        form.find('[data-form-error]').addClass('hidden');
        form.find('[data-error-item]').addClass('hidden');
        form.find('[data-form-success]').addClass('hidden');
        form.find('[data-form-auth-error]').addClass('hidden');

        form.find('input[type=number], input[type=text], input[type=radio], input[type=checkbox], textarea, input[type=password], select').each(function () {
            let itemFlag = true;
            let input = $(this);

            if (input.attr('data-skip-validation') !== undefined) {
                return;
            }

            let formItem = input.closest('[data-f-item]');
            let fieldError = formItem.find('[data-form-error]');
            let errorItems = formItem.find('[data-error-item]');

            let optionValue;
            let val = (input.prop('nodeName') === 'SELECT' && !(optionValue = input.find('option:selected').attr('value'))) ? optionValue : input.val();


            if (input[0].classList.contains('phone-with-country') && input[0].inputmask?.undoValue) {
                let pos = 0;
                let foundPos = input[0].inputmask?.undoValue.indexOf('_', pos);
                if (foundPos !== -1){
                    formItem.addClass('error')
                    flag = false
                    itemFlag = false
                }
            }

            if (input.is('[type=checkbox]')) {
                val = input.is(':checked');
            }

            if (val && input.attr('data-form-field-phone')) {
                val = val.split(' ').join('');
                input.val(val);
            }

            if (itemFlag && input.attr('data-required') === 'Y'
                && (!val || val === 'null' || val === input.attr('placeholder') || val.length < 1   )
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_REQUIRED_FIELD'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && input.attr('data-min-length') !== undefined
                && val.length < input.attr('data-min-length')
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_INCORRECT_LENGTH'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && input.attr('data-confirm-pass') !== undefined
                && val !== form.find('[data-pass]').val()
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_PASSWORDS_MISMATCH'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && input.attr('data-form-field-phone') !== undefined
                && !((/^\d{7,20}$/).test(val))
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_INCORRECT_FIELD_PHONE'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                val === '' && input.attr('data-form-field-phone') !== undefined
            ) {
                let phoneLabel = $(input).closest('[data-f-item]').find('[data-f-label]');
                phoneLabel.addClass('active');
            }

            if (
                itemFlag && val && input.attr('name') === 'UF_PERSONAL_BIRTHDAY'
                && !self.isUnrealDate(val)
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_INCORRECT_FIELD_DATE'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && (input.attr('name') === 'documentIssueDate')
                && !self.isUnrealDate(val)
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_INCORRECT_FIELD_DATE'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && (input.attr('name') === 'documentIssueDate') &&
                self.isUnrealDate(val) && form.find('input[name="UF_PERSONAL_BIRTHDAY"]').val() &&
                self.compareDates(form.find('input[name="UF_PERSONAL_BIRTHDAY"]').val(), val)
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('LK_COMPANION_ERROR_DOCUMENT_DATE'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && input.attr('data-form-field-email') !== undefined
                && !self.validEmailTest(val)
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_INCORRECT_FIELD_EMAIL'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if (
                itemFlag && val && input.attr('data-form-field-date') !== undefined
                && !self.validDateTest(val)
            ) {
                errorItems.addClass('hidden');
                errorItems.html(BX.message('ERROR_INCORRECT_FIELD_DATE'));
                formItem.find('[data-error-item]').removeClass('hidden');
                itemFlag = false;
            }

            if(/documentSeparateSeries/gi.test(input.attr('name'))) {
                const $docType = $('[name="documentType"]').first();

                if($docType.val() === '13') {
                    if(input.val().replace(/\D/g, '').length !== 4) {
                        formItem.addClass('error');
                        errorItems.html('Укажите 4 цифры');
                        itemFlag = false;
                    }
                } else if($docType.val() === '16') {
                    if(!/[IVXLC1УХЛС]{1,5}-[А-Я]{2}/.test(input.val())) {
                        formItem.addClass('error');
                        errorItems.html('Укажите корректную серию документа');
                        itemFlag = false;
                    }
                }
            }

            if(/documentSeparateNumber/gi.test(input.attr('name'))) {
                const $docType = $('[name="documentType"]').first();
                if($docType.val() === '13' || $docType.val() === '16') {
                    if(input.val().replace(/\D/g, '').length !== 6) {
                        formItem.addClass('error');
                        errorItems.html('Укажите 6 цифр');
                        itemFlag = false;
                    }
                }
            }

            if (itemFlag) {
                formItem.removeClass('error');
                fieldError.addClass('hidden');
                errorItems.addClass('hidden');
            } else {
                formItem.addClass('error');
                fieldError.removeClass('hidden');
                flag = false;
            }

            if (/AGENCY\[website\]/gi.test(input.attr('name'))) {
                if (input.val().length && !/\./.test(input.val())) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать не менее одной точки');
                }
            }

            if (/AGENCY\[inn\]/gi.test(input.attr('name'))) {
                const $type = $('select[name="AGENCY[ownershipForm]"]');

                if (/[^0-9]/gi.test(input.val())) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать только цифры');
                }

                if($type.length && $type.val() === '52' && /AGENCY\[inn\]/gi.test(input.attr('name'))) {
                    if(input.val().length !== 12) {
                        formItem.addClass('error');
                        errorItems.html('Поле должно содержать 12 цифр');
                    }
                } else {
                    if(input.val().length > 10) {
                        formItem.addClass('error');
                        errorItems.html('Поле должно содержать не более 10 цифр');
                    }
                }
            }

            if (/AGENCY\[okpo\]/gi.test(input.attr('name'))) {
                if (/[^0-9]/gi.test(input.val())) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать только цифры');
                }

                if (input.val().length > 14) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать 8, 10, 14 символов');
                }
            }

            if (/AGENCY\[bik\]/gi.test(input.attr('name')) ||
                /AGENCY\[kpp\]/gi.test(input.attr('name'))) {
                if (/[^0-9]/gi.test(input.val())) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать только цифры');
                }

                if (input.val().length > 9) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать не более 9 цифр');
                }
            }

            if (/AGENCY\[paymentAccount\]/gi.test(input.attr('name'))) {
                if (/[^0-9]/gi.test(input.val())) {
                    formItem.addClass('error');
                    errorItems.html('Поле должно содержать только цифры');
                }
            }

            if (/AGENCY\[ownerFirstNameNominative\]/gi.test(input.attr('name')) ||
                /AGENCY\[ownerMiddleNameNominative\]/gi.test(input.attr('name')) ||
                /AGENCY\[ownerLastNameNominative\]/gi.test(input.attr('name')) ||
                /AGENCY\[ownerFirstName\]/gi.test(input.attr('name')) ||
                /AGENCY\[ownerMiddleName\]/gi.test(input.attr('name')) ||
                /AGENCY\[ownerLastName\]/gi.test(input.attr('name'))) {
                if (/[0-9]/gi.test($(this).val())) {
                    formItem.addClass('error');
                    errorItems.html('Поле не должно содержать цифры');
                }
            }

            if (/AGENCY\[hasTaxesScan\]/gi.test(input.attr('name')) ||
                /AGENCY\[hasEgrulScan\]/gi.test(input.attr('name')) ||
                /AGENCY\[hasLegalScan\]/gi.test(input.attr('name'))) {
                const files = input[0].files;
                const MAX_SIZE = 15728640; //15MB

                if (files.length && files[0].size > MAX_SIZE) {
                    formItem.addClass('error');
                    errorItems.html('Файл не должен быть больше 15Мб');
                }
            }


            if (input.attr('name') === 'REGISTER[birthDate]' && itemFlag) {
                let birthDay = formItem.find('input')[0].value
                let differenceDay = birthDay.substr(0, 2) ;
                let differenceMounth = birthDay.substr(3, 2);
                let differenceYear = birthDay.substr(-4);

                let today_date = new Date();
                let today_year = today_date.getFullYear();
                let today_month = today_date.getMonth() ;
                let today_day = today_date.getDate();

                let age = today_year - differenceYear;

                if (today_month < (differenceMounth - 1)) {
                    age--;
                }

                if (((differenceMounth - 1) == today_month) && (today_day < differenceDay)) {
                    age--;
                }

                if (isNaN(age)) {
                    errorItems.removeClass('hidden');
                    formItem.addClass('error')
                    errorItems.html( "Заказчиком может быть только совершеннолетний.");
                    flag = false
                }

                if (age < 18){
                    errorItems.removeClass('hidden');
                    formItem.addClass('error')
                    errorItems.html( "Заказчиком может быть только совершеннолетний.");
                    flag = false
                } else {
                    errorItems.addClass('hidden');
                    formItem.removeClass('error')
                    errorItems.html( "");
                    flag = true
                }
            }

			if (input.attr('data-inputmask') && input.attr('id') === 'CONTRACT_NUMBER') {
				if (!input.inputmask("isComplete")) {
					formItem.addClass('error');
					errorItems.html('Номер заказа указан неверно');
					flag = false;
				}
			}
        });
        flag = this.checkFileField(form) && flag;
        if (!flag) {
            self.scrollToMessage(form);
        }
        return flag;
    },

    scrollToMessage(container) {
        let isModal = (container.closest('.modal').length > 0);
        let error = container.find('.form--error:first:not(.hidden), [data-required].error');
        let success = container.find('.form--success:first:not(.hidden)');
        if (!isModal && (error.length > 0 || success.length > 0)) {

            let pos = 0;
            if (error.length > 0) {
                pos = error.offset();
            } else {
                pos = success.offset();
            }

            if ($(window).scrollTop() === 0 && pos.top < 500)
                return;

            $('html,body').animate({ scrollTop: pos.top - 200 }, 500);
        }
    },

    checkFileField(form) {
        let self = this;
        let $file = form.find('input[type=file]');
        let flagRequire = true;
        let flagValidSize = true;
        let flagValidExtension = true;

        if ($file.length === 0) {
            return true;
        }

        $file.each(function () {
            let $this = $(this);
            let container = $this.closest('[data-file-field]');
            let required = $this.attr('data-file-field-required');
            let fieldError = container.find('[data-form-error]');
            let fieldErrorSize = container.find('[data-form-error-size]');
            let fieldErrorExtension = container.find('[data-form-error-extension]');
            let file = $this[0].files[0];

            if (container.hasClass('hidden')) {
                return true;
            }

            if (typeof required === 'string' && required !== 'N') {
                if (file) {
                    fieldError.addClass('hidden');
                    container.removeClass('error');
                } else {
                    flagRequire = false;
                    fieldError.removeClass('hidden');
                    container.addClass('error');
                }
            }

            if (file) {
                if (
                    self.validFileName.indexOf(self.getExtension(file.name.toLowerCase())) === -1
                ) {
                    flagValidExtension = false;
                    fieldErrorExtension.removeClass('hidden');
                    container.addClass('error');
                } else {
                    fieldErrorExtension.addClass('hidden');
                }
                if ($this[0].getAttribute('name') == 'PERSONAL_PHOTO') {
                    if (file.size > 3000000) {
                        flagValidSize = false;
                        fieldErrorSize.removeClass('hidden');
                        container.addClass('error');
                    }
                } else {
                    if (file.size > self.maxFileSize) {
                        flagValidSize = false;
                        fieldErrorSize.removeClass('hidden');
                        container.addClass('error');
                    } else {
                        fieldErrorSize.addClass('hidden');
                        if(!container.find('.b-form__text:not(.hidden)').length) container.removeClass('error');
                    }
                }
            }
        });

        return flagRequire && flagValidSize && flagValidExtension;
    },

    getExtension(filename) {
        let parts = filename.split('.');
        return parts[parts.length - 1];
    },

    validEmailTest(mail) {
        let re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re_email.test(mail);
    },

    validDateTest(date) {
        let re_date = /^\d{2}[.]\d{2}[.]\d{4}$/;
        return re_date.test(date);
    },

    isUnrealDate(date) {
        let dateNow = new Date();
        let dataNowFormat = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()).getTime() / 1000;
        let parseDateToArr = date.split('.');
        let inputDate = new Date(`${parseDateToArr[1]}/${parseDateToArr[0]}/${parseDateToArr[2]}`).getTime() / 1000;
        let minDate = new Date('01/31/1870').getTime() / 1000;
        return (dataNowFormat >= inputDate) && (minDate <= inputDate);
    },

    compareDates(firstDate, secondDate) {
        let parseFirstDateToArr = firstDate.split('.');
        let inputFirstDate = new Date(`${parseFirstDateToArr[1]}/${parseFirstDateToArr[0]}/${parseFirstDateToArr[2]}`).getTime() / 1000;
        let parseSecondDateToArr = secondDate.split('.');
        let inputSecondDate = new Date(`${parseSecondDateToArr[1]}/${parseSecondDateToArr[0]}/${parseSecondDateToArr[2]}`).getTime() / 1000;
        return (inputFirstDate > inputSecondDate);
    },

    agreeCheckBoxInit() {
        let self = this;
        $('[data-agree-check-box]').each(function () {
            let $this = $(this);
            self.processAgreeCheckBox($this)
        });
    },

    agreeCheckBoxChange() {
        let self = this;
        $(document).on('change', '[data-agree-check-box]', function () {
            let $this = $(this);
            self.processAgreeCheckBox($this)
        });
    },

    processAgreeCheckBox($this) {
        let name = $this.attr('data-agree-check-box');
        if ($this.prop('checked')) {
            $('[data-agree-submit=' + name + ']').prop('disabled', false).attr('data-disabled', 'false').removeClass('disabled');
        } else {
            $('[data-agree-submit=' + name + ']').prop('disabled', true).attr('data-disabled', 'true').addClass('disabled');
        }
    },
};

module.exports = validation;
