import inputs from './inputs';
import datepicker from './_vendor/datepicker.min';
import './_vendor/datepicker.en';


let datePicker = {
    run() {
        this.dateRange();
        this.default();
    },
    default() {
        $('[data-datepicker] > input').each(function (index) {
            const $this = $(this);
            const $wrapper = $this.closest('[data-datepicker]');
            $this.datepicker({
                autoClose: true,
                dateFormat: 'dd.mm.yyyy',
                language: $('[data-lang]').data('lang'),
                maxDate: $wrapper.data('max-date').length ? new Date($wrapper.data('max-date')) : '',
                minDate: setMinDateBDay({$input: $this}),
                navTitles: {
                    days: 'MM <i>yyyy</i>',
                    months: 'yyyy',
                    years: 'yyyy1 - yyyy2'
                },
                // inline: true,
                onSelect: function () {
                    $this.trigger('input');
                },
            });
        });

        function setMinDateBDay(props) {
            const $form = props.$input.closest('form');
            const $select = $form.find('select[name="UF_DOCUMENT"], select[name="documentType"]').first();
            const $bDay = $form.find('input[type="hidden"][name="BIRTHDAY"]');
            const FOREIGN_DOC = '14';
            let result = '';

            if (props.$input.attr('name') === 'UF_DOCUMENT_DATE' || props.$input.attr('name') === 'documentIssueDate') {
                if ($bDay.length && $bDay.val().length) {
                    const splitedBDay = $bDay.val().split('.');
                    const day = splitedBDay[0];
                    const month = splitedBDay[1];
                    const year = parseInt(splitedBDay[2]);
                    const minAge = 13;

                    if ($select.val() === FOREIGN_DOC) {
                        result = new Date(year + '-' + month + '-' + day);
                    } else {
                        result = new Date((year + minAge) + '-' + month + '-' + day);
                    }
                }
            }

            return result;
        }
    },

    dateRange() {
        $('[data-datepicker-range] > [data-f-field]').each(function () {
            const $this = $(this);
            const $box = $this.closest('[data-datepicker-range]');
            const $text = $box.find('[data-datepicker-range-text]');
            const $input = $box.find('[data-datepicker-range-input]');
            const $icon = $box.find('[data-datepicker-range-icon]');
            const $close = $box.find('[data-datepicker-range-close]');
            const $openbtn = $box.find('[data-datepicker-open-btn]');
            const isActiveAllMonth = $this.attr('data-active-month') === 'all';
            const calendarsCountAttr = parseInt($box.find('[data-calendar-count]').attr('data-calendar-count'));
            const calendarsCount = typeof calendarsCountAttr == 'number' && !isNaN(calendarsCountAttr) ? calendarsCountAttr : 2;
            const $wrap = $this.parents('[data-datepicker-range]');
            const isDesktop = window.innerWidth >= 768;
            const isMobile = window.innerWidth <= 1023;
            const $filterBtn = $('#set_filter');
            const maxDate = $this.attr('data-datepicker-max-date');
            let apiDates = $('[data-periods-api]').attr('data-periods-api');
            let datesCruises = datePicker.getDatesCruises(apiDates);
            let startDate = datePicker.getStartDateByYear(apiDates);
            let state = {
                checkClick: false,
                filterLoading: false,
                isClosed: true,
            };

            if ($openbtn.length) {
                $($icon).css('display', 'block');
                $($close).css('display', 'none');
            }

            if ($openbtn.length) {
                $openbtn.on('click', function () {
                    if (!state.checkClick) {
                        $wrap.addClass('select-open');
                        $this.focus();
                        state.checkClick = true;
                        if (isMobile && $('.c-filter').length > 0)
                            $('.datepickers-container .datepicker').addClass('datepicker--top');
                    } else {
                        $wrap.removeClass('select-open');
                        $this.focusout();
                        state.checkClick = false;
                    }
                });
            } else {
                $wrap.on('click', function () {
                    if (!state.checkClick) {
                        $wrap.addClass('select-open');
                        $this.focus();
                        state.checkClick = true;
                        if (isMobile && $('.c-filter').length > 0)
                            $('.datepickers-container .datepicker').addClass('datepicker--top');
                    } else {
                        $wrap.removeClass('select-open');
                        $this.focusout();
                        state.checkClick = false;
                    }
                });
            }
            if (isMobile) {
                $('[data-filter] .b-form').on('scroll', function () {
                    $wrap.removeClass('select-open');
                    $this.focusout();
                    state.checkClick = false;
                    $('.datepickers-container .datepicker').removeClass('active');
                });
            }
            const d = $this.datepicker({
                autoClose: true,
                position: ($('[data-main-filter]').length ? 'top' : 'bottom') + ' ' + (isDesktop ? "right" : "left"),
                offset: 8,
                dateFormat: 'dd.mm.yyyy',
                range: true, // Если true то выбирается диапазон дат
                language: $('[data-lang]').data('lang'),
                startDate: startDate,
                maxDate: typeof maxDate == 'string' && maxDate.length ? new Date(maxDate) : '',
                navTitles: {
                    days: 'MM <i>yyyy</i>',
                    months: 'yyyy',
                    years: 'yyyy1 - yyyy2'
                },
                // inline: true,
                onSelect: (formattedDate, date, i) => {
                    if (date.length === 2) {
                        setInputVal(formattedDate);
                        if ($openbtn.length) {
                            $($icon).css('display', 'none');
                            $($close).css('display', 'block');
                        }
                    } else if (date.length === 0)
                        clearInputVal();
                    else if (date.length === 1){
                        if($this.attr('name') === 'filter[cruise_start_date][]' 
                        || $this.attr('name') === 'filter[cruise_end_date][]'){
                            let singleDate
                            singleDate = formattedDate + ',' + formattedDate
                            $this.val(singleDate)
                        }
                        
                        setInputVal(formattedDate);
                    }

                    setLabelText(i);
                },
                onHide: () => {
                    $wrap.removeClass('select-open');
                    state.isClose = true;

                    if (!state.filterLoading || state.isClose)
                        unlockBtn();
                },
                onRenderCell: function (date, cellType) {                    
                    if (typeof datesCruises != 'undefined') {
                        //Получаем данные из API
                        let year = date.getFullYear();
                        if (year in datesCruises) {
                            let startCurDate = new Date(year, parseInt(datesCruises[year]['start']['month']) - 1, parseInt(datesCruises[year]['start']['day']));
                            let endDate = new Date(year, parseInt(datesCruises[year]['finish']['month']) - 1, parseInt(datesCruises[year]['finish']['day']));
                            if (cellType === 'year') {
                                return {
                                    disabled: false
                                }
                            } else if (cellType === 'month') {
                                if (date.getMonth() < startCurDate.getMonth() || date.getMonth() > endDate.getMonth()) {
                                    return {
                                        disabled: true
                                    }
                                }
                            } else if (date < startCurDate || date > endDate) {
                                return {
                                    disabled: true
                                }
                            }
                        } else {
                            return {
                                disabled: true
                            }
                        }
                    } else if (!isActiveAllMonth && datePicker.inactiveMonths.includes(date.getMonth())) {
                        return {
                            disabled: true
                        }
                    }
                },

                calendars: isDesktop ? calendarsCount : 1,
                moveToOtherMonthsOnSelect: false,
            });

            if ($openbtn.length) {
                $close.on('click', function () {
                    clearInputVal();

                    $text.each((i, item) => {
                        $(item).text('');
                    });

                    $($icon).css('display', 'block');
                    $($close).css('display', 'none');

                    d.datepicker().data('datepicker').clear()

                    const $label = $box.find('[data-f-label]');
                    $label.removeClass('active');
                });
            }

            if ($input.length) {
			    setInitialDates();
			}           

            if (this.value.length) {
                
                const datesArr = this.value.split(",")

                $text.each((i, item) => {
                    const getDate = i => {
                        const $item = $($input[i]);
                        let res = datesArr[i] ? datesArr[i] : ''
    
                        if (!res)
                            return false;
    
                        res = res.split('.');
    
                        return `${res[2]}-${res[1]}-${res[0]}`
                    };
                    
                    d.datepicker().data('datepicker').selectDate([new Date(getDate(0)), new Date(getDate(1))]);
                });
            }

            function setLabelText(i) {
                const date = (n) => i.selectedDates[n];
                const month = (n) => i.loc.monthsShort[n];
                const isMainFilter = !!i.$el.closest('.m-filter').length;
                const $box = i.$el.closest('[data-f-item]');
                const $label = $box.find('[data-f-label]');

                $text.each((i, item) => {
                    const setText = (text) => $(item).text(text);

                    if (date(i))
                        setText(`${date(i).getDate()} ${month(date(i).getMonth())}`);
                    else
                        setText('');
                });

                if ($text[0].innerText === $text[1].innerText) {
                    $($text[1]).css('visibility', 'hidden');
                } else {
                    $($text[1]).css('visibility', 'visible');
                }

                if (isMainFilter) {
                    if ($label.length && ($text[0].innerText !== '' || $text[1].innerText !== '')) {
                        $label.addClass('active');
                    } else {
                        $label.removeClass('active');
                    }
                }
            }
            function setInputVal(date) {
                state.filterLoading = true;

                let myDate = date.split(',');

                $input.each((i, item) => {
                    setTimeout(function () {

                        if (myDate[0] === undefined) {
                            myDate[0] = myDate[1];
                        } else if (myDate[1] === undefined) {
                            myDate[1] = myDate[0];
                        }

                        $(item).val(myDate[i]);

                        $(item).trigger('change');

                        if (i === 1)
                            filterLoadingEnd()
                    }, 100 * i)
                });
            }
            function clearInputVal() {
                state.filterLoading = true;

                $input.each((i, item) => {
                    setTimeout(function () {
                        $(item).val('');

                        $(item).trigger('change');

                        if (i === 1)
                            filterLoadingEnd()
                    }, 100 * i)
                });
            }
            function setInitialDates() {
                const getDate = i => {
                    const $item = $($input[i]);
                    let res = $item.val() ? $item.val() : ''/*$item.attr('placeholder')*/;

                    if (!res)
                        return false;

                    res = res.split('.');

                    return `${res[2]}-${res[1]}-${res[0]}`
                };
                if (getDate(1)) {
                    d.datepicker().data('datepicker').selectDate([new Date(getDate(0)), new Date(getDate(1))]);
                    inputs.placeholder();
                }
            }
            function unlockBtn() {
                $filterBtn.prop('disabled', false);
            }
            function filterLoadingEnd() {
                state.filterLoading = false;

                if (state.isClosed)
                    unlockBtn();
            }
        });
        // $('[data-datepicker-range] > [data-f-field]').each(function () {
        //     let $this = $(this);
        //     let date = $this.selectedDates;
        //     let $box = $this.closest('[data-datepicker-range]');
        //     let $text = $box.find('[data-datepicker-range-text]');
        //     let i = $(this).val()
        //     let splitDate
        //     splitDate = i.split(',')
        //     if(splitDate.length === 2){
        //         $text.each((i, item) => {
        //             $(item).text(splitDate[i])
        //         })
        //     }else{
        //         $text.text(i)
        //     }
        // })
    },

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

    getStartDateByYear(apiDates) {
		let year = new Date().getFullYear();
		let datesCruises = datePicker.getDatesCruises(apiDates);
        let startDate, startDateCurrentYear, endDateCurrentYear;
        let newDate = new Date();
        let month = newDate.getMonth();

        function calcStartDate() {
            let newDate, newStartDate;
            if (typeof datesCruises != 'undefined') {
                newDate = new Date();
                for (year in datesCruises) {
                    newStartDate = new Date(year, parseInt(datesCruises[year]['start']['month']) - 1, parseInt(datesCruises[year]['start']['day']));
                    if (newStartDate > newDate) {
                        return newStartDate;
                    }
                }
            } else {
                newDate = new Date();
                newDate.setDate(1);

                while (datePicker.inactiveMonths.includes(month))
                    incMonth();

                function incMonth() {
                    month++;

                    if (month > 12)
                        month = 0;
                }

                newDate = new Date(newDate.setMonth(month));

            }
            return newDate;
        };

        if (typeof datesCruises != 'undefined' && datesCruises[year]) {
            startDateCurrentYear = new Date(year, parseInt(datesCruises[year]['start']['month']) - 1, parseInt(datesCruises[year]['start']['day']));
            endDateCurrentYear = new Date(year, parseInt(datesCruises[year]['finish']['month']) - 1, parseInt(datesCruises[year]['finish']['day']));
            startDate = ((newDate < endDateCurrentYear) && (newDate > startDateCurrentYear)) ? new Date() : calcStartDate();
        } else {
            startDate = datePicker.inactiveMonths.includes(month) ? calcStartDate() : new Date();

        }
        return startDate;
    },
    inactiveMonths: [0, 1, 2, 3, 10, 11, 12],

    getDatesCruises(apiDates) {
        if (typeof apiDates != 'undefined' && datePicker.isJsonString(apiDates)) {
            return JSON.parse(apiDates);
        }
    }
};


module.exports = datePicker;



