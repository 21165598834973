import {CrsClient} from "../crs-client";

class CrsUser extends CrsClient
{
    getCurrent(params = {}) {
        return this.service.get('/users/current', {params})
    }

    getOne(id, params = {}) {
        return this.service.get(`/users/one/${id}`, {params})
    }

    updateUser(id, data) {
        return this.service.post(`/users/update-user/${id}`, data)
    }

    documentTypes(params = {}) {
        return this.service.get('/users/document-types', {params})
    }
}

const crsUser = new CrsUser()
export default crsUser
