let sendPdfPageForm = {
    run() {
        $(document).on('submit', '[data-send-page-form]', event => {
            event.preventDefault();
            event.stopPropagation();

            if (!window.App.validation.validate($(event.target)))
                return false;

            BX.showWait();
            BX.ajax.runComponentAction('citfact:pdf.page.send', 'sendPage', {
                mode: 'class',
                data: new FormData(event.target)
            }).then(function (response) {
                BX.closeWait();
                $('[data-send-page-error]').hide();
                if (typeof response.data.errors !== "undefined" && response.data.errors.length > 0) {
                    $('[data-send-page-error]').html(response.data.errors).fadeIn();
                }
                else{
                    $('[data-send-page-form]').hide();
                    if (response.data) {
                        $('[data-send-page-success]').show();
                    } else {
                        $('[data-send-page-error]').show();
                    }
                }
            });
        });
    },
}

module.exports = sendPdfPageForm;