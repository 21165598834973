const pathGalleryOpener = {
    run() {
      if($('[data-gallery-path-opener]').length) {
          $('[data-gallery-path-opener]').on('click', (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.onOpenerClick({
                  $btn: $(evt.currentTarget),
              });
          });
      }
    },

    onOpenerClick({$btn}) {
        const $box = $btn.closest('[data-gallery-main-box]');
        const $gallery = $box.find('.body-content__preview[data-modal="gallery"]');
        const $btns = $gallery.find('[data-modal-image-btn]');
        const items = [];

        $btns.each((i, item) => {
            items.push({
                src: $(item).attr('href'),
                type: $(item).data('modal') === 'video' ? 'iframe': 'image',
            })
        });

        if($gallery.length) {
            $.magnificPopup.open({
                items: items,
                type: 'image',
                gallery: {
                    enabled: true,
                    tCounter: '%curr% / %total%' // markup of counter
                },
                preloader: false,
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'hidden',
                mainClass: "mfp-fade",
                showCloseBtn: false,
                removalDelay: 300,
                callbacks: {},
            }, 0);
        }
    },
};

module.exports = pathGalleryOpener;