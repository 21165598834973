const cruiseList = {
    run() {
        this.$lists = $('[data-cruise-list]');

        if(!this.$lists.length) return false;

        this.$lists.each((index, list) => {
            const $items = $(list).find('[data-cruise-list-item]');
            const count = parseInt($(list).attr('data-cruise-list-show-count'));
            this.DISPLAY_ITEM_COUNT = typeof count === 'number' ? count : 3;

            if($items.length > this.DISPLAY_ITEM_COUNT) {
                this.initList({$list: $(list)});
            }
        });
    },

    initList({$list}) {
        const $opener = $list.find('[data-cruise-list-opener]');

        this.hideItems({$list: $list});

        $opener.on('click', (evt) => {
            if($list.attr('data-open') !== 'true') {
                this.showItems({$list: $list});
            } else {
                this.hideItems({$list: $list});
            }
        });
    },

    hideItems({$list}) {
        const $items = $list.find('[data-cruise-list-item]');
        const $opener = $list.find('[data-cruise-list-opener]');

        $list.attr('data-open', 'false');
        $items.each((index, item) => {
            if(index + 1 > this.DISPLAY_ITEM_COUNT) {
                $(item).hide();
            }
        });
        $opener.html($opener.attr('data-close'));
    },

    showItems({$list}) {
        const $items = $list.find('[data-cruise-list-item]');
        const $opener = $list.find('[data-cruise-list-opener]');

        $list.attr('data-open', 'true');
        $items.each((index, item) => {
            if(index + 1 > this.DISPLAY_ITEM_COUNT) {
                $(item).show();
            }
        });
        $opener.html($opener.attr('data-open'));
    },
};

module.exports = cruiseList;