import lazy from '../lazy';
import Swiper from 'swiper/dist/js/swiper.min.js';

let sliders = {
	run() {

		this.init();

		this.detailSliders();
		this.directionsSlider();
		this.mapCruisesSlider();
		this.cruisesFromSlider();
		this.shipSlider();
		this.citySlider();
		this.souvenirsSlider();
		this.cardModalSlider();
		this.convSliders();
		this.shipDetailSlider();
		this.offersSlider();
		this.photosSlider();
		this.shipDetailSliderNEW();
		this.shipServiceSliderNEW();
		this.shipDetailSliderEx();
		this.cBlogSlider();
		this.blogListSlider();
		this.mediaListSlider();
		this.newsListSlider();
		this.shipsClassesSlider();
		this.lkProSlider();
		this.cruiseNewShipSlider();
		this.reviewsEx();
	},

	init() {
		this.sliders.forEach(slider => $(`${slider.selector}:not(.swiper-container-initialized):visible`).each((i, item) => new Swiper(item, slider.options)));
	},

	shipsClassesSlider() {
		const $slider = $('[data-slider="ships-classes"]');
		const MOBILE_WIDTH = $slider.attr('data-slider-mobile') || 767;

		const classMainImg = '.ex-ships__class-img'
		const classPicture = '.ex-ships__class-ship-img'
		const classPreload = '.swiper-lazy-preloader'

		if(!$slider.length) return false;
		if($(window).innerWidth() > MOBILE_WIDTH) {
			$slider.find(`${classMainImg} ${classPreload}`).remove();
			$slider.find(`${classPicture} ${classPreload}`).remove();
			return false;
		}

		$slider.children().wrapAll('<div class="swiper-wrapper">');
		$slider.find(classMainImg).removeClass('lazy');
		$slider.find(classPicture).removeClass('lazy');
		$slider.find('.swiper-wrapper').wrap('<div class="swiper-container">');
		$slider.find('.ex-ships__classes-slide').addClass('swiper-slide');
		$slider.find('.swiper-container').append('<div class="swiper-pagination"></div>');

		const shipsCLassesSlider = new Swiper('[data-slider="ships-classes"] .swiper-container', {
			slidesPerView: $(window).innerWidth() < 599 ? 'auto' : 2,
			spaceBetween: 10,
			autoHeight: true,
			loop: true,
			lazy: true,
			preloadImages: false,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
			},
		});
	},

	detailSliders() {
		const $previews = $('[data-slider="detail-s"] .swiper-slide');

		if (!$previews.length)
			return;

		/* превью */
		const previewSlider = new Swiper('[data-slider="detail-s"]', {
			slidesPerView: 3,
			spaceBetween: 20,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			resistanceRatio: 0,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			on: {
				'init': function () {
					let $previewSlider = $('[data-slider="detail-s"]').parent();
					let $slides = $('[data-slider="detail-s"]').find('.swiper-slide');
					let arrowWidth = $previewSlider.find('[data-slider-arrow-p="detail-s"]').parent().innerWidth();
					let widthSlider = 0;
					widthSlider += arrowWidth;

					if ($slides.length == 2) {
						$slides.each(function (ind, iter) {
							widthSlider += iter.clientWidth;
							widthSlider += parseInt(iter.style.marginRight);
						})
						widthSlider += 15;
						$previewSlider.width(widthSlider);
					}
				},
				'transitionEnd': function () {

				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="detail-s"]',
				nextEl: '[data-slider-arrow-n="detail-s"]',
				disabledClass: 'disabled'
			},
			breakpoints: {
				/*767: {
					spaceBetween: 0,
					slidesPerView: 'auto',
				},
				1023: {
					slidesPerView: 1,
				},*/
				1400: {
					spaceBetween: 15,
				},
			}
		});

		/* основной слайдер */
		new Swiper('[data-slider="detail-m"]', {
			effect: 'fade',
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 3,
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="detail-s"]',
				nextEl: '[data-slider-arrow-n="detail-s"]',
				disabledClass: 'disabled'
			},
			on: {
				'transitionStart': function () {
					$previews.removeClass('active');
					$($previews[this.activeIndex]).addClass('active');
				}
			},
			thumbs: {
				swiper: previewSlider,
			},
		});
	},

	blogListSlider() {
		if(!$('[data-slider="blog-list"]').length) return false;

		if(typeof $('[data-slider="blog-list"]').attr('data-mobile') != 'string') {
			console.error(`Add attr data-mobile for ${$('[data-slider="blog-list"]')}`);
			return false;
		}

		if(typeof parseInt($('[data-slider="blog-list"]').attr('data-mobile')) != 'number') {
			console.error(`attr data-mobile must be a number in ${$('[data-slider="blog-list"]')}`);
			return false;
		}

		const self = this;
		const MOBILE_SIZE = parseInt($('[data-slider="blog-list"]').attr('data-mobile'));

		if($(window).innerWidth() <= MOBILE_SIZE) {
			self.runBlogListSlider();
		}

	},

	runBlogListSlider() {
		const $slider = $('[data-slider="blog-list"]');

		if($slider.hasClass('swiper-container-initialized')) return false;

		$slider.addClass('swiper-container');
		$slider.children().addClass('swiper-slide');
		$slider.children().wrapAll('<div class="swiper-wrapper"></div>');

		const blogSlider = new Swiper('[data-slider="blog-list"]', {
			slidesPerView: "auto",
			spaceBetween: 10,
			centeredSlides: true,
			loop: true,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			breakpoints: {}
		});
	},

	newsListSlider() {
		if(!$('[data-slider="news-list"]').length) return false;

		if(typeof $('[data-slider="news-list"]').attr('data-mobile') != 'string') {
			console.error(`Add attr data-mobile for ${$('[data-slider="news-list"]')}`);
			return false;
		}

		if(typeof parseInt($('[data-slider="blog-list"]').attr('data-mobile')) != 'number') {
			console.error(`attr data-mobile must be a number in ${$('[data-slider="news-list"]')}`);
			return false;
		}

		const self = this;
		const MOBILE_SIZE = parseInt($('[data-slider="news-list"]').attr('data-mobile'));

		if($(window).innerWidth() <= MOBILE_SIZE) {
			self.runNewsListSlider();
		}

	},

	runNewsListSlider() {
		const $containers = $('[data-slider="news-list"]');

		$containers.each(function (index, item) {
			const $container = $(item);
			if($container.hasClass('initialized')) return false;

			$container.children().not(':first-child').addClass('swiper-slide');
			$container.children().not(':first-child').wrapAll('<div class="swiper-wrapper"></div>');
			const $slider = $container.find('.swiper-wrapper').wrap('<div class="swiper-container"></div>');

			const newsSlider = new Swiper('[data-slider="news-list"] .swiper-container', {
				slidesPerView: 'auto',
				//spaceBetween: 10,
				observer: true,
				observeParents: true,
				// loop: true,
				//autoHeight: true,
				on: {
					init: function () {
						this.$el.closest('[data-slider="news-list"]').addClass('initialized');
					},
				},
				breakpoints: {}
			});
		});
	},

	mediaListSlider() {
		if(!$('[data-slider="media-list"]').length) return false;

		if(typeof $('[data-slider="media-list"]').attr('data-mobile') != 'string') {
			console.error(`Add attr data-mobile for ${$('[data-slider="blog-media"]')}`);
			return false;
		}

		if(typeof parseInt($('[data-slider="media-list"]').attr('data-mobile')) != 'number') {
			console.error(`attr data-mobile must be a number in ${$('[data-slider="media-list"]')}`);
			return false;
		}

		const self = this;
		const MOBILE_SIZE = parseInt($('[data-slider="media-list"]').attr('data-mobile'));

		if($(window).innerWidth() <= MOBILE_SIZE) {
			self.runMediaListSlider();
		}

	},

	runMediaListSlider() {
		const $slider = $('[data-slider="media-list"]');

		if($slider.hasClass('swiper-container-initialized')) return false;

		$slider.addClass('swiper-container');
		$slider.children().addClass('swiper-slide');
		$slider.children().wrapAll('<div class="swiper-wrapper"></div>');
		$slider.find('.c-media__img').addClass('swiper-lazy');

		const mediaSlider = new Swiper('[data-slider="media-list"]', {
			slidesPerView: "auto",
			spaceBetween: 10,
			centeredSlides: true,
			loop: true,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			breakpoints: {}
		});
	},

	shipDetailSliderNEW() {
		const $previews = $('[data-slider="detail-thumbs"] .swiper-slide');

		/* основной слайдер */
		const imageSlider = new Swiper('[data-slider="detail-images"]', {
			// effect: 'fade',
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 3,
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="detail-images"]',
				nextEl: '[data-slider-arrow-n="detail-images"]',
				disabledClass: 'disabled'
			},
			// on: {
			//     'transitionStart': function () {
			//         $previews.removeClass('active');
			//         $($previews[this.activeIndex]).addClass('active');
			//     }
			// },
			// thumbs: {
			//     swiper: previewSlider,
			// },
			loop: true,
			loopAdditionalSlides: 4,
			noSwiping: true,
			noSwipingClass: 'swiper-slide'
		});

		let zoom = document.querySelector('.ship-gallery__block-zoom');
		if (zoom) {
			zoom.addEventListener('click', () => {
				let swiper = document.querySelector('.ship-gallery__slider');
				let activeSlide = swiper.querySelector('.swiper-slide-active');
				let link = activeSlide.getElementsByTagName('a')
				link[0].click()
			})
		}

		if ($previews.length) {
			/* превью */
			const previewSlider = new Swiper('[data-slider="detail-thumbs"]', {
				slidesPerView: 4,
				spaceBetween: 20,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 2,
				},
				resistanceRatio: 0,
				slideToClickedSlide: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				navigation: {
					prevEl: '[data-slider-arrow-p="detail-thumbs"]',
					nextEl: '[data-slider-arrow-n="detail-thumbs"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					/*767: {
						spaceBetween: 0,
						slidesPerView: 'auto',
					},*/
					1023: {
						slidesPerView: 4,
					},
					1400: {
						spaceBetween: 15,
					},
				},
				loop: true,
				centeredSlides: true,
				loopAdditionalSlides: 4,
			});
			previewSlider.controller.control = imageSlider;
			imageSlider.controller.control = previewSlider;
		}
	},

	shipServiceSliderNEW() {
		const imageSlider = new Swiper('[data-slider="service-m"]', {
			effect: 'fade',
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 3,
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="service-m"]',
				nextEl: '[data-slider-arrow-n="service-m"]',
				disabledClass: 'disabled'
			},
			pagination: {
				el: '[data-slider-p="service-m"]',
				type: 'bullets',
				renderBullet: function (index, className) {
					return '<span class="' + className + '" tabindex="' + index + '"></span>';
				},
				// clickable: true,
			},
		});

		$(document).on('keyup', function (evt) {
			if(!$('.ship-service__slider').length) return false;
			if(evt.keyCode == 39)
			{
				$('[data-slider="service-m"]')[0].swiper.slideNext();
			}
			else if(evt.keyCode == 37)
			{
				$('[data-slider="service-m"]')[0].swiper.slidePrev();
			}
		});
	},

	directionsSlider() {
		const breakpoints = (() => {
			if (window.innerWidth < 1280)
				return 2;
			else if (window.innerWidth < 1800)
				return 3;
			else
				return 4
		})();

		if ($('[data-slider="dir-second-preview"] .swiper-slide').length < breakpoints)
			$('.dir-slider__arrows').hide();

		/* превью */
		new Swiper('[data-slider="dir-second-preview"]', {
			direction: 'vertical',
			spaceBetween: 7,
			slidesPerView: 4,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			on: {
				'init': function () {
					lazy.update();
				},
				'transitionEnd': function () {
					lazy.update();
				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="dir-second-preview"]',
				nextEl: '[data-slider-arrow-n="dir-second-preview"]',
				disabledClass: 'disabled'
			},
			breakpoints: {
				1800: {
					slidesPerView: 3,
				},
				1279: {
					slidesPerView: 2,
				},
				1023: {
					direction: 'horizontal',
					slidesPerView: 'auto',
				}
			}
		});
		/* основной слайдер */


		/*new Swiper('[data-slider="dir-second"]', {
			slidesPerView: '1',
			speed: 1000,
			loop: true,
			effect: 'fade',
			watchSlidesVisibility: true,
			on: {
				'init': function () {
					lazy.update()
				},
				'transitionEnd': function () {
					lazy.update()
				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="dir-second"]',
				nextEl: '[data-slider-arrow-n="dir-second"]',
				disabledClass: 'disabled'
			},
			thumbs: {
				swiper: previewSlider,
			},
		});*/
	},

	mapCruisesSlider() {

		/* превью */
		const previewSlider = new Swiper('[data-slider="map-cruises-preview"]', {
			direction: 'vertical',
			spaceBetween: 15,
			slidesPerView: 4,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			on: {
				'init': function () {
					lazy.update();
				},
				'transitionEnd': function () {
					lazy.update();
				}
			},
			breakpoints: {
				1800: {
					slidesPerView: 'auto',
				},
				1023: {
					direction: 'horizontal',
					slidesPerView: 'auto',
					spaceBetween: 7,
				}
			}
		});

		/* основной слайдер */
		new Swiper('[data-slider="map-cruises"]', {
			slidesPerView: '1',
			speed: 1000,
			loop: true,
			effect: 'fade',
			watchSlidesVisibility: true,
			on: {
				'init': function () {
					lazy.update()
				},
				'transitionEnd': function () {
					lazy.update()
				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="map-cruises"]',
				nextEl: '[data-slider-arrow-n="map-cruises"]',
				disabledClass: 'disabled'
			},
			thumbs: {
				swiper: previewSlider,
			},
		});
	},

	cruisesFromSlider() {
		const breakpoints = (() => {
			if (window.innerWidth < 767)
				return 2;
			else if (window.innerWidth < 1023)
				return 3;
			else if (window.innerWidth < 1279)
				return 4;
			else if (window.innerWidth < 1800)
				return 5;
			else
				return 6
		})();

		if ($('[data-slider="cruises-from"] .swiper-slide').length < breakpoints) {
			$('.dep-city-slider__arrows').hide();
			$('.dep-city-slider__main').addClass('dep-city-slider__main--top');

		}

		new Swiper('[data-slider="cruises-from"]', {
			direction: 'horizontal',
			spaceBetween: 16,
			slidesPerView: 6,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			on: {
				'init': function () {
					lazy.update();
				},
				'transitionEnd': function () {
					lazy.update();
				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="cruises-from"]',
				nextEl: '[data-slider-arrow-n="cruises-from"]',
				disabledClass: 'disabled'
			},
			breakpoints: {
				1800: {
					slidesPerView: 5,
				},
				1279: {
					slidesPerView: 4,
				},
				1023: {
					slidesPerView: 3,
				},
				767: {
					slidesPerView: 2,
				},
				599: {
					slidesPerView: 'auto',
				}
			}
		});
	},

	shipSlider() {
		const shipSl = new Swiper('[data-slider="ship"]', {
			direction: 'vertical',
			mousewheel: true,
			speed: 700,
			slidesPerView: 1,
			on: {
				'transitionEnd': function () {
					lazy.update();
				}
			},
			pagination: {
				el: '[data-slider-p="ship"]',
				type: 'bullets',
				renderBullet: function (index, className) {
					return '<span class="' + className + '" tabindex="' + index + '"><svg class="i-icon"><use xlink:href="#icon-pagination"/></svg></span>';
				},
				clickable: true,
			},
			breakpoints: {
				767: {
					direction: 'horizontal',
					autoHeight: true,
				}
			}
		});

		function onWheel(e) {
			let delta = e.deltaY || e.detail || e.wheelDelta;
			let headerHeight = $('.h__bottom.fixed').height() ? $('.h__bottom.fixed').height() : 0;
			let top = e.target.getBoundingClientRect().top;

			if ((delta > 0) && (shipSl.activeIndex !== (shipSl.slides.length - 1))) {
				setTimeout(() => shipSl.mousewheel.enable());
			} else if ((shipSl.activeIndex === (shipSl.slides.length - 1)) && (delta > 0)) {
				setTimeout(() => shipSl.mousewheel.disable(), 200);
			} else if ((delta < 0) && (top - headerHeight < 10)) {
				setTimeout(() => shipSl.mousewheel.disable());
			} else if ((delta < 0) && (top - headerHeight >= 0)) {
				setTimeout(() => shipSl.mousewheel.enable());
			} else if ((shipSl.activeIndex === (shipSl.slides.length - 1)) && (delta < 0)) {
				setTimeout(() => shipSl.mousewheel.enable());
			}
		};

		let el = $('[data-slider="ship"]');
		if (el[0]) {
			el[0].addEventListener('wheel', onWheel);
		}
	},

	offersSlider() {
		const offersSl = new Swiper('[data-slider="offers"]', {
			slidesPerView: 4,
			spaceBetween: 20,
			loop: true,
			simulateTouch: false,
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				1400: {
					slidesPerView: 3,
				},
				1024: {
					spaceBetween: 10,
					slidesPerView: 2,
				},
				640: {
					slidesPerView: 1,
					spaceBetween: 10,
				},
			},
			on: {
				slideChange: function () {
					if (window.blazy) {
						window.blazy.revalidate();
					}
				},
			},
		});
	},

	photosSlider() {
		const photosSl = new Swiper('[data-slider="photos"]', {
			slidesPerView: 'auto',
			spaceBetween: 0,
			centeredSlides: true,
			loop: true,
			simulateTouch: false,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			on: {
				'transitionEnd': function () {
					lazy.update();
				}
			}
		});
	},
	citySlider() {
		const citySl = new Swiper('[data-slider="city"]', {
			mousewheel: true,
			speed: 700,
			spaceBetween: 16,
			slidesPerView: 6,
			navigation: {
				prevEl: '[data-slider-city-p]',
				nextEl: '[data-slider-city-n]',
				disabledClass: 'disabled'
			},
			breakpoints: {
				1279: {
					slidesPerView: 5,
				},
				1024: {
					slidesPerView: 3,
				},

				768: {
					slidesPerView: 2,
				},

				600: {
					slidesPerView: 'auto',
				},
			},
		});
	},

	shipDetailSlider() {
		new Swiper('[data-slider="d-ship"]', {
			slidesPerView: 'auto',  /* в стилях на псевдо */
			spaceBetween: 20,
			on: {
				'transitionEnd': function () {
					lazy.update()
				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="d-ship"]',
				nextEl: '[data-slider-arrow-n="d-ship"]',
				disabledClass: 'disabled'
			},
			breakpoints: {
				767: {
					spaceBetween: 4,
				},
			}
		})
	},

	shipDetailSliderEx() {
		new Swiper('[data-slider="d-ship-ex"]', {
			slidesPerView: 2,
			spaceBetween: 20,
			on: {
				'transitionEnd': function () {
					lazy.update()
				}
			},
			navigation: {nextEl: ".swiper-promo-next", prevEl: ".swiper-promo-prev"},
			breakpoints: {
				//320: {slidesPerView: 1, spaceBetweenSlides: 20},
				//480: {slidesPerView: 2, spaceBetweenSlides: 20},
				767: {slidesPerView: "auto", spaceBetweenSlides: 20},
				1070: {slidesPerView: 3, spaceBetweenSlides: 20},
				1280: {slidesPerView: 2, spaceBetweenSlides: 20}
			}
		})
	},

	cruiseNewShipSlider() {
		new Swiper('[data-slider="cruise-new-ship-slider"]', {
			slidesPerView: "auto",
			spaceBetween: 20,
			on: {
				'transitionEnd': function () {
					lazy.update()
				}
			},
			navigation: {nextEl: ".swiper-promo-next", prevEl: ".swiper-promo-prev"},
			breakpoints: {
				768: {spaceBetween: 8},
			}
		})
	},

	updDataSlidingStatus({swiper}) {
		if(this.isEnd) {
			this.$el.attr('data-sliding', 'end');
		} else if(this.isBeginning){
			this.$el.attr('data-sliding', 'start');
		} else {
			this.$el.attr('data-sliding', '');
		}
	},

	reviewsEx() {
		const self = this;

		const reviewsSlider = new Swiper('[data-slider="reviews-ex"]', {
			spaceBetween: 20,
			slidesPerView: 2.6,
			navigation: {
				nextEl: '.swiper-test-next',
				prevEl: '.swiper-test-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetweenSlides: 10
				},
				1280: {
					slidesPerView: 2,
					spaceBetweenSlides: 10
				},
				1440: {
					slidesPerView: 2.6,
					spaceBetweenSlides: 10
				},
			}
		})
	},

	cBlogSlider() {
		new Swiper('[data-slider="cBlog"]', {
			slidesPerView: 4,
			spaceBetween: 16,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
			},
			navigation: {
				prevEl: '.slider__arrow--prev',
				nextEl: '.slider__arrow--next',
				disabledClass: 'disabled'
			},
			breakpoints: {
				1200: {
					slidesPerView: 3,
				},
				992: {
					slidesPerView: 2.5,
				},
				768: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 1.5,
				},
				480: {
					slidesPerView: 1,
				}
			}
		})
	},

	souvenirsSlider() {

		/* превью */
		const previewSlider = new Swiper('[data-slider="souvenirs-m-preview"]', {
			spaceBetween: 7,
			slidesPerView: 3,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			on: {
				'init': function () {
					lazy.update();
				},
				'transitionEnd': function () {
					lazy.update();
				}
			},
			breakpoints: {
				1279: {
					slidesPerView: 2,
				},
				1023: {
					slidesPerView: 3,
				}
			}
		});

		/* основной слайдер */
		new Swiper('[data-slider="souvenirs-m"]', {
			slidesPerView: 1,
			loop: true,
			watchSlidesVisibility: true,
			on: {
				'init': function () {
					lazy.update()
				},
				'transitionEnd': function () {
					lazy.update()
				}
			},
			navigation: {
				prevEl: '[data-slider-arrow-p="souvenirs-m"]',
				nextEl: '[data-slider-arrow-n="souvenirs-m"]'
			},
			thumbs: {
				swiper: previewSlider,
			},
		});
	},

	cardModalSlider() {
		/* основной слайдер с картинками в модалке экскурсий */
		new Swiper('[data-slider="card-m-preview"]', {
			spaceBetween: 10,
			slidesPerView: 5,
			navigation: {nextEl: ".swiper-promo-next", prevEl: ".swiper-promo-prev"},
			lazy: false,
			breakpoints: {
				640: {
				  slidesPerView: 2,
				  spaceBetween: 10,
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			  },
		});
		
		/* основной слайдер (закоменчен,изменен дизайн)*/
		// new Swiper('[data-slider="card-m"]', {
		// 	slidesPerView: 1,
		// 	watchSlidesVisibility: true,
		// 	on: {
		// 		'init': function () {
		// 			lazy.update()
		// 		},
		// 		'transitionEnd': function () {
		// 			lazy.update()
		// 		}
		// 	},
		// 	navigation: {
		// 		prevEl: '[data-slider-arrow-p="card-m"]',
		// 		nextEl: '[data-slider-arrow-n="card-m"]',
		// 		disabledClass: 'disabled'
		// 	},
		// 	thumbs: {
		// 		swiper: previewSlider,
		// 	}
		// });

		/*слайдер с текстом*/
		function adjustTextScrollWidth() {
			let textScrollWidth;
			if (window.innerWidth >= 1097) {
				textScrollWidth = 1040;
			} else if (window.innerWidth >= 767) {
				textScrollWidth = 720;
			} else if (window.innerWidth >= 600) {
				textScrollWidth = 570;
			} else if (window.innerWidth >= 380) {
				textScrollWidth = 370;
			} else {
				textScrollWidth = 290;
			}
		
			let $textSlider = $('[data-text-slider="card-m"]');
			$('[data-text-slider-p="card-m"]').on('click', function () {
				let textScrollLeft = $textSlider.scrollLeft();
				$textSlider.scrollLeft(textScrollLeft - textScrollWidth);
			});
			$('[data-text-slider-n="card-m"]').on('click', function () {
				let textScrollLeft = $textSlider.scrollLeft();
				$textSlider.scrollLeft(textScrollLeft + textScrollWidth);
			});
		}
		
		// Call the function on initial page load
		adjustTextScrollWidth();
		
		// Call the function whenever the window is resized
		window.addEventListener('resize', adjustTextScrollWidth);
		
	},

	convSliders() {
		let allConvSlider = $('[data-slider]');
		allConvSlider.each((ind, iter) => {
			if ($(iter).attr('data-slider').includes('ship-conv')) {
				let prev = '[data-slider-arrow-p="' + $(iter).attr('data-slider') + '"]';
				let next = '[data-slider-arrow-n="' + $(iter).attr('data-slider') + '"]';
				new Swiper($(iter), {
					slidesPerView: 1,
					watchSlidesVisibility: true,
					loop: true,
					on: {
						'init': function () {
							lazy.update();
						},
						'transitionEnd': function () {
							lazy.update();
						}
					},
					navigation: {
						prevEl: prev,
						nextEl: next
					}
				});
			}
		});
	},

	sliders: [
		{
			selector: '[data-slider="vertical-slider"]',
			options: {
				// direction: $(window).width() > 767 ? 'vertical' : 'horizontal',
				direction: 'vertical',
				slidesPerView: 'auto',
				mousewheel: true,
				spaceBetween: 10,
				navigation: {
					prevEl: '[data-slider-arrow-p="vertical-slider"]',
					nextEl: '[data-slider-arrow-n="vertical-slider"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					768: {
						direction: 'horizontal',
						slidesPerView: 2,
					},
					425: {
						direction: 'horizontal',
						slidesPerView: 1,
					},
				}
			}
		},
		{
			selector: '[data-slider="m-sale"]',
			options: {
				slidesPerView: 'auto',  /* в стилях на псевдо */
				watchSlidesVisibility: true,
				on: {
					'init': function () {
						let $routeBlocks = $('[data-slider="m-sale"] .m-sale__stops--m');

						if ($routeBlocks.length > 0) {
							let routeBlocksHeight = $routeBlocks.map(function () {
								return $(this).height();
							}).get();

							let routeBlocksMaxHeight = Math.max.apply(null, routeBlocksHeight);

							$routeBlocks.each(function () {
								$(this).height(routeBlocksMaxHeight);
							});
						}
					},
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="m-sale"]',
					nextEl: '[data-slider-arrow-n="m-sale"]',
					disabledClass: 'disabled'
				},
			}
		},
		{
			selector: '[data-slider="similar"]',
			options: {
				slidesPerView: 3,
				spaceBetween: 30,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="similar"]',
					nextEl: '[data-slider-arrow-n="similar"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1023: {
						slidesPerView: 'auto',
						spaceBetween: 0,
					},
					1799: {
						slidesPerView: 'auto',
						spaceBetween: 15,
					},

				}
			}
		},
		{
			selector: '[data-slider="similar-lk"]',
			options: {
				slidesPerView: 'auto',  /* в стилях на псевдо */
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="similar-lk"]',
					nextEl: '[data-slider-arrow-n="similar-lk"]',
					disabledClass: 'disabled'
				}
			}
		},
		{
			selector: '[data-slider="m-ships"]',
			options: {
				slidesPerView: '1',
				speed: $(window).innerWidth() < 1024 ? 200 : 1000,
				effect: 'fade',
				autoHeight: $(window).innerWidth() < 1024,
				on: {
					'init': function () {
						lazy.update();
					},
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="m-ships"]',
					nextEl: '[data-slider-arrow-n="m-ships"]',
					disabledClass: 'disabled'
				},
				/*breakpoints: {
					767: {
						spaceBetween: 0,
						slidesPerView: 'auto',
					},
					1023: {
						slidesPerView: 1,
					},
					1799: {
						slidesPerView: 2,
					},
				}*/
			}
		},
		{
			selector: '[data-slider="c-excursions"]',
			options: {
				slidesPerView: 'auto',
				watchSlidesVisibility: true,
				//loop: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="c-excursions"]',
					nextEl: '[data-slider-arrow-n="c-excursions"]',
					disabledClass: 'disabled'
				},
			}
		},
		{
			selector: '[data-slider="map-cruises-p"]',
			options: {
				slidesPerView: 'auto',
				spaceBetween: 26,
				watchSlidesVisibility: true,
				//loop: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="map-cruises-p"]',
					nextEl: '[data-slider-arrow-n="map-cruises-p"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1023: {
						spaceBetween: 13,
					}
				}
			}
		},
		{
			selector: '[data-slider="map-cruises-dir"]',
			options: {
				slidesPerView: '1',
				speed: 1000,
				loop: true,
				effect: 'fade',
				watchSlidesVisibility: true,
				on: {
					'init': function () {
						lazy.update()
					},
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="map-cruises-dir"]',
					nextEl: '[data-slider-arrow-n="map-cruises-dir"]',
					disabledClass: 'disabled'
				},
			}
		},
		{
			selector: '[data-slider="news-d"]',
			options: {
				slidesPerView: 1,
				watchSlidesVisibility: true,
				loop: true,
				on: {
					'init': function () {
						lazy.update();
					},
					'transitionEnd': function () {
						lazy.update();
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="news-d"]',
					nextEl: '[data-slider-arrow-n="news-d"]'
				},
			}
		},
		{
			selector: '[data-slider="s-review"]',
			options: {
				slidesPerView: 1,
				spaceBetween: 20,
				watchSlidesVisibility: true,
				loop: true,
				on: {
					'init': function () {
						lazy.update();
					},
					'transitionEnd': function () {
						lazy.update();
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="s-review"]',
					nextEl: '[data-slider-arrow-n="s-review"]'
				},
				breakpoints: {
					1279: {
						slidesPerView: 1,
						autoHeight: true
					}
				}
			}
		},
		{
			selector: '[data-slider="s-places"]',
			options: {
				slidesPerView: 1,
				autoHeight: true,
				watchSlidesVisibility: true,
				loop: true,
				on: {
					'init': function () {
						lazy.update();
					},
					'transitionEnd': function () {
						lazy.update();
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="s-places"]',
					nextEl: '[data-slider-arrow-n="s-places"]'
				}
			}
		},
		{
			selector: '[data-slider="info-sl"]',
			options: {
				slidesPerView: 1,
				autoHeight: true,
				watchSlidesVisibility: true,
				loop: true,
				on: {
					'init': function () {
						lazy.update();
					},
					'transitionEnd': function () {
						lazy.update();
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="info-sl"]',
					nextEl: '[data-slider-arrow-n="info-sl"]'
				},
				breakpoints: {
					767: {
						autoHeight: false,
					}
				}
			}
		},
		{
			selector: '[data-slider="news-line_1"]',
			options: {
				slidesPerView: 4,
				spaceBetween: 20,
				slideToClickedSlide: true,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="news-line_1"]',
					nextEl: '[data-slider-arrow-n="news-line_1"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1279: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					599: {
						slidesPerView: 1.2,
						spaceBetween: 10,
					}
				}
			}
		},
		{
			selector: '[data-slider="news-line_2"]',
			options: {
				slidesPerView: 4,
				spaceBetween: 20,
				slideToClickedSlide: true,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="news-line_2"]',
					nextEl: '[data-slider-arrow-n="news-line_2"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1279: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					599: {
						slidesPerView: 1.2,
						spaceBetween: 10,
					}
				}
			}
		},
		{
			selector: '[data-slider="news-line_3"]',
			options: {
				slidesPerView: 4,
				spaceBetween: 20,
				slideToClickedSlide: true,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="news-line_3"]',
					nextEl: '[data-slider-arrow-n="news-line_3"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1279: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					599: {
						slidesPerView: 1.2,
						spaceBetween: 10,
					}
				}
			}
		},
		{
			selector: '[data-slider="news-line_4"]',
			options: {
				slidesPerView: 4,
				spaceBetween: 20,
				slideToClickedSlide: true,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="news-line_4"]',
					nextEl: '[data-slider-arrow-n="news-line_4"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1279: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					599: {
						slidesPerView: 1.2,
						spaceBetween: 10,
					}
				}
			}
		},
		{
			selector: '[data-slider="lk-managers-slider"]',
			options: {
				slidesPerView: 4,
				spaceBetween: 23,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="lk-managers-slider"]',
					nextEl: '[data-slider-arrow-n="lk-managers-slider"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1800: {
						slidesPerView: 3,
					},
					1300: {
						slidesPerView: 2,
					},
					767: {
						slidesPerView: 1,
					}
				}
			}
		},
		{
			selector: '[data-slider="lk-offers-slider"]',
			options: {
				slidesPerView: 4,
				spaceBetween: 25,
				watchSlidesVisibility: true,
				on: {
					'transitionEnd': function () {
						lazy.update()
					}
				},
				navigation: {
					prevEl: '[data-slider-arrow-p="lk-offers-slider"]',
					nextEl: '[data-slider-arrow-n="lk-offers-slider"]',
					disabledClass: 'disabled'
				},
				breakpoints: {
					1279: {
						slidesPerView: 3,
					},
					1023: {
						spaceBetween: 10,
						slidesPerView: 3,
					},
					599: {
						slidesPerView: 'auto',
					}
				}
			}
		}
	],

	lkProSlider() {
		let allConvSlider = $('[data-slider]');
		allConvSlider.each((ind, iter) => {
			if ($(iter).attr('data-slider').includes('lkpro')) {
				let prev = '[data-slider-arrow-p="' + $(iter).attr('data-section') + '"]';
				let next = '[data-slider-arrow-n="' + $(iter).attr('data-section') + '"]';
				new Swiper($(iter), {
					slidesPerView: 3,
					spaceBetween: 10,
					watchSlidesVisibility: true,
					loop: false,
					navigation: {
						prevEl: prev,
						nextEl: next
					},
					breakpoints: {
						1279: {
							slidesPerView: 3,
						},
						1024: {
							slidesPerView: 2,
						},
						599: {
							slidesPerView: 1,
						}
					}
				});
			}
		})
	},

};

module.exports = sliders;
