let showFileName = {
    run() {
        let $inputs = $("[data-file-upload]");

        if($inputs.length) {
    
            $inputs.each(function () {
                const $this = $(this);
                const $filenameContainer = $this.parents('[data-file-wrap]').find('[data-file-text]');
                
                $this.change(function() {
                    let fileName = this.value.match(/[^\/\\]+$/);
        
                    if(fileName)
                        $filenameContainer.text(fileName[0]);
                })
                
            });
        }
    }
};

module.exports = showFileName;
