import lazy from './lazy';
import loadPrices from './loadPrices';

import Vue from "vue";
import compareAdd from "../js/vue/compare/compareAdd/compare-add";
import store from "../js/vue/compare/store/index";


let cruises = {
    run() {
        const self = this;
        $('.c-sort-v__i-js').on('click', function () {
            const _this = $(this),
                tab_body = $('.c-view__i'),
                loader = _this.closest('.c').find('.c-view .cLoader-box')
            $('.c-sort-v__i').toggleClass('active')
            const request = BX.ajax.runComponentAction('citfact:catalog.settings', 'setView', {
                mode: 'class',
                data: {
                    view: _this.data('view')
                }
            })
            BX.showWait();
            tab_body.removeClass('active')
            tab_body.attr('data-tab-body', _this.data('view') === 'TABLE' ? 'c-view_2' : 'c-view_1')
            self.showLoader({
                $loader: loader,
            });
            request.then(function () {
                self.loadCatalog(_this)
            }, function () {
                tab_body.addClass('active')
            })
        });

		$('[cruise-view-js-toggle]').on('change', function () {
			const _this = $(this),
				tab_body = $('.c-view__i'),
				loader = _this.closest('.c').find('.c-view .cLoader-box')
			const request = BX.ajax.runComponentAction('citfact:catalog.settings', 'setView', {
				mode: 'class',
				data: {
					view: _this.is(':checked') ? 'TABLE' : 'LIST'
				}
			})
			BX.showWait();
			tab_body.removeClass('active')
			tab_body.attr('data-tab-body', _this.data('view') === 'TABLE' ? 'c-view_1' : 'c-view_2')

			if (!!window.vym) {
				window.vym.reachGoal(_this.is(':checked') ? 'toggle_table_on' : 'toggle_table_off')
			}

			self.showLoader({
				$loader: loader,
			});
			request.then(function () {
				self.loadCatalog(_this)
			}, function () {
				tab_body.addClass('active')
			})
		});
    },

    loadCatalog(tab_btn) {
        const self = this;
        const body_view = $('.c-view__i'),
            loader = tab_btn.closest('.c').find('.c-view .cLoader-box'),
            url_cur = tab_btn.data('url-cur');

        $.ajax({
            url: url_cur,
            data: {ajax: "Y"},
            dataType: 'html',
            beforeSend: function () {
                BX.showWait();
                self.showLoader({
                    $loader: loader,
                });
            },
            success: function (resp) {
                lazy.run();
                body_view.empty()

                body_view.html($(resp).find('.c-view__i').html());

                body_view.find('.c-sort').remove();

                loadPrices.run();

                BX.closeWait();

                self.hideLoader({
                    $loader: loader,
                });

                setTimeout(() => {
                    let items = document.querySelectorAll('.compare-add-app')

                    items.forEach((el) => {
                        new Vue({
                            el,
                            components: {
                                compareAdd,
                            },
                            store,
                        })
                    })
                    App.tooltip.run();
                }, 0);
            }
        }).done(function () {
            body_view.addClass('active')
        })
    },

    showLoader({$loader}) {
        if(!$loader.length) return false;
        $loader.removeClass('cLoader-box--hidden');
    },

    hideLoader({$loader}) {
        if(!$loader.length) return false;
        $loader.addClass('cLoader-box--hidden');
    },
};

module.exports = cruises;
