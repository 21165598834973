const simpleDropdown = {
    run() {
        const $box = $('[data-simple-dropdown]');

        if($box.length) {
            $(document).on('click', '[data-simple-dropdown-opener]', evt => {
                this.toggleDropdown({
                    $box: $(evt.currentTarget).closest('[data-simple-dropdown]'),
                });
            });

            $(document).on('click', evt => {
                if($('[data-simple-dropdown-content].opened').length &&
                    $('[data-simple-dropdown-content].opened').closest('[data-simple-dropdown]').has(evt.target).length === 0) {
                    this.hideDropdown({
                        $dropdown: $('[data-simple-dropdown-content].opened').closest('[data-simple-dropdown]').find('[data-simple-dropdown-content]'),
                    })
                }
            });
        }
    },

    toggleDropdown({$box}) {
        const $dropdown = $box.find('[data-simple-dropdown-content]');
        $dropdown.hasClass('opened') ? this.hideDropdown({$dropdown: $dropdown}) : this.showDropdown({$dropdown: $dropdown});
    },

    hideDropdown({$dropdown}) {
        $dropdown.removeClass('opened');
    },

    showDropdown({$dropdown}) {
        $dropdown.addClass('opened');
    },
};

module.exports = simpleDropdown;