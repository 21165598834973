let testimonialGallery = {
    run() {
        document.addEventListener("mainJsLoaded", function () {
            $('.testimonial__gallery').each(function (index, item) {
                $(item).magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    tLoading: 'Loading image #%curr%...',
                    mainClass: 'mfp-img-mobile',
                    gallery: {
                        enabled: true,
                        navigateByImgClick: true,
                        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                    },
                    image: {
                        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    }
                });
            })
        })
    }
};

module.exports = testimonialGallery;