import select from './select';
import datepicker from './datepicker';

let tabsFilter = {
    run() {
        if($('#ajax_url').length && $('#filter_view_mode').length) {
            let _this = this,
                ajaxUrl = $('#ajax_url').val(),
                filterViewMode = $('#filter_view_mode').val();

            window.smartFilter = new JCSmartFilter(ajaxUrl, filterViewMode)

            $(document).ready(function() {
                const tab_id = $('.filter-control-js.active').data('id');
                $.ajax({
                    url: ajaxUrl,
                    success: function(result) {
                        _this.filters = result
                        _this.changeTab(tab_id)
                    }
                })
            })

            $('.m-filter-top .filter-control-js').click(function(e) {
                $('.m-filter-top div').removeClass('active')
                $(this).closest('div').addClass('active')
                _this.changeTab($(this).data('id'))
            })
        }
    },
    changeTab(tab_id) {
        let filter = $(this.filters).find(`[data-tab="${tab_id}"]`)
        $('.filter-body-js').replaceWith(filter)
        select.selectItems = [];
        select.run()
        datepicker.run()
    }
}

module.exports = tabsFilter
