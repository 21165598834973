import PerfectScrollbar from './_vendor/perfect-scrollbar.min.js';

let pfScrollbar = {
    run() {
        if($('[data-perfect-scrollbar]').length) {
            $('[data-perfect-scrollbar]').each(function (index,item) {
                const ps = new PerfectScrollbar(item);
            })
        }
    },
}

module.exports = pfScrollbar;