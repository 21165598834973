let customScroll = {
    run() {
        this.scroll();
        window.addEventListener('resize', this.scroll);
    },
    scroll() {
        var ps;
        if($('[data-scroll]').length){
            $('[data-scroll]').each(function () {
                let self = $(this)[0];
                ps = new PerfectScrollbar(self, {
                    wheelSpeed: 0.5,
                    wheelPropagation: true,
                });
                ps.update()
            });

        }
        if ($('[data-modal]').length)
            $('[data-modal]').on('click', function () {
                if($('[data-scroll]').length) {
                    setTimeout(function () {
                        ps.update();
                    }, 300)
                }
            });
        $(document).on('submit', 'form', function (e) {
            if($('[data-scroll]').length) {
                $('[data-scroll]').each(function () {
                    let self = $(this)[0];
                    ps = new PerfectScrollbar(self, {
                        wheelSpeed: 0.5,
                        wheelPropagation: true,
                    });
                    setTimeout(function () {
                        ps.update()
                    })

                });
            }
        });
    }
};
module.exports = customScroll;
