import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'

Vue.filter('formatPrice', value => `${(Number(value) / 100).toLocaleString()}`)
if (document.querySelectorAll('#discounts-app').length) {
	new Vue({
		el: '#discounts-app',
		components: {
			App,
		},
		i18n
	})
}
