import Vue from 'vue'
import App from './compareSidebar/App'
import store from './store/index'
import i18n from './i18n'

if (document.querySelectorAll('#compare-sidebar').length) {
	new Vue({
		el: '#compare-sidebar',
		render: h => h(App),
		store,
		i18n,
	})
}
