import Vue from 'vue'
import './comparePage/app'
import store from './store/index'
import i18n from './i18n'

if (document.querySelectorAll('#compare-app').length) {
	new Vue({
		el: '#compare-app',
		store,
		i18n,
	})
}
