import lazy from './lazy';
// import select from './select';

let toggleList = {
    run() {
        this.default();
        this.search();
        this.filter();
        this.password();
    },
    default() {
        const $allBtns = '[data-toggle-btn-m], [data-toggle-btn-t], [data-toggle-btn]';
        const $notMobileBtns = '[data-toggle-btn-t], [data-toggle-btn]';
        const $desktopBtns = '[data-toggle-btn]';
        const $btn = (() => {
            if (window.innerWidth < 768)
                return $allBtns;
            else if (window.innerWidth < 1024)
                return $notMobileBtns;
            else
                return $desktopBtns
        })();

        $(document).off('click.toggle');
        $(document).on('click.toggle', $btn, function (e) {
            e.preventDefault();

            const $this = $(this);
            const $wrap = $this.parents('[data-toggle-wrap]:first');
            
            $wrap.toggleClass("active");
            $this.toggleClass("active");
            $wrap.find('[data-toggle-list]:first').slideToggle(400, () => lazy.update());

            // select.run();
        });
    },

    search() {
        const $btn = $('[data-search-btn]');
        const $search = $('[data-search]');
        const $close = $('[data-search-close]');

        const hide = () => $search.removeClass('active');

        const show = (e) => {
            e.stopPropagation();
            $search.addClass('active');

            $(document).on('click', e => !$(e.target).parents('.h-search').length && !$(e.target).is('.h-search') ? hide() : '')
        };

        $btn.on('click', show);
        $close.on('click', hide);

        const $searchTitle = $('.title-search-result');

        $(document).on('scroll', () => {

            if($(window).scrollTop() > 0)
                $searchTitle.addClass('hide');
            else
                $searchTitle.removeClass('hide');
        });
    },

    filter() {
        const $btn = $('[data-filter-btn]');
        const $filter = $('[data-filter]');
        const $html = $('html');

        $filter.show();

        $btn.on('click', () => {
            $html.toggleClass('locked');
            $filter.toggleClass('active');
        });
    },

    password() {
        let $btn = $('.pass-switch-icon');

        $btn.on('mousedown touchstart', (e) => {
            let $currentTarget = $(e.currentTarget);
            $currentTarget.closest('.b-form__item--pass').find('input').attr('type', 'text');
            $currentTarget.addClass('pass-switch-icon--open');
        });

        $btn.on('mouseup touchend', (e) => {
            let $currentTarget = $(e.currentTarget);
            $currentTarget.closest('.b-form__item--pass').find('input').attr('type', 'password');
            $currentTarget.removeClass('pass-switch-icon--open');
        });

        $btn.on('mouseleave touchleave', (e) => {
            let $currentTarget = $(e.currentTarget);
            if ( $currentTarget.hasClass('pass-switch-icon--open')) {
                $currentTarget.closest('.b-form__item--pass').find('input').attr('type', 'password');
                $currentTarget.removeClass('pass-switch-icon--open');
            }
        });
    }
};

module.exports = toggleList;